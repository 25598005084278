import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes:any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  //personal
  userId: string;
  applicantType: string;
  name: string;
  personalState: string;
  personalCity: string;
  personalCountry: string;
  personalCountryId: string;
  addressForService: string;
  alternativeContactNumber: string;
  emailId: string;
  personalPinCode: string;
  personalTelephoneNo: string;

  //organizational
  organizationName: string;
  organizationAddress: string;
  organizationCity: string;
  organizationState: string;
  organizationCountry: string;
  organizationPinCode: string;
  organizationPhone: string;
  organizationFax: string;
  organizationWebsite: string;

  saveButtonDisable: boolean;
  editState: boolean;
  validationButton: boolean;
  personalPincodeValidate: boolean;
  personalTelephoneValidate: boolean;
  nameValidation: boolean;
  personalAlternateValidate: boolean;
  organizationalPincodeValidate: boolean;
  organizationalNumberValidate: boolean;
  organizationalFaxValidate:boolean;
  organizationalFaxnumberValidate: boolean;
  nameValidationText: string;

  updateSucess: boolean;
  updateSuccessMessage: string;

  token: string;
  loginUserId: string;

  // Customizable Area End

}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class UserProfileBasicController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start

  arrayholder: any[];
  apiCallMessageUpdateProfileRequestId: any;
  validationApiCallId: string = "";
  userProfileFetchApiCallId: string = "";
  userProfileUpdateApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      //personal
      userId: "",
      applicantType: "",
      name: "",
      personalCity: "",
      personalCountry: "",
      personalCountryId: "",
      personalPinCode: "",
      personalState: "",
      personalTelephoneNo: "",
      addressForService: "",
      alternativeContactNumber: "",
      emailId: "",

      //organizational
      organizationAddress: "",
      organizationCity: "",
      organizationCountry: "",
      organizationName: "",
      organizationPinCode: "",
      organizationFax: "",
      organizationPhone: "",
      organizationState: "",
      organizationWebsite: "",

      saveButtonDisable: false,
      editState: false,
      validationButton: false,
      personalPincodeValidate: false,
      personalTelephoneValidate: false,
      nameValidation: false,
      personalAlternateValidate: false,
      organizationalPincodeValidate: false,
      organizationalNumberValidate: false,
      organizationalFaxValidate:false,
      organizationalFaxnumberValidate: false,
      nameValidationText: "",
      updateSucess: false,
      updateSuccessMessage: "",

      token: "",
      loginUserId: "28"
    };

    this.arrayholder = [];

    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }
  async componentDidMount() {
    this.setState({ token: await getStorageData('authToken') })
    !this.state.token && this.props.navigation.history.push("/")
    this.getProfileData()
    // this.managecloseDailog()
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      //instanbul ignore next
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.userProfileFetchApiCallId) {
          this.fetchUserDataResponse(responseJson)
          this.parseApiCatchErrorResponse(errorReponse);
        } else if (apiRequestCallId === this.userProfileUpdateApiCallId) {
          this.updateUserApiResponse(responseJson)
          this.parseApiCatchErrorResponse(errorReponse);

        }
      }


    }
  }

  // Customizable Area End



  // Customizable Area Start

  updateUserApiResponse(responseJson: any) {
    if (!responseJson.errors) {
      this.setState(
        { updateSucess: true, updateSuccessMessage: responseJson.meta.message },
        () => {
          this.editStateChange(2);
        }
      );
    } else {
      //Check Error Response
      this.parseApiErrorResponse(responseJson);
    }
  }

  fetchUserDataResponse(responseJson: any) {
    if (!responseJson.errors) {
      const data = responseJson.data;
            this.setState({
        userId: data.attributes.id,
        name: data.attributes.full_name,
        applicantType: data.attributes.applicant_type,
        emailId: data.attributes.email,
        personalPinCode: data.attributes.postal_code,
        personalTelephoneNo: data.attributes.phone_number,
        personalCity: data.attributes.city,
        personalCountry: data.attributes.country,
        personalState: data.attributes.state,
        alternativeContactNumber: data.attributes.alternate_number,
        addressForService: data.attributes.address
      });
      const organization=data.attributes.organization.data
      this.setState({
        organizationName: organization.attributes.name,
        organizationAddress: organization.attributes.address,
        organizationCity: organization.attributes.city,
        organizationState: organization.attributes.org_state,
        organizationCountry:organization.attributes.org_country,
        organizationPinCode: organization.attributes.pincode,
        organizationPhone: organization.attributes.phone_no,
        organizationFax: organization.attributes.fax_no,
        organizationWebsite: organization.attributes.website
      });
    } else {
      //Check Error Response
      this.parseApiErrorResponse(responseJson);
    }
  }

  getProfileData() {
    const headers = {
      "Content-Type": configJSON.contentType,
      token: this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.userProfileFetchApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getUserApiEndPoint}/profiles/${this.state.loginUserId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getUserProfileType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateProfileData() {
    const headers = {
      "Content-Type": configJSON.contentType,
      token: this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.userProfileUpdateApiCallId = requestMessage.messageId;

    const profile = {
      phone_number: this.state.personalTelephoneNo,
      alternate_number: this.state.alternativeContactNumber,
      country: this.state.personalCountry,
      postal_code: this.state.personalPinCode,
      city: this.state.personalCity,
      address: this.state.addressForService,
      state: this.state.personalState,
      full_name: this.state.name
    };
    const organization = {
      name: this.state.organizationName,
      address: this.state.organizationAddress,
      city: this.state.organizationCity,
      org_country: this.state.organizationCountry,
      org_state: this.state.organizationState,
      phone_no: this.state.organizationPhone,
      alternate_no: this.state.alternativeContactNumber,
      website: this.state.organizationWebsite,
      fax_no: this.state.organizationFax,
      pincode: this.state.organizationPinCode
    };

    const httpBody = {
      profile: profile,
      organization: organization
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getUserApiEndPoint}/profiles/${this.state.loginUserId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateUserProfileType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  editStateChange(id: number) {
    if (id === 1) {
      this.setState({ editState: true });
    } else if (id === 2) {
      this.setState({ editState: false });
    }
  }
  validateButtonCheck = () => {
    // Destructure the state for better readability
    const {
      personalPincodeValidate,
      nameValidation,
      personalTelephoneValidate,
      organizationalPincodeValidate,
      organizationalNumberValidate,
      organizationalFaxValidate,
    } = this.state;
  
    // Log the current state for debugging purposes
    console.log(": validateButtonCheck :3", this.state);
  
    // Check if any validation condition is true
    const hasValidationErrors =
      personalPincodeValidate ||
      nameValidation ||
      personalTelephoneValidate ||
      organizationalPincodeValidate ||
      organizationalFaxValidate||
      organizationalNumberValidate;

    console.log(": validateButtonCheck :4", hasValidationErrors);
  
    // Set the saveButtonDisable based on the validation result
    this.setState(
      { saveButtonDisable: hasValidationErrors },
      () => console.log(": validateButtonCheck :5", this.state.saveButtonDisable)
    );
  };
  
  validatePinCode(pin: string, id: number) {
    console.log(": validatePinCode :0",pin);
    
    const pinchecker = /^[a-z0-9]+$/i;
    if (
      (pin.toString().length >= 4 &&
        pin.toString().length <= 6 &&
        pinchecker.test(pin)) ||
      !pin
    ) {
      console.log(": validatePinCode :1",id);
      //validate
      if (id === 1) {
        this.setState({ personalPincodeValidate: false }, () => {
          this.validateButtonCheck();
        });
      } else {
        this.setState({ organizationalPincodeValidate: false }, () => {
          this.validateButtonCheck();
        });
      }
    } else {
      console.log(": validatePinCode :2",id);
      //not validate
      if (id === 1) {
        this.setState({ personalPincodeValidate: true }, () => {
          this.validateButtonCheck();
        });
      } else {
        this.setState({ organizationalPincodeValidate: true }, () => {
          console.log(": validatePinCode :21",id);
          this.validateButtonCheck();
        });
      }
    }
  }
  validateNameNew(name: string) {
    const nameValidationPattern = /^([a-zA-Z0-9_ -]){0,30}$/;

    if (!nameValidationPattern.test(name)) {
      this.setState(
        { nameValidationText: "Invalid name.", nameValidation: true },
        () => {
          this.validateButtonCheck();
        }
      );
    } else {
      this.setState({ nameValidation: false }, () => {
        this.validateButtonCheck();
      });
    }
  }
  validatePhoneNumber(number: string, id: number) {
    const formatLettersandNumbers = /^\d+$/;
    if (
      (number.toString().length >= 9 &&
        number.toString().length <= 14 &&
        formatLettersandNumbers.test(number)) ||
      !number
    ) {
      //validate
      if (id === 1) {
        this.setState({ personalTelephoneValidate: false }, () => {
          this.validateButtonCheck();
        });
      } else if (id === 2) {
        this.setState({ organizationalNumberValidate: false }, () => {
          this.validateButtonCheck();
        });
      }
    } else {
      //not validate
      if (id === 1) {
        this.setState({ personalTelephoneValidate: true }, () => {
          this.validateButtonCheck();
        });
      } else if (id === 2) {
        this.setState({ organizationalNumberValidate: true }, () => {
          this.validateButtonCheck();
        });
      }
    }
  }
   validateFaxNumber(faxNumber: any, id: number) {
    // Define a regular expression for a basic fax number pattern
    const faxNumberPattern = /^(\+\d{1,3}\s?)?(\(\d{1,4}\)|\d{1,4})[\s.-]?\d{1,12}$/;
  
    // Test the fax number against the pattern
    this.setState({organizationalFaxValidate:faxNumberPattern.test(faxNumber)}, () => {
      this.validateButtonCheck();
    });
  }


  // Customizable Area End

}
