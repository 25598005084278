import React from "react";
// Customizable Area Start
import { Box } from "@material-ui/core";
import {GISearch,QuickFormFiling,Payments,Downloads,ControlPanel,FormHistory} from "../src/assets"
import Navbar from "../../../components/src/Navbar";
import { createStyles ,withStyles} from "@material-ui/core/styles";
import { ipoImage, logoImage } from "../../customform/src/assets";
// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props; 
    return (
      //Merge Engine DefaultContainer
      <Box  className={classes.pageContainerDB}>
          <Box className={classes.logoContainerDB}>
            <img src={logoImage} alt="Logo" className={classes.logoDB} />
            <img src={ipoImage} alt="Ipo-Logo" className={classes.logoDB} />
          </Box>
          <Box  className={classes.outerContainerDB}>
            <Box  className={classes.innerContainerDB}>
              <Navbar selectedTab={0} alertIcon={true} navigation={this.props.navigation} />
              <div  className={classes.rowDBTop}>
                <div  className={classes.coloredBox}>
                  <div  className={classes.boxData} onClick={this.handleAdvanceSearch} data-test-id="GI-Search">
                    <img className={classes.boxDataImage} src={GISearch} alt="Icon" />
                    <p  className={classes.boxLabelText}>GI Search</p>
                  </div>                  
                </div>
                <div  className={classes.coloredBox}><div  className={classes.boxData} onClick={this.handleQuickFormFiling}>
                  <img src={QuickFormFiling} className={classes.boxDataImage} alt="Icon" />
                  <p  className={classes.boxLabelText}>Quick Form Filing</p>
                </div></div>
                <div  className={classes.coloredBox}><div  className={classes.boxData} onClick={this.handleFormHistory}>
                  <img src={FormHistory} className={classes.boxDataImage} alt="Icon" />
                  <p  className={classes.boxLabelText}>Form History</p>
                </div></div>
              </div>
              <div  className={classes.rowDB}>
                <div  className={classes.coloredBox}><div  className={classes.boxData}>
                  <img src={Payments} className={classes.boxDataImage} alt="Icon" />
                  <p  className={classes.boxLabelText}>Payments</p>
                </div></div>
                <div  className={classes.coloredBox}><div  className={classes.boxData} data-test-id="controlPanel" onClick={this.handleControlPanel}>
                  <img src={ControlPanel} className={classes.boxDataImage} alt="Icon" />
                  <p  className={classes.boxLabelText}>Control Panel</p>
                </div></div>
                <div  className={classes.coloredBox}><div  className={classes.boxData}>
                  <img src={Downloads} className={classes.boxDataImage} alt="Icon" />
                  <p  className={classes.boxLabelText}>Downloads</p>
                </div></div>
              </div>
            </Box>
          </Box>
          <footer  className={classes.customFooter}>
            <p  className={classes.customFooterText}>
              Guidelines for using NTRP (Bharat Kosh) Payment Gateway <span  className={classes.startColor}>Guide</span>
            </p>
          </footer>
        </Box>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles =
  createStyles({
    pageContainerDB: {
      overflowX: 'hidden',
    },
    logoContainerDB: {
      display: "flex",
      backgroundColor: "#ffd4ac",
      justifyContent: "space-between",
      padding: "20px",
      maxWidth: "100%",
      height: "120px",
    },
    logoDB: {
      objectFit: "contain",
      height: "100px",
      padding: "4px",
      '@media (max-width: 900px)': {
        height: "80px",
        padding: "10px",
      },
      '@media (max-width: 600px)': {
        height: "60px",
        padding: "5px",
      },
      '@media (max-width: 300px)': {
        height: "40px",
        padding: "4px",
      }
    },
    outerContainerDB: {
      backgroundColor: '#ffd4ac',
      padding: '20px 180px',
      fontFamily: '"Poppins-Medium", sans-serif !important',
    },
    innerContainerDB: {
      backgroundColor: 'white',
      borderRadius: '20px',
      boxShadow: '5px 10px 8px #888888',
      marginBottom: '5% !important',
    },
    rowDB: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: '31px',
    },
    rowDBTop: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: '31px',
      marginTop: '50px',
    },
    coloredBox: {
      backgroundColor: '#FFEFDF',
      width: '25%',
      height: '180px',
      display: 'flex',
      borderRadius: '16px',
      flexDirection: 'column',
      alignItems: 'center',
      marginRight: '40px',
      marginBottom: '50px',
      justifyContent: 'center',
      '@media (max-width: 767px)': {
        width: 'calc(50% - 30px)',
        marginRight: '15px',
        marginBottom: '15px',
      },
    },
    boxData: {
      textAlign: 'center',
      cursor: 'pointer',
    },
    boxDataImage: {
      width: '50px',
      height: '50px',
      '@media (max-width: 767px)': {
        width: '40px',
        height: '40px',
      },
      '@media (max-width: 1280px)': {
        width: '33px',
        height: '33px',
      }
    },
    boxLabelText: {
      fontFamily: 'Poppins-Medium, sans-serif',
      fontWeight: 500,
      fontSize: '22px',
      color: '#8A4504',
      '@media (max-width: 767px)': {
        fontSize: '17px',
      },
      '@media (max-width: 1280px)': {
        fontSize: '14px',
      }
    },
    topMargin: {
      marginTop: '50px',
    },
    customFooter: {
      backgroundColor: '#ffffff',
      padding: '10px',
      textAlign: 'center',
      height: '70px', // Note: Using is discouraged and should be avoided if possible.
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    customFooterText: {
      margin: '0',
      fontSize: '20px', // Note: Using is discouraged and should be avoided if possible.
      color: '#000000',
      fontWeight: 400,
      lineHeight: '45px', // Note: Using is discouraged and should be avoided if possible.
      fontFamily: 'Poppins-Medium, sans-serif', // Note: Using is discouraged and should be avoided if possible.
    },
    startColor: {
      color: 'red',
    },
  })
export const DashboardHome =  withStyles(webStyles)(Dashboard); 
// Customizable Area End
