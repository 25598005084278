import React from "react";

// Customizable Area Start
import { logo ,nvsp, myGov, dipp, indiaPortal} from "../src/assets";
import { Typography, Box, Grid } from "@material-ui/core";
import { withStyles, createStyles } from "@material-ui/core/styles";
import Navbar from "../../../components/src/Navbar";
import Loader from "../../../components/src/Loader";
import { ipoImage, logoImage } from "../../customform/src/assets";
// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
        const { classes } = this.props; 

        return (
            <Box className={classes.pageContainer}>
                <Box className={classes.logoContainer}>
                  <img src={logoImage} alt="Logo" className={classes.logoAboutus} />
                  <img src={ipoImage} alt="Ipo-Logo" className={classes.logoAboutus} />
                </Box>
                <Box className={classes.outerContainer}>
                    <Box className={classes.innerContainer}>
                        <Navbar selectedTab={0} navigation={this.props.navigation} />
                        <Box className={classes.pageLayout}>
                          {this.state.isLoading ?
                            <Loader loading={this.state.isLoading} />
                            :
                            <Typography className={this.props.classes.privacyText} dangerouslySetInnerHTML={{
                              __html: this.state.termsConditions.replace(
                                /font-family:[^;]+;/g,
                                'font-family: Poppins, sans-serif'
                              )
                            }}/>
                          }
                            <Grid alignItems="center" spacing={2} container className={classes.logoImageContainer}>
                                <Grid item xs={6} md={3}><img className={classes.imgContainer} src={indiaPortal}/></Grid>
                                <Grid item xs={6} md={3}><img className={classes.imgContainer} src={nvsp}/></Grid>
                                <Grid item xs={6} md={3}><img className={classes.imgContainer} src={dipp}/></Grid>
                                <Grid item xs={6} md={3}><img className={classes.imgContainer} src={myGov}/></Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
                <footer className={classes.footer}>
                    <p className={classes.footerText}>
                        Guidelines for using NTRP (Bharat Kosh) Payment Gateway{" "}
                        <a className={classes.guideHypertext} href="#">
                            Guide
                        </a>
                    </p>
                </footer>
            </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = () =>
    createStyles({
        privacyText: {
            fontFamily: `Poppins, sans-serif !important`,
            "& h1" : {
                marginTop: '30px',
                marginBottom: '0px',
            },
            "& h2" : {
                fontWeight: 400,
                marginBottom: '0px'
            },
        },
        pageContainer: {
            overflowX: "hidden",
        },
        logoContainer: {
          display: "flex",
          backgroundColor: "#ffd4ac",
          justifyContent: "space-between",
      padding: "20px",
          maxWidth: "100%",
          height: "120px",
        },
        logoAboutus: {
          objectFit: "contain",
          height: "105px",
          padding: "4px",
          '@media (max-width: 900px)': {
            height: "80px",
            padding: "10px",
          },
          '@media (max-width: 600px)': {
            height: "60px",
            padding: "5px",
          },
          '@media (max-width: 300px)': {
            height: "40px",
            padding: "4px",
          }
        },
        outerContainer: {
            backgroundColor: "#ffd4ac",
            padding: "20px 180px",
            fontFamily: "'Poppins, sans-serif'",
            '@media (max-width: 900px)':{
              padding: "16px 40px",
            },
            '@media (max-width: 600px)':{
              padding: "10px 20px",
            },
            '@media (max-width: 300px)':{
              padding: "10px 10px",
            },
        },
        innerContainer: {
            backgroundColor: "white",
            borderRadius: 20,
            boxShadow: "5px 10px 8px #888888",
            marginBottom: "50px",
        },
        heading: {
            fontWeight: 700,
            fontSize: 26,
            color: "#8a4504",
            textAlign: "center",
            fontFamily: "'Poppins', sans-serif",
            margin: "30px 0",
        },
        spanFaq: {
            fontWeight: 700,
            fontSize: 26,
            fontFamily: "'Poppins', sans-serif",
        },
        content: {
            fontSize: 18,
            fontFamily: "'Poppins', sans-serif",
            margin: "10px 0",
        },
        contentHeading: {
            fontWeight: 700,
            fontSize: 22,
            lineHeight: "30px",
            fontFamily: "'Poppins', sans-serif",
        },
        paragraph: {
            fontSize: 18,
            fontFamily: "'Poppins', sans-serif",
            margin: "30px 0",
        },
        pageLayout: {
            padding: "40px 60px",
            '@media (max-width: 900px)':{
                padding: "20px 40px",
              },
              '@media (max-width: 600px)':{
                padding: "10px 30px",
              },
              '@media (max-width: 300px)':{
                padding: "10px 20px",
              },
        },
        contentContainer: {
            padding: "10px 0",
        },
        footer: {
            backgroundColor: "#ffffff",
            padding: "10px",
            textAlign: "center",
            height: "70px !important",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
          footerText: {
            margin: 0,
            fontSize: "20px !important",
            color: "#000000",
            fontWeight: 400,
            lineHeight: "45px !important",
            fontFamily: "Poppins-Medium, sans-serif !important",
          },
          guideHypertext: {
            color: "#ff0000",
            textDecoration: "none",
          },
          logoImageContainer:{
           padding: "20px 10px"
          },
          imgContainer: {
            objectFit: "contain",
            width: "100%",
            height: "40px",
            margin: "auto"
          }
          
    });

export const TermsAndConditions =  withStyles(styles)(TermsConditions); 
// Customizable Area End
