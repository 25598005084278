import React from "react";
// Customizable Area Start
import { Headerlogo } from "../../landingpage/src/assets";
import { ipoImage } from "../../customform/src/assets";
import LinkList from "../../../components/src/LinkList";
import { withStyles,createStyles } from "@material-ui/core/styles";
// Customizable Area End
import ForgotPasswordController, { Props } from "./ForgotPasswordController";

export class ResetLink extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.getRegisterEmail()
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    return (
      <div className={this.props.classes.appContainerFG}>
        <div className={this.props.classes.headerFGReset}>
          <img 
          src={Headerlogo}
          alt="Logo"
          className={this.props.classes.logoFGReset} />
          <img 
          src={ipoImage}
          alt="Ipo-Logo"
          className={this.props.classes.logoFGReset} />
        </div>
        <div 
        className={this.props.classes.cardRL}>
          <div 
          className={this.props.classes.cardHeaderFG}>
            <span 
            className={this.props.classes.headerTextFG}>
              Reset link sent on registered mail id{" "}
            </span>
          </div>
          <div 
          className={this.props.classes.centeredMessage}>
            <p>
              An email to reset your password has been sent on {" "}
              <strong>
                {this.state.RegisteredEmailId}
              </strong>
              <br />
              Please note that the link is valid for the next 30 minutes.
            </p>
          </div>
          <div 
          className={this.props.classes.buttonsContainerFG}>
            <button
              className={this.props.classes.actionButton}
              id="resetlinkPwd"
              onClick={() => { this.handleNavigation("LandingPageweb")}}
            >
              Login
            </button>
          </div>
        </div>
        <LinkList />
        <footer className={this.props.classes.customFooter}>
          <p 
          className={this.props.classes.customFooterText}>
            Guidelines for using NTRP (Bharat Kosh) Payment Gateway {" "}
            <span 
            className={this.props.classes.startColors}>Guide</span>
          </p>
        </footer>
      </div>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
const webStyles = () =>
createStyles({
  appContainerFG: {
    backgroundColor: '#ffd4ac',
    height: 'auto',
    overflowX: 'hidden',
  },
  headerFGReset: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#ffd4ac",
    padding: "10px",
    maxWidth: "100%",
    height: "100px",
    marginBottom: "40px",
  },
  logoFGReset: {
    objectFit: "contain",
    height: "92px",
    padding: "20px",
    '@media (max-width: 900px)': {
      height: "80px",
      padding: "10px",
    },
    '@media (max-width: 600px)': {
      height: "60px",
      padding: "5px",
    },
    '@media (max-width: 300px)': {
      height: "40px",
      padding: "4px",
    }
  },
  cardRL: {
    width: '75%',
    minHeight: '450px',
    height: 'auto',
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ffffff',
    position: 'relative',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    padding: '20px',
    borderRadius: '10px',
    marginBottom: '40px',
    marginTop: '40px',
    margin: '0 auto',
  },
  cardHeaderFG: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '33px',
    display: 'flex',
  },
  headerTextFG: {
    fontSize: '25px',
    fontWeight: 500,
    fontFamily: 'Poppins, sans-serif',
  },
  centeredMessage: {
    fontFamily: 'Poppins, sans-serif',
    textAlign: 'center',
    fontSize: '22px',
    fontWeight: 400,
    margin: 'auto',
  },
  imageSize: {
    height: '70px',
    marginBottom: '10px',
    marginTop: '20px',
    width: '70px',
  },
  buttonsContainerFG: {
    marginTop: '10px', // Note: Using is discouraged and should be avoided if possible.
    justifyContent: 'center',
    marginBottom: '30px', // Note: Using is discouraged and should be avoided if possible.
    display: 'flex',
  },
  customFooter: {
    padding: '10px',
    backgroundColor: '#ffffff',
    height: '70px', // Note: Using is discouraged and should be avoided if possible.
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  customFooterText: {
    color: '#000000',
    fontSize: '20px', // Note: Using is discouraged and should be avoided if possible.
    margin: '0',
    lineHeight: '45px', // Note: Using is discouraged and should be avoided if possible.
    fontFamily: 'Poppins-Medium, sans-serif', // Note: Using is discouraged and should be avoided if possible.
    fontWeight: 400,
  },
  startColors: {
    color: 'red',
  },
  actionButton: {
    padding: "10px 20px",
    fontSize: "1.125rem", // 18px
    borderRadius: "5px",
    cursor: "pointer",
    fontWeight: "bold",
    margin: "0 10px",
    minWidth: "300px",
    height: "54px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: 'Poppins, sans-serif',
    textTransform: "none",
    color: "#ffffff",
    backgroundColor: "#894402",
    border: "none",
  },
})
export default withStyles(webStyles)(ResetLink)
// Customizable Area End
