Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productList = "Product List"
exports.cancel = "cancel"
exports.approved = "Approved"
exports.pending = "Pending"
exports.addProduct = 'Add Your Product'
exports.title = 'enter the Title'
exports.description = 'enter the Description'
exports.price = 'enter the Price'
exports.quantity = 'enter the Quantity'
exports.productImages = 'add Product Images'
exports.btnExampleTitle = "Submit";
exports.users = "User's";
exports.addNewButton = 'Add New Product'
exports.deleteText = "Are you sure you want to delete product?"
exports.delete = "delete"
exports.validationsMessage = "Please enter all fields"
exports.numberRegex = /^\d+$/
exports.upload = "Upload"
exports.validationApiContentType = "application/json";
exports.formData = 'multipart/form-data'
exports.userListUrl = "https://nickcoingophase1v2-158847-ruby.b158847.stage.eastus.az.svc.builder.ai/bx_block_content_management/user_type?user_type="
exports.getMethod = 'GET'
exports.postMethod = 'POST'
exports.addDataUrl = 'https://nickcoingophase1v2-158847-ruby.b158847.stage.eastus.az.svc.builder.ai/bx_block_content_management/content_managements'
exports.status = 'Staus :'
exports.success = 'Product Added Successfully'
exports.pageTitle = 'Contact Us';
exports.helpLineNumber= 'Helpline for General Queries : Delhi: +91-11-25300200 | Mumbai: +91-22-24159196,  | Kolkata: +91-33-23679101 | Chennai: 044-22505280, 90    Helpline for Online filing : Delhi: +91-11-25301243, +91-11-25300201, +91-11-28089556'
exports.aboutUsUrl='bx_block_content_management/about_us_pages'
// Customizable Area End
