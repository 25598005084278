import React from "react";
// Customizable Area Start
import { withStyles, createStyles } from "@material-ui/styles";
import { warningIcon } from "../../landingpage/src/assets";
import { TextField } from "@material-ui/core";
import { setStorageData } from "framework/src/Utilities";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ipoImage ,logoImage} from "../../customform/src/assets";
// Customizable Area End
import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";

export class SelectTypeOfApplicant extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <div className={this.props.classes.container}>
        <div className={this.props.classes.headerSelect}>
          <img src={logoImage} alt="Logo" className={this.props.classes.logoSelect} />
          <img src={ipoImage} alt="Ipo-Logo" className={this.props.classes.logoSelect} />
        </div>
        <div className={this.props.classes.cardDS}>
          <div className={this.props.classes.topLine}>
            <button className={this.props.classes.button}>New User Registration Form</button>
          </div>
          <div className={this.props.classes.content}>
            <label className={this.props.classes.label}>
              Fields Marked with an asterisk * are required.{" "}
            </label>
          </div>
          <div className={this.props.classes.formContainerDS}>
            <div className={this.props.classes.formRow}>
              <label className={this.props.classes.typeOfApplicantSelect}>
                {" "}
                Type of Applicant*:
              </label>
              <div className={this.props.classes.selectContainer}>
                <Autocomplete
                  id="TypeofApplicant"
                  options={this.state.roleList}
                  className={this.props.classes.selectContainerOptions}
                  popupIcon={<ExpandMoreIcon />}
                  getOptionLabel={(option: any) => option.attributes.name}
                  onBlur={this.validateSelectType}
                  renderOption={(option) => (<div className={this.props.classes.optionContainer}>{option.attributes.name}</div>)}
                  disableClearable  
                  onChange={(event,value:any) => {
                    const selectedRoleId = value?.attributes?.id;
                    setStorageData("SelectedRoleID", selectedRoleId);
                    this.setState({
                      selectedApplicant: value?.attributes?.id,
                      applicatTypeError: "",
                    });
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      error={this.state.applicatTypeError}
                      variant="outlined"
                      placeholder="Select your type of Applicant"
                      id="renderInput"
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true
                      }}
                    />
                  )}
                />

                {this.state.applicatTypeError && (
                  <div className={this.props.classes.textLabel}>
                    <img src={warningIcon} alt="alertIcon" className={this.props.classes.alertIcon} />
                    <span className={this.props.classes.errorMessageText}>
                      {this.state.applicatTypeError}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={this.props.classes.buttonsContainerSelect}>
            <button
              className={this.props.classes.actionButtonBack}
              id="backButton"
              onClick={() => {
                this.handleNavigate("NewUserRegistrationForm");
              }}
            >
              Back
            </button>
            <button
              className={this.state.selectedApplicant ? this.props.classes.actionButton : this.props.classes.actionButtonDisableSEL }
              disabled={!this.state.selectedApplicant}
              id="nextButton"
              onClick={() => {
                this.handleNavigate("OrganisationDetails");
              }}
            >
              Next
            </button>
          </div>
          <div className={this.props.classes.SelectApplicantDiv}>
            <span className={this.props.classes.SelectApplicantNote}>
              NB: Please register using Internet Explorer 6.0 or above.
            </span>
          </div>
        </div>
        <div className={this.props.classes.buttonsContainerDS}>
          <button id="Home" className={this.props.classes.actionButton} onClick={()=>this.handleNavigate("Dashboard")}>Home</button>
          <button id="AboutUs" className={this.props.classes.actionButton} onClick={()=>this.handleNavigate("AboutUS")}>About Us</button>
          <button id="ContactUs" className={this.props.classes.actionButton} onClick={()=>this.handleNavigate("ContactUs")}>Contact Us</button>
        </div>
        <footer className={this.props.classes.footer}>
          <span className={this.props.classes.footerText}>
            Guidelines for using NTRP (Bharat Kosh) Payment Gateway <span className={this.props.classes.gideLable}>Guide</span>
          </span>
        </footer>
      </div>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const style = createStyles({
    container: {
        backgroundColor: "#ffd4ac",
        height: "auto",
        overflowX: "hidden"
    },
    headerSelect: {
      display: "flex",
      justifyContent: "space-between",
      backgroundColor: "#ffd4ac",
      maxWidth: "100%",
      height: "100px",
      marginBottom: "50px"
    },
  logoSelect: {
      objectFit: "contain",
      height: "92px",
      padding: "20px",
      '@media (max-width: 900px)': {
        height: "80px",
        padding: "10px",
      },
      '@media (max-width: 600px)': {
        height: "60px",
        padding: "5px",
      },
      '@media (max-width: 300px)': {
        height: "40px",
        padding: "4px",
      }
    },
    cardDS: {
        width: "75%",
        height: "auto",
        minHeight: "500px",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        backgroundColor: "#ffffff",
        borderRadius: "10px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        margin: "0 auto",
        padding: "20px",
        marginBottom: "40px",
        paddingTop: "20px",
    },
    topLine: {
        width: "100%",
        height: "1px",
        backgroundColor: "#ccc",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        top: "0"
    },
    button: {
        padding: "5px 10px",
        fontSize: "30px",
        fontWeight: 400,
        border: "none",
        width: "600px",
        height: "70px",
        backgroundColor: "#894402",
        color: "#ffffff",
        borderRadius: "5px",
        fontFamily: "Poppins-Medium, sans-serif",
        '@media (max-width: 1200px)': {
            width: "60%",
            fontSize: "25px",
            height: "auto",
        }
    },
    content: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "50px"
    },
    label: {
        fontWeight: 400,
        fontSize: "27px",
        fontFamily: "Poppins, sans-serif",
        color: "#000000",
        marginBottom: "15px",
        marginTop:"8px",
        '@media (max-width: 1200px)': {
          fontSize: "18px",
        }
    },
    formContainerDS: {
        flexDirection: "column",
        marginTop: "5%",
        marginBottom: "5%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    formRow: {
        display: "flex",
        alignItems: "center",
        marginBottom: "20px",
    },
    typeOfApplicantSelect: {
        fontWeight: 500,
        fontSize: "24px",
        marginRight: "10px",
        marginTop: "18px",
        alignSelf:"flex-start",
        fontFamily: 'Poppins, sans-serif',
        '@media (max-width: 1200px)': {
          fontSize: "17px"
        }
    },
    selectContainer: {
        margin: "10px auto",
        display: "flex",
        flexDirection: "column",
      },
      selectOptions:{
        fontFamily: 'Poppins, sans-serif',
      },
      selectContainerOptions: {
        width: "500px",
        fontFamily: 'Poppins, sans-serif !important',
        fontSize: '20px',
        color: "#000000 !important",
        '@media (max-width: 1200px)': {
          width: "250px",
        },
        '& .MuiAutocomplete-input': {
          fontSize: '20px',
          color: '#000000',
          marginLeft:"9px",
          fontFamily: "Poppins, sans-serif"
        },
        '& .MuiAutocomplete-option': {
          fontSize: '20px !important', 
          color: 'red !important',
          fontFamily: "'Poppins', sans-serif !important",
        },
      },
      optionContainer: {
        fontSize: '20px',
        color: '#000000',
        fontFamily: 'Poppins, sans-serif',
      },
      textLabel: {
        marginBottom: "10px",
        textAlign: "left",
        display: "flex",
        alignItems: "center",
      },
      errorMessageText: {
        position: "relative",
        fontFamily: 'Poppins-Medium, sans-serif',
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "18px",
        color: "#FF0000",
        marginTop: "5px",
        left: "0",
      },
      buttonsContainerSelect: {
        display: "flex",
        marginTop: "auto",
        marginBottom: "25px",
        justifyContent: "center",
        '@media (max-width: 1200px)': {
          flexDirection: "column",
          marginTop: "40px",
          gap:"10px",
          alignItems: "center",
        }
        
      },
      actionButton: {
        padding: "10px 20px",
        fontSize: "18px",
        fontWeight: "bold",
        borderRadius: "5px",
        margin: "0 10px" /* Add some margin between the buttons */,
        cursor: "pointer",
        minWidth: "300px" /* Minimum width for the button */,
        height: "54px" /* Height for the button */,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textTransform: "none",
        fontFamily: '"Poppins", sans-serif',
        backgroundColor: "#894402", // Background color
        color: "#ffffff", // Text color
        border: "none", // Remove border
        '@media (max-width: 1200px)': {
          width: "200px",
        }
      },
      actionButtonDisableSEL: {
        padding: "10px 20px",
        fontSize: "18px",
        fontWeight: "bold",
        borderRadius: "5px",
        margin: "0 10px" /* Add some margin between the buttons */,
        cursor: "pointer",
        minWidth: "300px" /* Minimum width for the button */,
        height: "54px" /* Height for the button */,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textTransform: "none",
        fontFamily: "Poppins, sans-serif",
        backgroundColor: "#b38961", // Background color
        border: "1px solid #b38961",
        color: "#ffffff", // Text color
        '@media (max-width: 1200px)': {
          marginBottom:"20px"
        }
      },
      actionButtonBack: {
        padding: "10px 20px",
        fontSize: "18px",
        fontWeight: "bold",
        borderRadius: "5px",
        margin: "0 10px" /* Add some margin between the buttons */,
        cursor: "pointer",
        minWidth: "300px" /* Minimum width for the button */,
        height: "54px" /* Height for the button */,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textTransform: "none",
        fontFamily: '"Poppins", sans-serif',
        backgroundColor: "#ffffff", // Background color
        color: "#894402", // Text color
        border: "1px solid #894402", // Remove border
        marginRight: "10px",
        '@media (max-width: 1200px)': {
          marginBottom:"20px",
          width:"200px"
        }
      },
      gideLable:{
        color: "#FF0000"
      },
      footerText: {
        margin: "0" /* Remove default margins for the paragraph */,
        fontSize: "20px",
        color: "#000000",
        fontWeight: 400,
        lineHeight: "45px",
        fontFamily: "Poppins-Medium, sans-serif"
      },
      footer: {
        backgroundColor: "#ffffff",
        padding: "10px",
        height: "70px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      },
      buttonsContainerDS: {
        display: "flex",
        marginTop: "auto",
        marginBottom: "40px",
        justifyContent: "center",
        '@media (max-width: 1200px)': {
          flexDirection: "column",
          marginTop: "40px",
          gap:"10px",
          alignItems: "center",
        }
      },
      optionColor:{
        color: '#979797 !important'
      },
      alertIcon:{width: "20px", height: "20px", marginRight: 4 },
      SelectApplicantNote: {
        fontFamily: 'Poppins, sans-serif',
        color: "#009B31",
        fontSize: "20px",
        fontWeight: 400,
      },
      SelectApplicantDiv: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },

})
export default withStyles(style)(SelectTypeOfApplicant)
// Customizable Area End
