import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
import { WithStyles } from "@material-ui/core";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  settingsTabValue: number;
  selectedOption:string;
  checkedForm:any;
  draftedForms: any,
  discardedForms: any,
  submittedForms: any,
  approvedForms: any,
  searchQuery:string;
  discardedSuccessfulModal:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class FormHistoryController extends BlockComponent<Props,S,SS> {
  // Customizable Area Start
  token: string = ""
  formHistoryapiCallId: string = ""
  formDiscardApiId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      settingsTabValue: 0,
      selectedOption:"Show All",
      checkedForm:{
        id:"",
        attributes:{custom_form_id:""}
      },
      draftedForms: [],
      discardedForms: [],
      submittedForms: [],
      approvedForms: [],
      searchQuery: '',
      discardedSuccessfulModal: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  async componentDidMount() {
    const token = await getStorageData('authToken')
    if (!token) {
      this.props.navigation.history.push("/")
    }
    this.token = token
    this.getFormHistory();
    
    // this.managecloseDailog()
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      
      if (apiRequestCallId === this.formHistoryapiCallId) {
        if(responseJson?.data){
          this.setFormData(responseJson.data)
        }else{
          this.setFormData([])
        }
      }
      if (apiRequestCallId === this.formDiscardApiId) {        
        if(responseJson?.data){
          this.handleOpen()
          this.setState({checkedForm:{id:"",attributes:{custom_form_id:""}}})
          this.getFormHistory()
        }
      }
    }
      
    // Customizable Area End
  }

  // Customizable Area Start
  setFormData=(data:any)=>{
    switch (this.state.settingsTabValue) {
      case 0: this.setState({draftedForms:data}); break;
      case 1: this.setState({discardedForms:data}); break;
      case 2: this.setState({submittedForms:data}); break;
      case 3: this.setState({approvedForms:data}); break;
    }
  }

  handleCheck=(event:any, element:any)=>{
    if(event.target.checked){
      this.setState({checkedForm:element})
    }else{
      this.setState({checkedForm:{id:"",attributes:{custom_form_id:""}}})
    }
  }

  getFormHistory=()=> {
    let status = "drafted";
    switch (this.state.settingsTabValue) {
      case 1: status = "discarded"
        break;
      case 2: status = "submitted"
        break;
      case 3: status = "completed"
        break;
    }

    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: this.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.formHistoryapiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.formHistoryGetUrl+`?status=${status}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleFormDiscard=()=>{
    if(!this.state.checkedForm.id){
      return
    }

    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: this.token,
    };

    const body = {
      "form_submission":{
          "custom_form_id": this.state.checkedForm.attributes.custom_form_id,
          "status": 5,
          "submit_form_details": this.state.checkedForm.attributes.submit_form_details
        }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.formDiscardApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.formHistoryGetUrl+`/${this.state.checkedForm.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PutApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  settingsChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({settingsTabValue:newValue, searchQuery:""},()=>{
      this.getFormHistory();
    })
  }

  handleChange = (event:any) => {
    this.setState({selectedOption: event.target.value});
  };

  onPreview=()=>{
    if(typeof window !== 'undefined' && this.state.checkedForm){
      const id=this.state.checkedForm.attributes.custom_form_id;
      setTimeout(() => {
        window.location.href = `CustomForm/${id}/${this.state.checkedForm.id}/preview`;
      }, 0);
    }
  }

  onEdit=()=>{
    if(typeof window !== 'undefined' && this.state.checkedForm){
      const id=this.state.checkedForm.attributes.custom_form_id;
      setTimeout(() => {
        window.location.href = `CustomForm/${id}/${this.state.checkedForm.id}/edit`;
      }, 0);
    }
  }

  handleSearchChange = (event:any) => {
    this.setState({ searchQuery: event.target.value });
  };

   handleOpen = () => {
    this.setState({ discardedSuccessfulModal: true  });
  };

   handleClose = () => {
    this.setState({ discardedSuccessfulModal: false  });
  };

  // Customizable Area End
}
