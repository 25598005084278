import React from "react";
// Customizable Area Start
import { Headerlogo,linkExpired } from "../../landingpage/src/assets";
import { ipoImage } from "../../customform/src/assets";
import LinkList from "../../../components/src/LinkList";
import { withStyles,createStyles } from "@material-ui/core/styles";
// Customizable Area End
import ForgotPasswordController, { Props } from "./ForgotPasswordController";

export class LinkExpire extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <div className={this.props.classes.appContainerFG}>
        <div className={this.props.classes.headerFGLink}>
          <img src={Headerlogo} alt="Logo" className={this.props.classes.logoFGLink} />
          <img src={ipoImage} alt="Ipo-Logo" className={this.props.classes.logoFGLink} />
        </div>
        <div className={this.props.classes.cardRL}>
          <div className={this.props.classes.cardHeaderFG}>
            <span className={this.props.classes.headerTextFG}>
            { "Link Expired" }
            </span>
          </div>
          <div className={this.props.classes.centeredMessage}>
            
            <img className={this.props.classes.imageSize} src={linkExpired} alt="linkExpired"/>
            <p>
             {"Sorry, your password reset link has expired. Please click the"}
              <strong>
                {"“Forgot password”"}
              </strong>
              <br />
              {"at the login page to receive a new link"}
            </p>
          </div>
          <div className={this.props.classes.buttonsContainerFG}>
            <button
              className={this.props.classes.actionButton}
              id="backtoLandingPageweb"
              onClick={() =>{this.handleNavigation("LandingPageweb")}}
            >
              Login
            </button>
          </div>
        </div>
        <LinkList />
        <footer className={this.props.classes.customFooter}>
          <p className={this.props.classes.customFooterText}>
            Guidelines for using NTRP (Bharat Kosh) Payment Gateway{" "}
            <span 
            className={this.props.classes.startColor}>
            Guide
            </span>
          </p>
        </footer>
      </div>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyles = () =>
createStyles({
appContainerFG: {
  backgroundColor: '#ffd4ac',
  height: 'auto', 
  overflowX: 'hidden' ,
},
headerFGLink: {
  display: "flex",
  justifyContent: "space-between",
  backgroundColor: "#ffd4ac",
  padding: "10px",
  maxWidth: "100%",
  height: "100px",
  marginBottom: "40px"
},
logoFGLink: {
  objectFit: "contain",
  height: "92px",
  padding: "20px",
  '@media (max-width: 900px)': {
    height: "80px",
    padding: "10px",
  },
  '@media (max-width: 600px)': {
    height: "60px",
    padding: "5px",
  },
  '@media (max-width: 300px)': {
    height: "40px",
    padding: "4px",
  }
},
cardRL: {
  width: '75%',
  height: 'auto',
  minHeight: '450px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  backgroundColor: '#ffffff',
  borderRadius: '10px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  padding: '20px',
  margin: '0 auto',
  marginBottom: '40px',
  marginTop: '40px',
},
cardHeaderFG: {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '40px'
},
headerTextFG: {
  fontFamily: 'Poppins, sans-serif',
  fontSize: '25px',
  fontWeight: 600,
},
centeredMessage: {
  margin: 'auto',
  textAlign: 'center' ,
  fontFamily: 'Poppins, sans-serif',
  fontWeight: 400,
  fontSize: '22px',
},
imageSize: {
  width: '70px',
  height: '70px',
  marginTop: '20px',
  marginBottom: '10px',
},
buttonsContainerFG: {
  display: 'flex',
  marginBottom: '30px', // Note: Using is discouraged and should be avoided if possible.
  marginTop: '10px', // Note: Using is discouraged and should be avoided if possible.
  justifyContent: 'center',
},
customFooter: {
  backgroundColor: '#ffffff',
  padding: '10px',
  textAlign: 'center' ,
  height: '70px', // Note: Using is discouraged and should be avoided if possible.
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
},
customFooterText: {
  margin: '0',
  fontSize: '20px', // Note: Using is discouraged and should be avoided if possible.
  color: '#000000',
  fontWeight: 400,
  lineHeight: '45px', // Note: Using is discouraged and should be avoided if possible.
  fontFamily: 'Poppins-Medium, sans-serif', // Note: Using is discouraged and should be avoided if possible.
},
startColor: {
  color: 'red',
},
actionButton: {
  padding: "10px 20px",
  fontSize: "18px",
  fontWeight: "bold",
  borderRadius: "5px",
  margin: "0 10px" /* Add some margin between the buttons */,
  cursor: "pointer",
  minWidth: "300px" /* Minimum width for the button */,
  height: "54px" /* Height for the button */,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textTransform: "none",
  fontFamily: 'Poppins, sans-serif',
  backgroundColor: "#894402", // Background color
  color: "#ffffff", // Text color
  border: "none" // Remove border
},
})
export default withStyles(webStyles)(LinkExpire)
// Customizable Area End
