import React from "react";
import {
    // Customizable Area Start
    withStyles, Typography, Theme, Grid, Box, Container, createStyles
    // Customizable Area End
} from "@material-ui/core";
import { logo } from "../../landingpage/src/assets";
import "../../dashboard/src/Dashboard.css"
import { dipp, indiaPortal, myGov, nvsp } from "./assets";
import TermsConditionsController, { Props } from "./TermsConditionsController";

// Customizable Area Start
import Navbar from "../../../components/src/Navbar";
import Loader from "../../../components/src/Loader";
import { ipoImage, logoImage } from "../../customform/src/assets";
// Customizable Area End
export class PrivacyPolicy extends TermsConditionsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <Box className={this.props.classes.pageContainerPP}>
                    <Box className={this.props.classes.logoContainerPP}>
                        <img src={logoImage} alt="Logo" className={this.props.classes.logoAboutus} />
                        <img src={ipoImage} alt="Ipo-Logo" className={this.props.classes.logoAboutus} />
                    </Box>
                        <Box className={this.props.classes.outerContainer}>
                        <Box className={this.props.classes.innerContainerPP}>
                            <Navbar selectedTab={0} navigation={this.props.navigation} />
                            <Box className={this.props.classes.pageLayout}>
                            {this.state.isLoading ?
                                <Loader loading={this.state.isLoading} />
                            :   
                                <Typography className={this.props.classes.privacyText} dangerouslySetInnerHTML={{
                                    __html: this.state.privacypolicyData.replace(
                                      /font-family:[^;]+;/g,
                                      'font-family: Poppins, sans-serif'
                                    )
                                  }}/>
                            }
                            </Box>
                            <Grid item xs={12} >
                                <Grid container direction="row" justifyContent="center" spacing={2} className={this.props.classes.bottomImagesLayout}>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <img src={indiaPortal} alt="" width={161} height={54} className={this.props.classes.imageLayout} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <img src={nvsp} width={269} height={54} className={this.props.classes.imageLayout} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <img src={dipp} alt="" width={222} height={54} className={this.props.classes.imageLayout} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <img src={myGov} alt="" width={137} height={54} className={this.props.classes.imageLayout} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                        </Box>
                    <footer className={this.props.classes.footerPV}>
                        <p className={this.props.classes.footerTextPV}>
                            Guidelines for using NTRP (Bharat Kosh) Payment Gateway <span className={this.props.classes.startColorPV}>Guide</span>
                        </p>
                    </footer>
                </Box>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

export const PrivacyPolicyStyles = (theme: Theme) => 
createStyles({
    pageContainerPP: {
        overflowX: 'hidden',
    },
    logoContainerPP: {
        display: "flex",
        backgroundColor: "#ffd4ac",
        justifyContent: "space-between",
        padding: "20px",
        maxWidth: "100%",
        height: "120px",
    },
    innerContainerPP: {
        backgroundColor: 'white',
        borderRadius: '20px',
        boxShadow: '5px 10px 8px #888888',
        marginBottom: '5% !important',
    },
    outerContainer: {
        backgroundColor: "#ffd4ac",
        padding: "20px 180px",
        fontFamily: "'Poppins, sans-serif'",
        '@media (max-width: 900px)':{
            padding: "16px 40px",
        },
        '@media (max-width: 600px)':{
            padding: "10px 20px",
        },
        '@media (max-width: 300px)':{
            padding: "10px 10px",
        },
    },
    pageLayout: {
        padding: "40px 60px",
        '@media (max-width: 900px)':{
            padding: "20px 40px",
          },
          '@media (max-width: 600px)':{
            padding: "10px 30px",
          },
          '@media (max-width: 300px)':{
            padding: "10px 20px",
          },
    },
    logoAboutus: {
        objectFit: "contain",
        height: "105px",
        padding: "4px",
        '@media (max-width: 900px)': {
          height: "80px",
          padding: "10px",
        },
        '@media (max-width: 600px)': {
          height: "60px",
          padding: "5px",
        },
        '@media (max-width: 300px)': {
          height: "40px",
          padding: "4px",
        }
    },
    privacyText: {
        fontFamily: `Poppins, sans-serif !important`,
        "& h1" : {
            margin: '8px',
        },
        "& h2" : {
            fontWeight: 400,
        },
        "& p" : {
            margin: '0px'
        }
    },
    heading: {
        fontSize: 26,
        fontWeight: 700,
        fontFamily: `Poppins, sans-serif !important`,
        lineHeight: "60px",
        textAlign: 'center',
        color: "#894402",
    },
    description: {
        fontSize: 18,
        fontWeight: 400,
        fontFamily: 'Poppins, sans-serif',
        textAlign: 'start',
        color: "#000000"
    },
    subHeading: {
        fontSize: 25,
        fontWeight: 400,
        fontFamily: 'Poppins, sans-serif',
        textAlign: 'start',
        color: "#000000",
        lineHeight: "50px",
    },
    footerPV: {
        backgroundColor: "#ffffff",
        padding: "10px",
        height: "70px !important",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      startColorPV: {
        color: "red", // Set the text color to red
      },
      footerTextPV: {
        margin: 0,
        fontSize: "20px !important",
        color: "#000000",
        fontWeight: 400,
        lineHeight: "45px !important",
        fontFamily: "Poppins-Medium, sans-serif !important",
    },
    imageLayout: {
        objectFit: "contain",
        width: "100%",
        height: "40px",
        margin: "auto"
      },
      bottomImagesLayout:{
        padding:"2.5rem"
      }

});
export default withStyles(PrivacyPolicyStyles)(PrivacyPolicy);
// Customizable Area End