import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { FormData ,CustomFormSection } from "./CustomForm.web" 
import { toast } from "react-toastify";
import * as Yup from "yup";
import {successIcon,faildIcon} from './assets'
import { getStorageData } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: {
    navigate: (path:string) => void;
    goBack: () => void;
  };
  id: string;
  classes?: any;
  // Customizable Area End
}

// Customizable Area Start

export interface Dropdown {
  label: string;
  value: string;
}

type ArrayFormValues = {
  [sectionId: string]:{
    [fieldName: string]: string | number | boolean;
  }[]
}

type ObjectFormValues = {
  [sectionId: string]:{
    [fieldName: string]: string | number | boolean ;
  }
}

type FormValues =  ArrayFormValues | ObjectFormValues;

// Customizable Area End

interface S {
  // Customizable Area Start
  isPreview:any;
  isEdit:any;
  formDataId: any;
  loading: boolean;
  formData: FormData,
  modalData: {
    currentEditIndex:  number | null,
    isModalOpen: boolean,
    modalId: string
  },
  isPublished:number,
  messageModal:{
    isError:boolean,
    message:string,
    showModal:boolean,
    icon:string,
    buttonLabel:string,
  }
  formTypeOptions: { id: string, type: string, attributes: { id: number, name: string } }[];
  selectedFormType:string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class CustomFormController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCustomFormApiCallId: string = "";
  getCreatedFormApiCallId: string = "";
  formSubmissionApiId: string = "";
  intialValues: FormValues = {}
  getFormTypeOptionsApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      formDataId:"",
      isPreview:false,
      isEdit:false,
      formData: {} as FormData,
     
      modalData: {
        currentEditIndex: null,
        isModalOpen: false,
        modalId:""
      },
      isPublished:configJSON.markAsDarft,
      messageModal:{
        isError:false,
        message:"",
        showModal:false,
        icon:successIcon,
        buttonLabel:"",
      },
      formTypeOptions: [],
      selectedFormType:""
      // Customizable Area End
    };

    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (responseJson.status === 500 || responseJson.status === 404) {
          toast.error("Something went wrong!");
          this.setState({ loading: false });
        } else if (responseJson.errors) {
          toast.error(responseJson.errors);
          this.setState({ loading: false });
        } else if(apiRequestCallId == this.getCreatedFormApiCallId){
          const finalData=responseJson.data.attributes.submit_form_details.data.attributes.section.data.map((data:any)=>{
            data.attributes.fixed_filed_name=data.attributes.fixed_filed_name?.toLowerCase()
            const fields=data.attributes.fields.data.map((field:any)=>{
              field.attributes.fixed_filed_name=field.attributes.fixed_filed_name?.toLowerCase()
              return field
            })
            data.attributes.fields.data=fields
            return data
          })
          let formData={...responseJson.data.attributes.submit_form_details.data}
          formData.attributes.section.data=finalData

          formData.attributes.section.data = formData.attributes.section.data.sort(
            (a: any, b: any) => a.attributes.section_position - b.attributes.section_position
          );

          this.setState({ formData, loading:false, formDataId: responseJson.data.id})
          this.setState({ formData: responseJson.data.attributes.submit_form_details.data, loading: false })
        } else if (apiRequestCallId == this.getCustomFormApiCallId) {
          const finalData=responseJson.data.attributes.section.data.map((data:any)=>{
            data.attributes.fixed_filed_name=data.attributes.fixed_filed_name?.toLowerCase()
            const fields=data.attributes.fields.data.map((field:any)=>{
              field.attributes.fixed_filed_name=field.attributes.fixed_filed_name?.toLowerCase()
              return field
            })
            data.attributes.fields.data=fields
            return data
          })
          let formData={...responseJson.data}
          formData.attributes.section.data=finalData

          formData.attributes.section.data = formData.attributes.section.data.sort(
            (a: any, b: any) => a.attributes.section_position - b.attributes.section_position
          );

          this.setState({ formData, loading:false })
          this.setState({ formData: responseJson.data, loading: false })
        } else if (apiRequestCallId == this.getFormTypeOptionsApiCallId) {
          this.setState({ loading: false, formTypeOptions: responseJson.data });
        }
      }

      if(apiRequestCallId===this.formSubmissionApiId){
        this.formSubmitApiResponseHandler(responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.fecthFormData()  
  }

  fecthFormData=async()=>{
    const params=window.location.pathname.split('/');
    let formId = params[3];

    if(params[4] == "preview"){
      this.setState({isPreview:true})
    }else if(params[4] == "edit"){
      this.setState({isEdit:true})
    }
    
    if(params.length>=3 && !formId){
      this.getCustomFormData(params[2]);
    }
    if(formId){
      this.getCreatedFormData(formId)
    }

    this.getFormTypeOptions()
  }

  handleSubmit = (values: FormValues) => {
    let sectionId
     this.state.formData.attributes.section.data.forEach((section) => {
      sectionId = section.id;
      const defaultSectionValues = values[sectionId];
      section.saved_values = defaultSectionValues;
     })
     let payload={
        "form_submission":{
            "custom_form_id": this.state.formData.id,
            "status": this.state.isPublished,
            "submit_form_details":
              {
                "data": this.state.formData
              }
          }
     }
     this.handleFormSubmission(payload)
  };

  createStringSchema = (fieldName: string, required: boolean, min_value: number, max_value: number) => {
    let schema = Yup.string();
    if (required) {
      schema = schema.required(`${fieldName} is required`);
    }
    if (min_value) {
      schema = schema.min(min_value, `${fieldName} length must be at least ${min_value}`);
    }
    if (max_value) {
      schema = schema.max(max_value, `${fieldName} length must not be more than ${max_value}`);
    }
    return schema;
  };

  createNumberSchema = (fieldName: string, required: boolean, min_value: number, max_value: number) => {
    let schema = Yup.number();
    if (required) {
      schema = schema.required(`${fieldName} is required`);
    }
    schema = schema.typeError(`${fieldName} must contain numbers only`);
    if (min_value) {
      schema = schema.test('min', `${fieldName} length must be at least ${min_value}`, (val) => Boolean(val && val.toString().length >= min_value));
    }
    if (max_value) {
      schema = schema.test('max', `${fieldName} length must not be more than ${max_value}`, (val) => Boolean(val && val.toString().length <= max_value));
    }
    return schema;
  };

  generateValidationSchema = (formData: FormData) => {

    const schema: { [sectionId: string]: Yup.ObjectSchema | Yup.ArraySchema<any> } = {};

    formData.attributes.section.data.forEach((section) => {
      if(section.attributes.section_as_array === false){
        const sectionSchema: { [field: string]: Yup.StringSchema | Yup.NumberSchema | Yup.BooleanSchema } = {};
     
        const sectionId = section.id;
  
        section.attributes.fields.data.forEach((field) => {
         
          const fieldAttributes = field.attributes;
          const name = fieldAttributes.fixed_filed_name;
          const { min_value, max_value, required }  = fieldAttributes
          const fieldName = fieldAttributes.field_name;
  
          switch (fieldAttributes.field_type) {
            case "text":
              sectionSchema[name] = this.createStringSchema(fieldName, required, min_value, max_value);
              break;
              case "string":
              sectionSchema[name] = this.createStringSchema(fieldName, required, min_value, max_value);
              break;
            case "integer":
            sectionSchema[name] = this.createNumberSchema(fieldName, required, min_value, max_value);
            break;
            case "boolean":
              sectionSchema[name] = Yup.boolean().when(String(required), 
                { is: "true",
                  then: Yup.mixed().required(`${fieldName} is required`),
                });
              break;
            case "dropdown":
            sectionSchema[name] = Yup.mixed().test('is-non-empty', `${fieldName} is required`, function(value) {
              if (required) {
              return (typeof value === 'string' && value.trim() !== '') || (typeof value === 'object' && Object.keys(value).length !== 0);
              }
              return true; 
             });
            break;
           
          }
        });
  
        schema[sectionId] = Yup.object().shape(sectionSchema);
      }
     
    });


    const lastSectionSchema = Yup.object().shape({
      "term_and_conditions": Yup.boolean().oneOf([true],  'You must agree that the above information submitted by you is true to your best knowledge')
    });
    schema["last_section"] = lastSectionSchema;
  
    return Yup.object().shape(schema);
  };

  generateInitialValues = (formData: FormData) => {
    const initialValues: FormValues = {};
  
    formData.attributes.section.data.forEach((section) => {
      const sectionId = section.id;
  
      if (section.saved_values) {
        initialValues[sectionId] = section.saved_values;
      } else {
        if (section.attributes.section_as_array === false) {
          initialValues[sectionId] = {};
  
          section.attributes.fields.data.forEach((field) => {
            const fieldAttributes = field.attributes;
            const name = fieldAttributes.fixed_filed_name
            const fieldType = fieldAttributes.field_type;
            const defaultValue = fieldAttributes.default_value;
            if(name==="country" && fieldType === "dropdown"){
              (initialValues as ObjectFormValues)[sectionId][name] =  {
                "name": defaultValue ? "India" : "",
                "isoCode": defaultValue ? "IN" : undefined
            } as any
          } 
             else{
              (initialValues as ObjectFormValues)[sectionId][name] = defaultValue || "";
             }
          });
        } 
        else {
          (initialValues as ArrayFormValues)[sectionId] = [];
        }
      }
    });
    // For the "last_section," you can directly assign a known type
    initialValues["last_section"] = {};
    initialValues["last_section"]["term_and_conditions"] = false;
  
    return initialValues;
  };

  generateValidationSchemaForModal = (section: CustomFormSection) => {
    const sectionSchema: { [field: string]: Yup.StringSchema | Yup.NumberSchema | Yup.BooleanSchema } = {};
      section.attributes.fields.data.forEach((field) => {
        const fieldAttributesForModal = field.attributes;
        const name = fieldAttributesForModal.fixed_filed_name;
        const fieldName = fieldAttributesForModal.field_name;
        const { min_value, max_value, required} = fieldAttributesForModal
        switch (fieldAttributesForModal.field_type) {
          case "string":
          sectionSchema[name] = this.createStringSchema(fieldName, required, min_value, max_value);
          break;
          case "text":
          sectionSchema[name] = this.createStringSchema(fieldName, required, min_value, max_value);
          break;
          case "integer":
          sectionSchema[name] = this.createNumberSchema(fieldName, required, min_value, max_value);
          break;
          case "dropdown": sectionSchema[name] = Yup.mixed().test('is-non-empty', `${fieldName} is required`, function(value) {
            if (required) { return (typeof value === 'string' && value.trim() !== '') || (typeof value === 'object' && Object.keys(value).length !== 0);}
            return true; 
           });
          break;
          case "boolean":
          sectionSchema[name] = Yup.boolean().when(String(required), {
              is: "true",
              then: Yup.mixed().required(`${fieldName} is required`),
            });
          break;
        }
      });
  
    return Yup.object().shape(sectionSchema);
  };


  generateInitialValuesForModal = (section: CustomFormSection) => {

    const initialValues: {
      [fieldName: string]: string | number | boolean;
    }  = {}; 

      section.attributes.fields.data.forEach((field) => {
        const fieldAttributes = field.attributes;
        const name = fieldAttributes.fixed_filed_name;
        const fieldType = fieldAttributes.field_type;
        const defaultValue = fieldAttributes.default_value;
        if( name === "country" && fieldType === "dropdown" ){
          initialValues[name] = {
            "name": defaultValue ? "India" : "",
            "isoCode": defaultValue ? "IN" : undefined
        } as any}
         else{
          initialValues[name] = defaultValue || "";
         }
      });
  
    return initialValues;
  };

  getFormTypeOptions = () => {
    this.setState({ loading: true });

    const header = {
      "Content-Type": configJSON.getUserListApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFormTypeOptionsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFormTypeOptionsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getUserListApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };


  getCustomFormData = (id:string) => {
    
    this.setState({ loading: true });

    const header = {
      "Content-Type": configJSON.getUserListApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCustomFormApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCustomFormApiEndPoint}${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getUserListApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getCreatedFormData = async (id:string) => {

    const token = await getStorageData('authToken')
    
    this.setState({ loading: true });

    const header = {
      "Content-Type": configJSON.getUserListApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCreatedFormApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.customFormSubmissions}/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getUserListApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleSameAsApplicant = (formFields:CustomFormSection, formData: FormData, values: ObjectFormValues, setFieldValue:(name: string, values: any) => void ) => {
    if(formFields.attributes.section_reference){
    const sectionReference = formData.attributes.section.data
    .find((section) => 
      section.attributes.section_position === Number(formFields.attributes.section_reference))
      if(sectionReference){
        formFields.attributes.fields.data.forEach((field) => {
          if(field.attributes.field_type === "boolean"){
            setFieldValue(field.attributes.fixed_filed_name, !values[sectionReference.id][field.attributes.fixed_filed_name])
          }
          setFieldValue(field.attributes.fixed_filed_name, values[sectionReference.id][field.attributes.fixed_filed_name])
        })
      }
    }
  } 
  
  handleFormSubmission=(body:object)=>{
    this.setState({ loading: true });

    let method = configJSON.addUserApiMethod;
    let endpoint = configJSON.customFormSubmissions;

    if(this.state.isEdit){
      endpoint = endpoint + `/${this.state.formDataId}`;
      method = configJSON.editUserApiMethod;
    }

    const header = {
      "Content-Type": configJSON.getUserListApiContentType,
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.formSubmissionApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  setIsPublished=(isPublished:number)=>{
    this.setState({isPublished})
  }

  handleMessageModalClose=()=>{
    this.setState(prevState=>({messageModal:{...prevState.messageModal,showModal:false}}))
    if(this.state.messageModal.buttonLabel!=configJSON.faildBtnLabel){
      this.navigateToFormHistory()
    }
  }

  goBack=()=>{
    this.props.navigation.goBack()
  }

  navigateToFormHistory=()=>{
    const msg: Message = new Message(getName(MessageEnum.NavigationFormHistoryMessage));
    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(msg);
  }

  formSubmitApiResponseHandler=(responseJson:any)=>{
        const data=responseJson.data
        if(data){
          let message=''
          let buttonLabel
          if(this.state.isPublished===configJSON.markAsPublished){
            message=`${configJSON.formSubmitMessage}${data.attributes.application_number}`
            buttonLabel=configJSON.markAsPublishedLabel
          }else{
            message=`${configJSON.formSaveMessage}${data.attributes.application_number}`
            buttonLabel=configJSON.markAsDarftBtnLabel
          }

          this.setState({
            messageModal:{
              message,
              showModal:true,
              isError:false,
              icon:successIcon,
              buttonLabel
            }
          })
        }else{
          this.setState({
            messageModal:{
              message:configJSON.formFaildMessage,
              showModal:true,
              isError:false,
              icon:faildIcon,
              buttonLabel:configJSON.faildBtnLabel
            }
          })
        }
        this.setState({loading:false})
  }  

  navigateToCustomForm=(evt:any)=>{
    const id=evt.target.value
    this.setState({selectedFormType:id.toString()})
    window.location.href= `CustomForm/${id}`
  }
  // Customizable Area End
}