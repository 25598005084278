import React, { Component } from "react";
// Customizable Area Start
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
} from "@material-ui/core/styles";
import {
  Box,
  Grid,
  TextField,
  Typography,
  Select,
  MenuItem,
  Checkbox,
} from "@material-ui/core";
import {
  Field,
  ErrorMessage,
  FieldAttributes,
  FormikValues,
} from "formik";
import {
  CheckBoxOutlineBlankSharp,
  CheckBoxSharp,
  ExpandMoreRounded,
} from "@material-ui/icons";
import { Country, State, City } from "country-state-city";
// Customizable Area End

// Customizable Area Start
const selectStyles = (theme: Theme) =>
  createStyles({
    inputField: {
      '&.Mui-disabled': {
        "& .MuiSvgIcon-root": {
          color: "transparent",
        },
      },
      "& .MuiOutlinedInput-input": {
        padding: "16px",
        '@media (max-width: 1600px)': {
          padding: "10px",
        }
      },
      "& .MuiInputBase-input": {
        fontSize: "24px",
        fontFamily: "Poppins, sans-serif",
        display: "flex",
        alignItems: "center",
        color: "black !important",
        '@media (max-width: 1600px)': {
          fontSize: "18px",
        }
      },
      "& .MuiSvgIcon-root": {
        color: "black",
      },
    },
    menuItem: {
      fontFamily: "Poppins, sans-serif",
    },
    menuDisabledItem: {
      color: "#C0C0C0",
      fontFamily: "Poppins, sans-serif",
    },
  });

interface SelectProps extends WithStyles<typeof selectStyles> {
  name: string;
  values: FormikValues;
  disabled: boolean;
  fixedName: string;
  options: {
    id: number;
    value: string;
    custom_form_field_id: number;
    created_at: string;
    updated_at: string;
  }[];
  placeholder: string;
}

class SelectComponent extends Component<SelectProps> {
  renderOptions = () => {
    const {
      name,
      values,
      fixedName,
      classes,
      disabled,
      placeholder,
    } = this.props;

    const renderValue = (selectValue: { name: string }) => {
      if (selectValue.name) {
        return <span>{selectValue.name}</span>;
      }
      return <span className={classes.menuDisabledItem}>{placeholder}</span>;
    };

    const renderDropdownValue = (selectValue: string) => {
      if (selectValue) {
        return <span>{selectValue}</span>;
      }
      return <span className={classes.menuDisabledItem}>{placeholder}</span>;
    };


    const commonProps: FieldAttributes<any> = {
      as: Select,
      name: name,
      type: "text",
      variant: "outlined",
      className: classes.inputField,
      IconComponent: ExpandMoreRounded,
      displayEmpty: true,
      disabled,
    };

    switch (fixedName) {
      case "country":
        return (
          <Field {...{ renderValue, ...commonProps }}>
            {Country.getAllCountries().map(({ name, isoCode }) => (
              <MenuItem
                className={classes.menuItem}
                key={name}
                value={{ name, isoCode } as any}
              >
                {name}
              </MenuItem>
            ))}
          </Field>
        );
      case "state":
        return (
          <Field {...{ renderValue, ...commonProps }}>
            {State.getStatesOfCountry(values.country?.isoCode).map(
              ({ name, isoCode }) => (
                <MenuItem
                  className={classes.menuItem}
                  key={name}
                  value={{ name, isoCode } as any}
                >
                  {name}
                </MenuItem>
              )
            )}
          </Field>
        );
      case "city":
        return (
          <Field {...{ renderValue, ...commonProps }}>
            {City.getCitiesOfState(
              values.country?.isoCode,
              values.state?.isoCode
            ).map(({ name }) => (
              <MenuItem
                className={classes.menuItem}
                key={name}
                value={{ name } as any}
              >
                {name}
              </MenuItem>
            ))}
          </Field>
        );
      default:
        return (
          <Field {...{ renderValue: renderDropdownValue, ...commonProps }}>
            {this.props.options.map((selectValue) => (
              <MenuItem
                className={classes.menuItem}
                key={selectValue.id}
                value={selectValue.value}
              >
                {selectValue.value}
              </MenuItem>
            ))}
          </Field>
        );
    }
  };

  render() {
    return this.renderOptions();
  }
}

const SelectElement = withStyles(selectStyles)(SelectComponent);

const booleanStyles = () =>
  createStyles({
    inputBooleanLabel: {
      flex: 1,
      fontSize: "24px",
      fontFamily: "Poppins, sans-serif",
      '@media (max-width: 1600px)': {
        fontSize: "18px",
      },
      "& .MuiCheckbox-root": {
        padding: "10px 10px 10px 0px"
      }
    },
    requiredText: {
      color: "red",
      fontFamily: "Poppins, sans-serif",
    },
    errorText: {
      color: "red",
      fontSize: "20px",
      fontFamily: "Poppins, sans-serif",
      marginTop: "2px",
      '@media (max-width: 1600px)': {
        fontSize: "14px",
      }
    },
    checkedIcon: {
      color: "#33CD33",
    },
  });

interface BooleanProps extends WithStyles<typeof booleanStyles> {
  name: string;
  disabled: boolean;
  fixedName: string;
  error: string | undefined,
  touched: boolean | undefined,
  required: boolean,
  label: string,
  handleSameAsApplicant?: () => void,
  values: FormikValues,
  setFieldValue?: (field: string, value: any) => void,
}

class BooleanComponent extends Component<BooleanProps> {
  renderOptions = () => {
    const { name, handleSameAsApplicant, error, touched, disabled, required, fixedName, classes, label, values, setFieldValue } = this.props;

    const searchString = "address"
    if (fixedName.includes(searchString)) {
      return (
        <Grid item xs={12} sm={12}>
          <Typography className={classes.inputBooleanLabel}>
            <Field
              as={Checkbox}
              name={name}
              data-test-id="checkboxSameAsInput"
              onChange={(e: {
                target: {
                  checked: boolean
                }
              }) => {
                if (e.target.checked && handleSameAsApplicant) {
                  handleSameAsApplicant()
                }
                setFieldValue && setFieldValue(name , e.target.checked)
              }}
              icon={
                <CheckBoxOutlineBlankSharp
                  color={Boolean(error && touched) ? "error" : undefined}
                />
              }
              checkedIcon={<CheckBoxSharp className={classes.checkedIcon} />}
              disabled={disabled}
              checked={values[fixedName]}
            />
            {label}
            {required && (
              <span className={classes.requiredText}>*</span>
            )}
          </Typography>
          <ErrorMessage name={name} component="p" className={classes.errorText} />
        </Grid>
      )
    }
    else {
      return (<Grid item xs={12} sm={12}>
        <Typography className={classes.inputBooleanLabel}>
          <Field
            as={Checkbox}
            name={name}
            data-test-id="checkboxSameAsInput"
            icon={
              <CheckBoxOutlineBlankSharp
                color={Boolean(error && touched) ? "error" : undefined}
              />
            }
            checkedIcon={<CheckBoxSharp className={classes.checkedIcon} />}
            disabled={disabled}
          />
          {label}
          {required && (
            <span className={classes.requiredText}>*</span>
          )}
        </Typography>
        <ErrorMessage name={name} component="p" className={classes.errorText} />
      </Grid>)
    }
  };

  render() {
    return this.renderOptions();
  }
}

const BooleanElement = withStyles(booleanStyles)(BooleanComponent);

interface InputProps extends WithStyles<typeof styles> {
  fieldType: "dropdown" | "string" | "integer" | "boolean" | "text";
  required: boolean;
  error: string | undefined;
  rowCount?: number;
  label: string;
  name: string;
  disabled: boolean;
  fixedName: string;
  placeholder: string;
  touched: boolean | undefined;
  options: {
    id: number;
    value: string;
    custom_form_field_id: number;
    created_at: string;
    updated_at: string;
  }[];
  handleSameAsApplicant?: () => void
  values: FormikValues;
  setFieldValue?: (field: string, value: any) => void;
}
// Customizable Area End
class InputElement extends Component<InputProps> {

// Customizable Area Start
  assignFieldType = (fieldType: string) => {
    if (fieldType === "string") {
      return "text"
    }
    if (fieldType === "integer") {
      return "number"
    }
    return fieldType
  }
// Customizable Area End

  render() {
    // Customizable Area Start

    const {
      classes,
      fieldType,
      error,
      required,
      rowCount = 1,
      label,
      name,
      touched,
      options,
      values,
      disabled,
      placeholder,
      fixedName,
      handleSameAsApplicant,
      setFieldValue,
    } = this.props;
    // Customizable Area End


    return (
      // Customizable Area Start
      <>
        {fieldType !== "dropdown" && fieldType !== "boolean" &&
          <>
            <Grid item xs={12} sm={4}>
              <Typography className={classes.inputLabel}>
                {label}
                {required && (
                  <span className={classes.requiredText}>*</span>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Box className={classes.inputFieldContainer}>
                <Field
                  as={TextField}
                  name={name}
                  placeholder={placeholder}
                  error={Boolean(error && touched)}
                  type={this.assignFieldType(fieldType)}
                  multiline={rowCount > 1}
                  rows={rowCount}
                  className={classes.inputField}
                  variant="outlined"
                  disabled={disabled}
                />
                <ErrorMessage
                  name={name}
                  component="p"
                  className={classes.errorText}
                />
              </Box>
            </Grid>
          </>
        }
        {fieldType === "boolean" &&
          <BooleanElement
            data-test-id="booleanElementInput"
            name={name} fixedName={fixedName}
            label={label}
            required={required} error={error}
            touched={touched} disabled={disabled}
            handleSameAsApplicant={handleSameAsApplicant} 
            values={values}
            setFieldValue={setFieldValue}/>
        }
        {fieldType === "dropdown" && (
          <>
            <Grid item xs={12} sm={4}>
              <Typography className={classes.inputLabel}>
                {label}
                {required && (
                  <span className={classes.requiredText}>*</span>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Box className={classes.inputFieldContainer}>
                <SelectElement
                  data-test-id="selectElementInput"
                  disabled={disabled}
                  name={name}
                  fixedName={fixedName}
                  placeholder={placeholder}
                  options={options}
                  values={values}
                />
                <ErrorMessage
                  name={name}
                  component="p"
                  className={classes.errorText}
                />
              </Box>
            </Grid>
          </>
        )}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    inputLabel: {
      flex: 1,
      fontSize: "24px",
      wordWrap: "break-word",
      fontFamily: "Poppins, sans-serif",
      '@media (max-width: 1600px)': {
        fontSize: "18px",
      }
    },
    inputBooleanLabel: {
      flex: 1,
      fontSize: "24px",
      fontFamily: "Poppins, sans-serif",
      '@media (max-width: 1600px)': {
        fontSize: "18px",
      },
      "& .MuiCheckbox-root": {
        padding: "10px 10px 10px 0px"
      }
    },
    inputField: {
      "& .MuiOutlinedInput-multiline": {
        padding: "0px",
      },
      "& .MuiOutlinedInput-input": {
        padding: "16px",
        '@media (max-width: 1600px)': {
          padding: "10px",
        }
      },
      "& input::placeholder": {
        color: "#C0C0C0",
        opacity: 1
      },
      "& .MuiInputBase-input": {
        fontSize: "24px",
        fontFamily: "Poppins, sans-serif",
        color: "black !important",
        '@media (max-width: 1600px)': {
          fontSize: "18px",
        }
      },
    },
    textField: {
      "& .MuiOutlinedInput-multiline": {
        padding: "0px",
      },
      "& .MuiOutlinedInput-input": {
        padding: "16px",
        '@media (max-width: 1600px)': {
          padding: "10px",
        }
      },
      "& textarea::placeholder": {
        color: "#C0C0C0",
        opacity: 1
      },
      "& .MuiInputBase-input": {
        fontSize: "24px",
        fontFamily: "Poppins, sans-serif",
        '@media (max-width: 1600px)': {
          fontSize: "18px",
        }
      },
    },
    inputFieldContainer: {
      flex: 2,
      display: "flex",
      flexDirection: "column",
    },
    requiredText: {
      color: "red",
      fontFamily: "Poppins, sans-serif",
    },
    errorText: {
      color: "red",
      fontSize: "20px",
      fontFamily: "Poppins, sans-serif",
      marginTop: "2px",
      '@media (max-width: 1600px)': {
        fontSize: "14px",
      }
    },
    checkedIcon: {
      color: "#33CD33",
    },
  });

export default withStyles(styles)(InputElement);
// Customizable Area End
