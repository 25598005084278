import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import randomstring from "randomstring";

export const generateString=()=> {
  const uppercaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const lowercaseChars = "abcdefghijklmnopqrstuvwxyz";
    const numberChar = "0123456789";
    const allChars = uppercaseChars + lowercaseChars+numberChar;

    let generatedString = "";

    // Add one uppercase letter
    generatedString += randomstring.generate({
      length: 1,
      charset: uppercaseChars,
      readable: true
    });

    // Add one lowercase letter
    generatedString += randomstring.generate({
      length: 1,
      charset: lowercaseChars,
      readable: true
    });

    // Add the remaining characters
    generatedString += randomstring.generate({
      length: 1, // Total length minus the 2 characters added above
      charset: numberChar,  
      readable: true
    });

    // Add the remaining characters
    generatedString += randomstring.generate({
      length: 2, // Total length minus the 2 characters added above
      charset: allChars,
      readable: true
    });


    return generatedString;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?:any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  captcha: any;
  credentials: any;
  username: any;
  logintype: any;
  inputcaptcha: any;
  usernameError: any;
  credentialsError: any;
  logintypeError: any;
  captchaError: any;
  loginFailError:any;
  guideData:any;
  buttonDisable:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      captcha: "",
      credentials: "",
      username: "",
      logintype: "password",
      inputcaptcha: "",
      usernameError: "",
      credentialsError: "",
      logintypeError: "",
      captchaError: "",
      loginFailError:"",
      guideData:[],
      buttonDisable:false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
  
    console.log("receive response::::", responseJson);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      this.handleApiResponse(message);
    }
    // Customizable Area End
  }

  // Customizable Area Start

  loginuserId: any;
  getGuideRulesId: any;

  async handleApiResponse(message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
  
    console.log("receive response:", responseJson, apiRequestCallId);
  
    if (apiRequestCallId === this.getGuideRulesId) {
      this.handleGuideRulesResponse(responseJson);
    }
  }
  
  handleGuideRulesResponse(responseJson:any) {
    console.log("getBlock data: getGuideRulesId ::", responseJson);
  
    if (responseJson.guide) {
      this.setState({ guideData: responseJson.guide.data });
    }
  }
  getGuideRulesApi = async () => {
    const header = {
      "Content-Type": "application/json"
    };

    console.log("getGuideRulesApi ::");
    

    this.apiCall({
      setApiCallId: "getGuideRulesId",
      header,
      method: "GET",
      endPoint: configJSON.getGuideEndpoint
    });
  };

  apiCall = async (data: any) => {
    const { setApiCallId, header, endPoint, method, body } = data;
    console.log(
      "calling api================================",
      setApiCallId,
      endPoint,
      body,
      header,
      method
    );

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if (setApiCallId === "getGuideRulesId") {
      this.getGuideRulesId = requestMessage.messageId;
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  async componentDidMount() {
    await this.getGuideRulesApi();
  }
 
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  // Customizable Area End
}
