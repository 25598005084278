export const triangle = require("../assets/triangle.png");
export const progressBar = require("../assets/Circle.png");
export const settingIcon = require("../assets/settings.png");
export const progressIcon = require("../assets/Progress.png");
export const mailIcon = require("../assets/mail.png");
export const qrCodeIcon = require("../assets/qrcode.png");
export const payPalLogo = require("../assets/paypal.png");
export const rightArrow = require("../assets/Icon.png");
export const logo = require("../assets/group_logo.png");
export const leftLogo = require("../assets/logo-image.png");
export const rightLogo = require("../assets/ipo-logo.png");
