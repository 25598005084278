// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'
import '../src/assets/style.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";
import LandingPageweb from "../../blocks/landingpage/src/LandingPage.web";
import ForgotPasswordWeb from "../../blocks/forgot-password/src/ForgotPassword.web";
import ResetPassWord from "../../blocks/forgot-password/src/ResetPassWord.web";
import ResetLink from "../../blocks/forgot-password/src/ResetLink.web";
import SetPassword from "../../blocks/forgot-password/src/SetPassword.web";
import LinkExpire from "../../blocks/forgot-password/src/LinkExpire.web";
import {FormHistory1} from "../../blocks/dashboard/src/FormHistory.web";
import {DashboardHome} from "../../blocks/dashboard/src/Dashboard.web";
import OrganisationDetails from '../../blocks/email-account-registration/src/OrganisationDetails.web'
import DigitalSignature from '../../blocks/email-account-registration/src/DigitalSignature.web'
import NewUserRegistrationForm from '../../blocks/email-account-registration/src/NewUserRegistrationForm.web'
import SelectTypeOfApplicant from '../../blocks/email-account-registration/src/SelectTypeOfApplicant.web'
import AccountRegistration from '../../blocks/email-account-registration/src/AccountRegistration.web'
import AboutUS from '../../blocks/contentmanagement/src/AboutUs.web';
import UserProfileBasic from "../../blocks/user-profile-basic/src/UserProfileBasic.web";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Filecompression from "../../blocks/filecompression/src/Filecompression";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import RandomNumberGenerator from "../../blocks/randomnumbergenerator/src/RandomNumberGenerator";
import HelpCentre from "../../blocks/helpcentre/src/HelpCentre";
import HelpCentreQA from "../../blocks/helpcentre/src/HelpCentreQA.web";
import { FormTypeSelection } from "../../blocks/customform/src/CustomForm.web";
import CustomForm from "../../blocks/customform/src/CustomFormPage.web";
import HelpCentreSub from "../../blocks/helpcentre/src/HelpCentreSub";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import LanguageSupport from "../../blocks/languagesupport/src/LanguageSupport";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import AutomaticFormCreation from "../../blocks/automaticformcreation/src/AutomaticFormCreation";
import Notifications from "../../blocks/notifications/src/Notifications";
import Servicespecificsettingsadmin2 from "../../blocks/servicespecificsettingsadmin2/src/Servicespecificsettingsadmin2";
import FormApprovalWorkflow from "../../blocks/formapprovalworkflow/src/FormApprovalWorkflow";
import Paymentadmin2 from "../../blocks/paymentadmin2/src/Paymentadmin2";
import Stripegatewayapifrontend2 from "../../blocks/stripegatewayapifrontend2/src/Stripegatewayapifrontend2";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Convertfile from "../../blocks/convertfile/src/Convertfile";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Print from "../../blocks/print/src/Print";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import TextComparison from "../../blocks/textcomparison/src/TextComparison";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Translation from "../../blocks/translation/src/Translation";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Analytics from "../../blocks/analytics/src/Analytics";
import Baselinereporting2 from "../../blocks/baselinereporting2/src/Baselinereporting2";
import Multilevelapproval2 from "../../blocks/multilevelapproval2/src/Multilevelapproval2";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import LanguageOptions from "../../blocks/languageoptions/src/LanguageOptions";
import ReviewApprovalAdmin from "../../blocks/reviewandapproval/src/ReviewApprovalAdmin";
import ReviewApprovalBasicUser from "../../blocks/reviewandapproval/src/ReviewApprovalBasicUser";
import { TermsAndConditions } from "../../blocks/termsconditions/src/TermsConditions.web";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import Captcha from "../../blocks/captcha/src/Captcha";
import { ContactUsWithStyles } from '../../blocks/contentmanagement/src/ContactUs.web'
import PrivacyPolicy from "../../blocks/termsconditions/src/PrivacyPolicy.web"
import {AdvancedSearchSection } from "../../blocks/advancedsearch/src/AdvancedSearch.web"

const routeMap = {
  TermsConditions: {
    component: TermsAndConditions,
    path: "/TermsConditions"
  },
  TermsConditionsDetail: {
    component: TermsConditionsDetail,
    path: "/TermsConditionsDetail"
  },
  TermsConditionsUsers: {
    component: TermsConditionsUsers,
    path: "/TermsConditionsUsers"
  },
  NavigationMenu: {
    component: NavigationMenu,
    path: "/NavigationMenu"
  },
  Captcha: {
    component: Captcha,
    path: "/Captcha"
  },

  Filecompression: {
    component: Filecompression,
    path: "/Filecompression"
  },
  Filteritems: {
    component: Filteritems,
    path: "/Filteritems"
  },
  Filteroptions: {
    component: Filteroptions,
    path: "/Filteroptions"
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: "/EducationalUserProfile"
  },
  RandomNumberGenerator: {
    component: RandomNumberGenerator,
    path: "/RandomNumberGenerator"
  },
  HelpCentre: {
    component: HelpCentre,
    path: "/HelpCentre"
  },
  HelpCentreQA: {
    component: HelpCentreQA,
    path: "/HelpCentreQA"
  },
  HelpCentreSub: {
    component: HelpCentreSub,
    path: "/HelpCentreSub"
  },
  Emailnotifications2: {
    component: Emailnotifications2,
    path: "/Emailnotifications2"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },
  LanguageSupport: {
    component: LanguageSupport,
    path: "/LanguageSupport"
  },
  VisualAnalytics: {
    component: VisualAnalytics,
    path: "/VisualAnalytics"
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: "/Adminconsole2"
  },
  AutomaticFormCreation: {
    component: AutomaticFormCreation,
    path: "/AutomaticFormCreation"
  },
  Notifications: {
    component: Notifications,
    path: "/Notifications"
  },
  Servicespecificsettingsadmin2: {
    component: Servicespecificsettingsadmin2,
    path: "/Servicespecificsettingsadmin2"
  },
  FormApprovalWorkflow: {
    component: FormApprovalWorkflow,
    path: "/FormApprovalWorkflow"
  },
  Paymentadmin2: {
    component: Paymentadmin2,
    path: "/Paymentadmin2"
  },
  Stripegatewayapifrontend2: {
    component: Stripegatewayapifrontend2,
    path: "/Stripegatewayapifrontend2"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  Catalogue: {
    component: Catalogue,
    path: "/Catalogue"
  },
  Rolesandpermissions2: {
    component: Rolesandpermissions2,
    path: "/Rolesandpermissions2"
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: "/UserProfileBasicBlock"
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: "/Pushnotifications"
  },
  Scheduling: {
    component: Scheduling,
    path: "/Scheduling"
  },
  Convertfile: {
    component: Convertfile,
    path: "/Convertfile"
  },
  Settings2: {
    component: Settings2,
    path: "/Settings2"
  },
  Print: {
    component: Print,
    path: "/Print"
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: "/SocialMediaAccountLoginScreen"
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: "/SocialMediaAccountRegistrationScreen"
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: "/EmailAccountLoginBlock"
  },
  TextComparison: {
    component: TextComparison,
    path: "/TextComparison"
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },
  Translation: {
    component: Translation,
    path: "/Translation"
  },
  StripePayments: {
    component: StripePayments,
    path: "/StripePayments"
  },
  ImportExportData: {
    component: ImportExportData,
    path: "/ImportExportData"
  },
  BulkUploading: {
    component: BulkUploading,
    path: "/BulkUploading"
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: "/MobileAccountLoginBlock"
  },
  LandingPage: {
    component: LandingPage,
    path: "/LandingPage"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration"
  },
  Analytics: {
    component: Analytics,
    path: "/Analytics"
  },
  Baselinereporting2: {
    component: Baselinereporting2,
    path: "/Baselinereporting2"
  },
  Multilevelapproval2: {
    component: Multilevelapproval2,
    path: "/Multilevelapproval2"
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: "/Categoriessubcategories"
  },
  LanguageOptions: {
    component: LanguageOptions,
    path: "/LanguageOptions"
  },
  ReviewApprovalAdmin: {
    component: ReviewApprovalAdmin,
    path: "/ReviewApprovalAdmin"
  },
  ReviewApprovalBasicUser: {
    component: ReviewApprovalBasicUser,
    path: "/ReviewApprovalBasicUser"
  },

  Home: {
    component: LandingPageweb,
    path: '/',
    exact: true
  },
  Dashboard: {
    component: DashboardHome,
    path: '/Dashboard',
    exact: true
  },
  FormHistory: {
    component: FormHistory1,
    path: '/FormHistory',
    exact: true
  },
  LandingPageweb: {
    component: LandingPageweb,
    path: '/LandingPageweb',
    exact: true
  },
  LinkExpire: {
    component: LinkExpire,
    path: '/LinkExpire',
    exact: true
  },
  OrganisationDetails: {
    component: OrganisationDetails,
    path: '/OrganisationDetails',
    exact: true
  },
  DigitalSignature: {
    component: DigitalSignature,
    path: '/DigitalSignature',
    exact: true
  },
  NewUserRegistrationForm: {
    component: NewUserRegistrationForm,
    path: '/NewUserRegistrationForm',
    exact: true
  },
  UserDetails: {
    component: AccountRegistration,
    path: '/UserDetails',
    exact: true
  },
  AboutUS: {
    component: AboutUS,
    path: '/AboutUS',
    exact: true
  },
  forgotPwd: {
    component: ForgotPasswordWeb,
    path: '/forgotPwd',
    exact: true
  },
  ResetPwd: {
    component: ResetPassWord,
    path: '/ResetPwd',
    exact: true
  },
  ResetLink: {
    component: ResetLink,
    path: '/ResetLink',
    exact: true
  },
  SetPwd: {
    component: SetPassword,
    path: '/reset_password',
    exact: true
  },
  SelectTypeOfApplicant: {
    component: SelectTypeOfApplicant,
    path: '/SelectTypeOfApplicant',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },
  FormTypeSelection: {
    component: FormTypeSelection,
    path: "/FormTypeSelection"
  },
  CustomForm: {
    component: CustomForm,
    path: "/CustomForm/:id"
  },
  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },
  ChangePassword: {
    component: Settings2,
    path: "/ChangePassword",
    exact: true
  },
  UserProfileBasic: {
    component: UserProfileBasic,
    path: "/UserProfileBasic",
  },
  RenewDigitalSignature: {
    component: Settings2,
    path: "/RenewDigitalSignature"
  },
  ContactUs: {
    component: ContactUsWithStyles,
    path: "/ContactUs"
  },
  RequestDigitalSignature: {
    component: Settings2,
    path: "/RequestDigitalSignature"
  },
  PrivacyPolicy: {
    component: PrivacyPolicy,
    path: "/PrivacyPolicy"
  },
  AdvancedSearch: {
    component: AdvancedSearchSection,
    path: "/AdvancedSearch"
  },
  
};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {

  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        {WebRoutesGenerator({ routeMap })}
      </View>
    );
  }
}

export default App;
