import React from "react";
// Customizable Area Start
import {
  IconButton
} from "@material-ui/core";
import { refresh, captchaback } from "../../landingpage/src/assets";
import { generateString } from "../../landingpage/src/LandingPageController";
import { createStyles } from "@material-ui/styles"
// Customizable Area End
import CaptchaController, { Props, configJSON } from "./CaptchaController.web";

export default class Captcha extends CaptchaController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.props.setCaptcha(this.state.captcha)
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div className={this.props.classes.container}>
        <div className={this.props.classes.imageContainer}>
          <img width={"100%"} height={49} src={captchaback} alt="Snow" />
          <div className={this.props.classes.centered}>{this.state.captcha}</div>
        </div>
        <div className={this.props.classes.iconContainer}>
          <IconButton
            aria-label="like"
            color="secondary"
            id="generateString"
            onClick={() => {
              const result = generateString();
              this.setState({ captcha: result });
              this.props.setCaptcha(result);
            }}
          >
            <img width={40} height={40} src={refresh} alt="Snow" />
          </IconButton>
        </div>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = createStyles( {
container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '15px',
  },
  imageContainer: {
    position: 'relative',
    textAlign: 'center',
    color: 'rgb(114, 23, 23)',
    marginTop: '20px',
  },
  centered: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '25px',
    fontWeight: 800,
  }, 
  imageSize:{
    width:"100%",
    height:"49px"
  },
  iconContainer: {
    marginLeft: '10px', /* Push the IconButton to the right */
    marginTop: '20px',
  },
});
// Customizable Area End
