import React from "react";

import {
    // Customizable Area Start
    withStyles, Typography, Theme, Grid, IconButton, Container,
    InputAdornment,
    createStyles
    // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import { generateString } from "../../landingpage/src/LandingPageController";
import { captchaback, refresh } from "../../landingpage/src/assets";
import ErrorMessage from "../../../components/src/ErrorMessage.web";
import { StyledTextFieldPassword, StyledPasswordButton } from "../../../components/src/CustomizedComponents.web";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import Settings2Controller, { Props, configJSON } from "./Settings2Controller";
import VerificationDailog from "../../../components/src/VerificationDailog.web";
// Customizable Area End


export class ChangePassword extends Settings2Controller {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        const { classes } = this.props
        const { currentPassword, newPassword, reEnterNewPassword, captch } = this.state.changePasswordForm
        const changeDisabled = !(currentPassword && newPassword && reEnterNewPassword && captch)
        const resetDisabled = !(currentPassword || newPassword || reEnterNewPassword || captch)
        return (
            // Customizable Area Start
            <>
                <Container style={{ paddingLeft: "5rem" }}>
                    <Grid item xs={12}>
                        <Grid container justifyContent="flex-start" alignItems="center" spacing={3}>
                            <Grid item xs={12} md={3}>
                                <Typography component={'div'} className={classes.inputLabel}>Current Password:</Typography>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <Grid container justifyContent="flex-start" alignItems="center" spacing={1} className={classes.layout}>
                                    <Grid item xs={12}>
                                        <StyledTextFieldPassword
                                            fullWidth
                                            data-test-id="currentPasswordField"
                                            variant="outlined"
                                            value={this.state.changePasswordForm.currentPassword}
                                            name="currentPassword"
                                            onChange={this.handleChangePassword}
                                            error={Boolean(this.state.isErrorFormData.currentPassword)}
                                            type={this.state.oldPassword ? 'text' : 'password'}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            data-test-id="showOldPassword"
                                                            aria-label="toggle password visibility"
                                                            onClick={this.handleShowOldPassword}
                                                            edge="end"
                                                        >
                                                            {this.state.oldPassword ? (
                                                                <Visibility />
                                                            ) : (
                                                                <VisibilityOff />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}

                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ErrorMessage
                                            errorName={this.state.isErrorFormData.currentPassword || this.state.currentPasswordError}
                                        />

                                    </Grid>
                                </Grid >

                            </Grid>
                            <Grid item xs={12} md={1}>
                                <Typography component={'div'} className={classes.inputLabel}>or</Typography>

                            </Grid>
                            <Grid item xs={12} lg={3}>
                                <StyledPasswordButton
                                    variant="contained"
                                    className={classes.containedButton}
                                >
                                    {configJSON.chnagePasswordButtons.DigitalBtnText}

                                </StyledPasswordButton>

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justifyContent="flex-start" alignItems="center" spacing={3}>
                            <Grid item xs={12} md={3}>
                                <Typography component={'div'} className={classes.inputLabel}>New Password:</Typography>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <Grid container justifyContent="flex-start" alignItems="center" spacing={1} className={classes.layout}>
                                    <Grid item xs={12}>
                                        <StyledTextFieldPassword
                                            data-test-id="newPasswordField"
                                            fullWidth
                                            variant="outlined"
                                            value={this.state.changePasswordForm.newPassword}
                                            name="newPassword"
                                            onChange={this.handleChangePassword}
                                            error={Boolean(this.state.isErrorFormData.newPassword)}
                                            type={this.state.newPassword ? 'text' : 'password'}

                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            data-test-id="showNewPassword"
                                                            aria-label="toggle password visibility"
                                                            onClick={this.handleShowNewPassword}
                                                            edge="end"
                                                        >
                                                            {this.state.newPassword ? (
                                                                <Visibility />
                                                            ) : (
                                                                <VisibilityOff />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ErrorMessage
                                            errorName={this.state.isErrorFormData.newPassword}
                                        />


                                    </Grid>
                                </Grid >
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justifyContent="flex-start" alignItems="center" spacing={3} >
                            <Grid item xs={12} md={3}>
                                <Typography component={'div'} className={classes.inputLabel}>Re-type Password:</Typography>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <Grid container justifyContent="flex-start" alignItems="center" spacing={1} className={classes.layout}>
                                    <Grid item xs={12}>
                                        <StyledTextFieldPassword
                                            data-test-id="reEnterNewPasswordField"
                                            fullWidth
                                            variant="outlined"
                                            value={this.state.changePasswordForm.reEnterNewPassword}
                                            name="reEnterNewPassword"
                                            onChange={this.handleChangePassword}
                                            error={Boolean(this.state.isErrorFormData.reEnterNewPassword)}
                                            type={this.state.confirmNewPassword ? 'text' : 'password'}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            data-test-id="showConfirmNewPassword"
                                                            aria-label="toggle password visibility"
                                                            onClick={this.handleShowConfirmPassword}
                                                            edge="end"
                                                        >
                                                            {this.state.confirmNewPassword ? (
                                                                <Visibility />
                                                            ) : (
                                                                <VisibilityOff />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ErrorMessage
                                            errorName={this.state.isErrorFormData.reEnterNewPassword}
                                        />

                                    </Grid>
                                </Grid >



                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justifyContent="flex-start" alignItems="center" spacing={3} >
                            <Grid item xs={12} md={3}>
                                <Typography component={'div'} className={classes.inputLabel}>Captcha:</Typography>
                            </Grid>
                            <Grid item xs={12} md={5}>


                                <Grid container justifyContent="flex-start" alignItems="center" spacing={1} className={classes.layout}>
                                    <Grid item xs={12}>
                                        <StyledTextFieldPassword
                                            fullWidth
                                            data-test-id="captchField"
                                            variant="outlined"
                                            value={this.state.changePasswordForm.captch}
                                            name="captch"
                                            onChange={this.handleChangePassword}
                                            className={classes.captchField}
                                            error={Boolean(this.state.isErrorFormData.captch)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ErrorMessage
                                            errorName={this.state.isErrorFormData.captch}
                                        />


                                    </Grid>
                                </Grid >



                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justifyContent="flex-start" alignItems="center" spacing={3}>
                            <Grid item xs={12} md={3} />
                            <Grid item xs={12} md={5}>
                                <Grid container justifyContent="flex-start" alignItems="center" spacing={1}>

                                    <Grid item className={classes.imageContainer}>
                                        <img width={233} height={45} src={captchaback} alt="Snow" />
                                        <div className={classes.centered}>{this.state.passwordCaptch}</div>
                                    </Grid>
                                    <Grid item>
                                        <div className={classes.iconContainer}>
                                            <IconButton
                                                data-test-id="relodCaptchBtn"
                                                aria-label="like"
                                                color="secondary"
                                                id="generateString"
                                                onClick={() => {
                                                    const result = generateString();
                                                    this.setState({ passwordCaptch: result })
                                                }}
                                            >
                                                <img width={30} height={30} src={refresh} alt="Snow" />
                                            </IconButton>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.bottomButtonsLayout}>
                        <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
                            <Grid item >
                                <StyledPasswordButton
                                    variant="outlined"
                                    className={classes.outlinedButton}
                                    data-test-id="backButton"
                                    onClick={this.handleBackButton}
                                >
                                    {configJSON.chnagePasswordButtons.backBtnText}

                                </StyledPasswordButton>
                            </Grid>
                            <Grid item >
                                <StyledPasswordButton
                                    disabled={resetDisabled}
                                    variant="contained"
                                    className={classes.containedButton}
                                    data-test-id="resetBtn"
                                    onClick={this.handleReset}
                                >
                                    {configJSON.chnagePasswordButtons.resetBtnText}

                                </StyledPasswordButton>
                            </Grid>
                            <Grid item >
                                <StyledPasswordButton
                                    disabled={changeDisabled}
                                    variant="contained"
                                    className={classes.containedButton}
                                    data-test-id="changePasswordBtn"
                                    onClick={this.changePasswordBtn}
                                >
                                    {configJSON.chnagePasswordButtons.changeBtnText}
                                </StyledPasswordButton>
                            </Grid>



                        </Grid>
                    </Grid>
                </Container>
                {this.state.dailogOpen &&
                    <VerificationDailog
                        open={this.state.dailogOpen}
                        message={this.state.messageText}
                        verificationStatus={this.state.changePasswordStatus}
                        closeDailog={this.closeDailog}
                        sourceType={"changePassword"}
                    />

                }

            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

export const styles = (theme: Theme) =>
    createStyles({
        layout: {
            [theme.breakpoints.down('md')]: {
                justifyContent: "center"
            }
        },
        inputLabel: {
            fontSize: "20px",
            fontWeight: 400,
            color: "#000000",
            lineHeight: "30px",
            fontFamily: "Poppins, sans-serif"
        },
        Layout: {
            display: "flex",
            alignItems: "center",
        },
        outlinedButton: {
            borderColor: "#8A4504",
            color: "#8A4504",
            backgroundColor: "#ffffff",
            fontFamily: "Poppins, sans-serif",
            '&:hover': {
                backgroundColor: "#ffffff",
                color: "#8A4504",
            }
        },
        containedButton: {
            color: "#ffffff",
            backgroundColor: "#8A4504",
            fontFamily: "Poppins, sans-serif",
            '&:hover': {
                backgroundColor: "#8A4504",
                color: "#ffffff",
            },
            "&:disabled": {
                color: "#ffffff",
                backgroundColor: "#8A4504",
                opacity: "50%",

            }
        },
        imageContainer: {
            position: "relative",
            color: "rgb(114, 23, 23)",
            textAlign: 'center',

        },
        iconContainer: {
            // marginLeft: "10px",
            // marginTop: "20px",
        },
        centered: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            fontFamily: "Poppins, sans-serif",
            fontSize: "30px",
            fontWeight: 800,
            color: "#C74217",
            fontStyle: "italic"
        },
        captchField: {
            width: "263px",
            maxWidth: "100%",
            [theme.breakpoints.down('sm')]: {
                width: "100%"
            }
        },
        bottomButtonsLayout: {
            marginTop: "3rem",
            marginBottom: "2.5rem",
            [theme.breakpoints.down('sm')]: {
                marginTop: "1.5rem",
                marginBottom: "1rem"
            }
        }
    });

export default withStyles(styles, { withTheme: true })(ChangePassword);

// Customizable Area End
