import React from "react";

// Customizable Area Start
import { withStyles, createStyles } from "@material-ui/styles";
import {  imageSuccess, imageUpload } from "../../landingpage/src/assets";
import { IconButton } from "@material-ui/core";
import { ipoImage ,logoImage} from "../../customform/src/assets";
import ImageUploading from "react-images-uploading";
// Customizable Area End

import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";

export class DigitalSignature extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <div className={this.props.classes.containerDG}>
        <div className={this.props.classes.headerDG}>
          <img src={logoImage} alt="Logo" className={this.props.classes.logoDG} />
          <img src={ipoImage} alt="Ipo-Logo" className={this.props.classes.logoDG} />
        </div>
        <div className={this.props.classes.cardDSDG}>
          <div className={this.props.classes.topLineDG}>
            <button className={this.props.classes.buttonDG}> New User Registration Form </button>
          </div>
          {this.state.pageLabel && (
            <div className={this.props.classes.contentDG}>
              <label className={this.props.classes.labelDG}>Digital signature</label>
              <label className={this.props.classes.subLabelDG}>( Association/Organisation )</label>
            </div>
          )}
          {this.state.addDigitalSignature && (
            <div className={this.props.classes.formContainerDS}>
              <button
                className={this.props.classes.actionButtonDS}
                id="addDigitalSignature"
                onClick={() => {
                  this.addDigitalSignature();
                }}
              >
                Add Digital Signature
              </button>
              <span className={this.props.classes.infoTextDs}>
                (Please enter password after selecting Digital Signature)
              </span>
            </div>
          )}
          {this.state.uploadIcon && (
            <div className={this.props.classes.innerCardDS}>
              {!this.state.showImage && (
                <div className={this.props.classes.justifyContentDS}>
                  <div className={this.props.classes.uploadIconDS}>
                    <IconButton aria-label="upload icon">
                      <img
                        src={imageUpload}
                        alt="upload"
                        style={{ width: 80, height: 60 }}
                      />
                    </IconButton>
                  </div>
                  <span className={this.props.classes.innerCardTextDS}>
                    Drag and drop file here{" "}
                  </span>
                  <span className={this.props.classes.innerCardTextDS}> or </span>
                </div>
              )}
              {this.state.showImage && (
                <div className={this.props.classes.cardStyle} >
                  <div className={this.props.classes.cardImage}>
                    <img
                      src={imageUpload}
                      alt="upload"
                      className={this.props.classes.cardImageStyle}
                    />
                  </div>
                  <span className={this.props.classes.innerCardFileDS}>
                    {this.state.imageName}{" "}
                  </span>
                  <span className={this.props.classes.innerCardFileDS}> File Uploaded  <span style={{ color: "green" }}>✓</span> </span>
                </div>
              )}
              <ImageUploading
                multiple
                onChange={this.onChange}
                maxNumber={1}
                value={[]} // Add an empty array for the value prop
              >
                {({ onImageUpload }: { onImageUpload: () => void }) => (
                  <div>
                    <button
                      className={this.props.classes.actionButtonDSG}
                      onClick={onImageUpload}
                    >
                      Browse for file
                    </button>
                  </div>
                )}
              </ImageUploading>
            </div>
          )}
          {this.state.verifiedDS && (
            <div className={this.props.classes.verifiedDS}>
              <div className={this.props.classes.verifyIconDS}>
                <img
                  src={imageSuccess}
                  height={250}
                  width={260}
                  alt="signature"
                />
              </div>
              <span className={this.props.classes.verifiedSignatureTextDS}>
                Digital signature verified{" "}
              </span>
            </div>
          )}
          <div className={this.props.classes.buttonsContainerDSG}>
            <button
              className={this.props.classes.actionButtonBackDS}
              id="DSBack"
              onClick={() => {
                this.handleBackNavigate();
              }}
            >
              Back
            </button>
            <button
              className={this.props.classes.actionButtonDS}
              id="nextStep"
              onClick={() => {
                this.handleNavigate("UserDetails", "signature");
              }}
            >
              Next
            </button>
          </div>
        </div>
        <div className={this.props.classes.buttonsContainerDSG}>
          <button id="Home" className={this.props.classes.actionButtonDS} onClick={()=>this.handleNavigate("Dashboard")}>Home</button>
          <button id="AboutUs" className={this.props.classes.actionButtonDS} onClick={()=>this.handleNavigate("AboutUS")}>About Us</button>
          <button id="ContactUs" className={this.props.classes.actionButtonDS} onClick={()=>this.handleNavigate("ContactUs")}>Contact Us</button>
        </div>
        <footer className={this.props.classes.footerDS}>
          <p className={this.props.classes.footerTextDS}>
            Guidelines for using NTRP (Bharat Kosh) Payment Gateway <span className={this.props.classes.guidecolorDS}>Guide</span>
          </p>
        </footer>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const style=createStyles({
  containerDG: {
    backgroundColor: "#ffd4ac",
    height: "auto",
    overflowX: "hidden"
  },
  headerDG: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#ffd4ac",
    maxWidth: "100%",
    height: "100px",
    marginBottom: "50px"
  },
  logoDG: {
    objectFit: "contain",
    height: "92px",
    padding: "20px",
    '@media (max-width: 900px)': {
      height: "80px",
      padding: "10px",
    },
    '@media (max-width: 600px)': {
      height: "60px",
      padding: "5px",
    },
    '@media (max-width: 300px)': {
      height: "40px",
      padding: "4px",
    }
  },
  cardDSDG: {
    width: "75%",
    height: "auto",
    minHeight: "500px",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#ffffff",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    margin: "0 auto",
    padding: "20px",
    marginBottom: "40px",
    paddingTop: "20px",
  },
  topLineDG: {
    width: "100%",
    height: "1px",
    backgroundColor: "#ccc",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: "0",
  },
  buttonDG: {
    padding: "5px 10px",
    fontSize: "30px",
    fontWeight: 400,
    border: "none",
    width: "600px",
    height: "70px",
    backgroundColor: "#894402",
    color: "#ffffff",
    borderRadius: "5px",
    fontFamily: "Poppins-Medium, sans-serif",
    '@media (max-width: 1200px)': {
        width:"60%",
        fontSize: "25px",
        height: "auto",
      }
  },
  contentDG: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "50px"
},
subLabelDG: {
    fontWeight: 400,
    fontSize: "24px",
    fontFamily: "Poppins, sans-serif",
    color: "#000000",
  },
  labelDG: {
    fontWeight: 470,
    fontSize: "33px",
    fontFamily: "Poppins, sans-serif",
    color: "#000000",
    marginBottom: "15px"
},
formContainerDS: {
  flexDirection: 'column',
  marginTop: '8%',
  marginBottom: '8%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
},
actionButtonDS: {
  padding: "10px 20px",
  fontSize: "18px",
  fontWeight: "bold",
  borderRadius: "5px",
  margin: "0 10px" /* Add some margin between the buttons */,
  cursor: "pointer",
  minWidth: "300px" /* Minimum width for the button */,
  height: "54px" /* Height for the button */,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textTransform: "none",
  fontFamily: 'Poppins, sans-serif',
  backgroundColor: "#894402", // Background color
  color: "#ffffff", // Text color
  border: "none", // Remove border
  '@media (max-width: 1200px)': {
      width: "25%"
  }
},
infoTextDs: {
  marginTop: '8px',
  fontFamily: "Poppins, sans-serif",
  fontSize: '20px',
  fontWeight: 400,
},
innerCardDS: {
  width: '60%',
  margin: '8% 0',
  border: '2px solid #ADADAD',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '8px',
},
justifyContentDS: {
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
},
uploadIconDS: {
  marginTop: '40px',
  marginBottom: '31px',
},
verifyIconDS: {
  marginTop: '40px',
  marginBottom: '20px',
},
innerCardTextDS: {
  fontFamily: "Poppins, sans-serif",
  fontSize: '30px',
  fontWeight: 400,
  color: '#000000',
  marginBottom: '5px',
},
innerCardFileDS: {
  fontFamily: "Poppins, sans-serif",
  fontSize: '22px',
  fontWeight: 400,
  color: '#979797',
  marginBottom: '10px',
},
cardImage:{
  marginBottom: 25, marginTop: 15
},
cardStyle:{
   marginTop: 15,
   display: "flex",
   justifyContent: "center",
   alignItems: "center",
  flexDirection: "column" 
},
cardImageStyle:{
  width: 80, height: 60
},
verifiedDS: {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  marginBottom: '30px',
},
verifiedSignatureTextDS: {
  fontFamily: "Poppins, sans-serif",
  fontSize: '35px',
  fontWeight: 600,
  color: '#000000',
  marginBottom: '10px',
},
buttonsContainerDSG: {
  display: "flex",
  marginTop: "auto",
  marginBottom: "40px",
  justifyContent: "center",
  gap:"5px",
  '@media (max-width: 1200px)': {
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "10px",
  }
},
actionButtonBackDS: {
  padding: "10px 20px",
  fontSize: "18px",
  fontWeight: "bold",
  borderRadius: "5px",
  margin: "0 10px" /* Add some margin between the buttons */,
  cursor: "pointer",
  minWidth: "300px" /* Minimum width for the button */,
  height: "54px" /* Height for the button */,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textTransform: "none",
  fontFamily: 'Poppins, sans-serif',
  backgroundColor: "#ffffff", // Background color
  color: "#894402", // Text color
  border: "1px solid #894402", // Remove border
  marginRight: "10px",
  '@media (max-width: 1200px)': {
    marginBottom:"20px",
    width: "25%"
  }
},
guidecolorDS: {
  color: "#FF0000"
},
footerTextDS:{
  fontSize: "20px",
  color: "#000000",
  fontWeight: 400,
  lineHeight: "45px",
  fontFamily: "Poppins-Medium, sans-serif"
},
footerDS: {
  backgroundColor: "#ffffff",
  padding: "10px",
  height: "70px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
},
actionButtonDSG: {
  padding: '10px 20px',
  fontSize: '18px',
  fontWeight: 'bold',
  borderRadius: '5px',
  cursor: 'pointer',
  minWidth: '300px',
  height: '54px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textTransform: 'none',
  fontFamily: "Poppins, sans-serif",
  backgroundColor: '#894402',
  border: "1px solid #894402",
  color: '#ffffff',
  marginBottom: '40px',
  marginTop: '20px',
  '@media (max-width: 1200px)': {
    minWidth: '150px',
    fontSize: '12px',
  }
},

})
export default withStyles(style)(DigitalSignature)
// Customizable Area End