import React, { Component } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ArrowDropDown';
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) => createStyles({
  root: {
    margin: "60px 0px",
    padding: 0,
  },
  accordion: {
    backgroundColor:"#fff1e6",
    borderRadius:"20px !important",
    padding:"24px 10px 24px 10px"
  },
  largeIcon: {
    fontSize: '54px',
    color:"#984414",
  },
  accordionHeading: {
    fontSize: '28px',
    fontWeight: 500,
    color: 'black',
    fontFamily: "Poppins, sans-serif",
  },
  accordionSubHeading: {
    fontSize: '20px',
    color: 'black',
    fontFamily: "Poppins, sans-serif",
  },
});

interface MyAccordionProps extends WithStyles<typeof styles> {
  questionNumber: string;
  question: string;
  answer:string
}

class MyAccordion extends Component<MyAccordionProps> {
  render() {
    
    const { classes, questionNumber, question,answer } = this.props;

    return (
      <div className={classes.root}>
        <Accordion className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={classes.largeIcon} />}
            aria-controls="accordion-content-2"
            id="accordion-header-2"
          >
            <Typography className={classes.accordionHeading} variant="h6">
              {`${questionNumber}. ${question}`}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.accordionSubHeading}>
             {answer}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }
}

export default withStyles(styles)(MyAccordion);
