import React from "react";

import {
  Box,
  Typography,
  // Customizable Area Start
  createStyles, Theme, Grid, Tab, withStyles,
  // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import { ipoImage, logoImage } from "../../customform/src/assets";
import { StyledTabs } from "../../../components/src/CustomizedComponents.web";
import Navbar from "../../../components/src/Navbar";
import "../../dashboard/src/Dashboard.css"
import Settings2Controller, { Props } from "./Settings2Controller";
import ChangePassword from "./ChangePassword.web";
import RenewDigitalSignature from "./RenewDigitalSignature.web";



function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={'div'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
// Customizable Area End



export class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { classes } = this.props
    return (

      <>
        <Box className="pageContainer-DB">
          <Box className={classes.logoContainer}>
            <img src={logoImage} alt="Logo" className={classes.logo} />
            <img src={ipoImage} alt="Ipo-Logo" className={classes.logo} />
          </Box>
          <Box className="outerContainer-DB">
            <Box className="innerContainer-DB">
              <Navbar selectedTab={0} navigation={this.props.navigation} />
              <Grid item xs={12}>
                <Grid item xs={12} container direction="row" justifyContent="center" alignItems="center" className={classes.titlePadding}>
                  <Typography component={'div'} className={classes.sectionHeading}>Control Panel</Typography>
                </Grid>
              </Grid>

              <Grid item xs={12} style={{ flexBasis: "0% !important" }}>
                <StyledTabs

                  aria-label="simple tabs example"
                  value={this.state.settingsTabValue}
                  onChange={this.settingsChange}
                  data-test-id="controlPanelTab"
                  variant="fullwidth"
                >
                  <Tab label="Change Password"
                    className={
                      this.state.settingsTabValue === 0
                        ? classes.selectedTab
                        : classes.nonSelectTab
                    }

                    {...a11yProps(0)}
                  />
                  <Tab label="Renew Digital Signature"
                    className={
                      this.state.settingsTabValue === 1
                        ? classes.selectedTab
                        : classes.nonSelectTab
                    }

                    {...a11yProps(1)}
                  />
                  <Tab label="Request Digital Signature"
                    className={
                      this.state.settingsTabValue === 2
                        ? classes.selectedTab
                        : classes.nonSelectTab
                    }

                    {...a11yProps(2)}
                  />
                </StyledTabs>
              </Grid>
              <TabPanel to="/ChangePassword" value={this.state.settingsTabValue} index={0}>
                <ChangePassword {...this.props} />
              </TabPanel>
              <TabPanel to="/RenewDigitalSignature" value={this.state.settingsTabValue} index={1}>
                <RenewDigitalSignature {...this.props} />
              </TabPanel>
              <TabPanel to="/RequestDigitalSignature" value={this.state.settingsTabValue} index={2}>
                <RenewDigitalSignature {...this.props} />

              </TabPanel>
            </Box>
          </Box>

        <footer className={classes.footer}>
          <Typography className={classes.footerText}>
            Guidelines for using NTRP (Bharat Kosh) Payment Gateway{" "}
            <a href="#" className={classes.guideLink}>
              Guide
            </a>
          </Typography>
        </footer>
        </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const styles = (theme: Theme) =>
  createStyles({
    appContainer: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#ffd4ac",
      height: "auto !important",
      overFlowX: "hidden"
      /* min-height: 100vh;  */
    },
    header: {
      backgroundColor: "#ffd4ac",
      padding: "10px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: "120px",
      marginBottom: " 30px !important",
      marginTop: "18px !important  ",
      maxWidth: "100%",
      margin: "0 auto"
    },
    logoContainer: {
      display: "flex",
      backgroundColor: "#ffd4ac",
      justifyContent: "space-between",
      padding: "20px",
      maxWidth: "100%",
      height: "120px",
    },
    logo: {
      height: "100px",
      objectFit: "contain",
      padding: "4px",
    },
    cardContainer: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#ffffff",
      borderRadius: "10px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      width: "75%",
      margin: "0 auto",
      marginBottom: "40px",
      height: "100%",


    },
    guideLink: {
      color: "#ff0000",
      textDecoration: "none",
    },
    footer: {
      backgroundColor: "#ffffff",
      padding: "10px",
      textAlign: "center",
      height: "70px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    footerText: {
      margin: 0,
      fontSize: "20px",
      color: "#000000",
      fontWeight: 400,
      lineHeight: "45px",
      fontFamily: "Poppins-Medium, sans-serif",
    },
    sectionHeading: {
      fontSize: "24px",
      fontWeight: 500,
      color: "#8A4504",
      fontFamily: "Poppins, sans-serif"
    },
    titlePadding: {
      padding: "1.5rem"
    },
    selectedTab: {
      fontSize: "19.5px",
      fontWeight: 600,
      minWidth: "0px",
      fontFamily: "Poppins, sans-serif",
      padding: "0px",
      minHeight: "0px",
      textTransform: "none",
      lineHeight: "46.5px",
      color: "#000000"
    },
    nonSelectTab: {
      fontSize: "19.5px",
      fontWeight: 300,
      minWidth: "0px",
      fontFamily: "Poppins, sans-serif",
      padding: "0px",
      minHeight: "0px",
      textTransform: "none",
      lineHeight: "46.5px",
      color: "#000000",
      opacity: 1,
    }
  });
export default withStyles(styles, { withTheme: true })(Settings2);
// Customizable Area End