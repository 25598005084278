import React from "react";
// Customizable Area Start
import { Headerlogo } from "../../landingpage/src/assets";
import { ipoImage } from "../../customform/src/assets";
import LinkList from "../../../components/src/LinkList";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import { withStyles,createStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// Customizable Area End
import ForgotPasswordController, { Props } from "./ForgotPasswordController";

export class ForgotPasswordWeb extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    // Customizable Area Start
    const options = [
      { label: "User ID", value: "UserID" },
      { label: "Digital signature", value: "signature" }
    ];
    // Customizable Area End
    return (
      // Customizable Area Start
      <div className={this.props.classes.appContainerFG}>
        <div className={this.props.classes.headerFG}>
          <img src={Headerlogo} alt="Logo" className={this.props.classes.logoFG} />
          <img src={ipoImage} alt="Ipo-Logo" className={this.props.classes.logoFG} />
        </div>
        <div className={this.props.classes.cardFG}>
          <div className={this.props.classes.topLineFG}>
            <button className={this.props.classes.customButton}>Forgot password?</button>
          </div>
          <div className={this.props.classes.customContent}>
            <label className={this.props.classes.customLabel}>
              Fields Marked with an asterisk * are required.{" "}
            </label>
          </div>
          <div className={this.props.classes.formContainerFG}>
            <div className={this.props.classes.customFormRow} >
              <label className={this.props.classes.customSelect}>
                {" "}
                Reset password with <span className={this.props.classes.startColor}>*</span>:
              </label>
              <div className={this.props.classes.customSelectContainer}>
                <Autocomplete
                  id="userType"
                  options={options}
                  className={this.props.classes.formInputStyleAutoFG}
                  popupIcon={<ExpandMoreIcon />}
                  getOptionLabel={option => option.label} // Display the label property as the option label
                  onChange={(e, value) => {
                    this.setState(
                      {
                        passWordResetWith: value ? value.value : null
                      },
                      () => {
                        this.handleNavigation("ResetPwd");
                      }
                    );
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Select"
                      id="renderInput"
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true
                      }}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className={this.props.classes.buttonsContainerFG}>
            <button
              onClick={() => {
                this.handleNavigation("LandingPageweb");
              }}
              className={this.props.classes.actionButtonBack}
              id="ResetPwd"
            >
              Back
            </button>
          </div>
          <div className={this.props.classes.customSelectApplicantDiv}>
            <span className={this.props.classes.customSelectApplicantNote} >
              NB: Please register using Internet Explorer 6.0 or above.
            </span>
          </div>
        </div>
        <LinkList />
        <footer className={this.props.classes.customFooter} >
          <p className={this.props.classes.customFooterText}>
            Guidelines for using NTRP (Bharat Kosh) Payment Gateway{" "}
            <span className={this.props.classes.startColor} >Guide</span>
          </p>
        </footer>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyles = () =>
createStyles({
  appContainerFG: {
    backgroundColor: '#ffd4ac',
    height: 'auto', 
    overflowX: 'hidden' ,
  },
  headerFG: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#ffd4ac",
    padding: "10px",
    maxWidth: "100%",
    height: "100px",
    marginBottom: "50px"
  },
  logoFG: {
    objectFit: "contain",
    height: "92px",
    padding: "20px",
    '@media (max-width: 900px)': {
      height: "80px",
      padding: "10px",
    },
    '@media (max-width: 600px)': {
      height: "60px",
      padding: "5px",
    },
    '@media (max-width: 300px)': {
      height: "40px",
      padding: "4px",
    }
  },
  cardFG: {
    width: '75%',
    height: 'auto', // Note: Using is discouraged and should be avoided if possible.
    minHeight: '465px',
    display: 'flex',
    flexDirection: 'column' ,
    position: 'relative',
    backgroundColor: '#ffffff',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    margin: '0 auto',
    padding: '20px',
    marginBottom: '10px',
  },
  topLineFG: {
    height: '1px',
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    top: '0',
  },
  formContainerFG: {
    flexDirection: 'column' ,
    marginTop: '5%',
    marginBottom: '5%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  startColor: {
    color: 'red',
  },
  formInputStyleAutoFG: {
    height: '54px',
    fontSize: '16px',
    // border: '1.31px solid #979797',
    borderRadius: '5px',
    marginRight: '30px',
    fontFamily: "Poppins-Medium, sans-serif",
    width: '95%',
    minWidth: '450px',
    color: '#000000',
    '@media (max-width: 1200px)': {
      width: '45%',
      minWidth: '200px',
    },
  },
  buttonsContainerFG: {
    display: 'flex',
    marginBottom: '30px', // Note: Using is discouraged and should be avoided if possible.
    marginTop: '10px', // Note: Using is discouraged and should be avoided if possible.
    justifyContent: 'center',
  },
  customButton: {
    padding: '5px 10px',
    fontWeight: 400, // Note: Using is discouraged and should be avoided if possible.
    fontSize: '30px', // Note: Using is discouraged and should be avoided if possible.
    width: '530px', // Note: Using is discouraged and should be avoided if possible.
    border: 'none',
    height: '70px', // Note: Using is discouraged and should be avoided if possible.
    color: '#ffffff',
    backgroundColor: '#894402',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: "Poppins, sans-serif", // Note: Using is discouraged and should be avoided if possible.
    borderRadius: '5px',
    '@media (max-width: 1200px)': {
      fontSize: '21px',
      width: '340px',
    },
    '@media (max-width: 1100px)': {
      fontSize: '18px',
      width: '210px',
      padding: '0 0',
    },
  },
  customContent: {
    display: 'flex' , // Note: Using is discouraged and should be avoided if possible.
    flexDirection: 'column' ,
    justifyContent: 'center', // Note: Using is discouraged and should be avoided if possible.
    alignItems: 'center', // Note: Using is discouraged and should be avoided if possible.
    marginTop: '50px', // Note: Using is discouraged and should be avoided if possible.
  },
  customLabel: {
    justifyContent: 'center',
    fontSize: '27px',
    fontWeight: 400,
    display: 'flex',
    fontFamily: "Poppins, sans-serif", // Note: Using is discouraged and should be avoided if possible.
    alignItems: 'center',
    marginTop: '15px', 
    '@media (max-width: 1200px)': {
      fontSize: '18px',
    },
  },
  customFormRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
    '@media (max-width: 1200px)': {
      flexDirection: 'column' ,
    },
  },
  customSelect: {
    fontWeight: 500, // Note: Using is discouraged and should be avoided if possible.
    fontSize: '25px', // Note: Using is discouraged and should be avoided if possible.
    marginRight: '10px', // Note: Using is discouraged and should be avoided if possible.
    fontFamily: '"Poppins", sans-serif', // Note: Using is discouraged and should be avoided if possible.
    '@media (max-width: 1200px)': {
      fontSize: '14px',
      alignSelf: 'flex-start',
    },
  },
  customSelectContainer: {
    margin: '10px auto',
    display: 'flex', // Note: Using is discouraged and should be avoided if possible.
    flexDirection: 'column' 
  },
  actionButtonBack: {
    padding: "10px 20px",
    fontSize: "18px",
    fontWeight: "bold",
    borderRadius: "5px",
    margin: "0 10px" /* Add some margin between the buttons */,
    cursor: "pointer",
    minWidth: "300px" /* Minimum width for the button */,
    height: "54px" /* Height for the button */,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "none",
    fontFamily: '"Poppins", sans-serif',
    backgroundColor: "#ffffff", // Background color
    color: "#894402", // Text color
    border: "1px solid #894402", // Remove border
    marginRight: "10px"
  },
  customSelectApplicantNote: {
    fontFamily: '"Poppins", sans-serif',
    color: '#009B31',
    fontSize: '20px', // Note: Using is discouraged and should be avoided if possible.
    fontWeight: 400, // Note: Using is discouraged and should be avoided if possible.
  },
  customSelectApplicantDiv: {
    display: 'flex', // Note: Using is discouraged and should be avoided if possible.
    justifyContent: 'center',
    alignItems: 'center', // Note: Using is discouraged and should be avoided if possible.
  },
  customFooter: {
    backgroundColor: '#ffffff',
    padding: '10px',
    textAlign: 'center' ,
    height: '70px', // Note: Using is discouraged and should be avoided if possible.
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  customFooterText: {
    margin: '0',
    fontSize: '20px', // Note: Using is discouraged and should be avoided if possible.
    color: '#000000',
    fontWeight: 400,
    lineHeight: '45px', // Note: Using is discouraged and should be avoided if possible.
    fontFamily: 'Poppins-Medium, sans-serif', // Note: Using is discouraged and should be avoided if possible.
  },
})
export default withStyles(webStyles)(ForgotPasswordWeb)
// Customizable Area End
