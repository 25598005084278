Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.httpGetMethod = 'GET'
exports.advancedsearchApiContentType = 'application/json'
exports.getAdvancedSearchApiEndPoint = 'advanced_search/search'
exports.errorTitle = 'Error'
exports.avancedSearch='bx_block_advanced_search/filter'
exports.COLUMNS = [
  {
      name: "GI Number",
      width: "0px",
      paddingLeft: "8px",
  },
  {
      name: "Application Date",
      width: "0px",
  },
  {
      name: "GI Name",
      width: "220px",
  },
  {
      name: "Applicant Name",
      width: "0px",
  },
  {
      name: "Publication Date",
      width: "0px",
  },
  {
      name: "Abstract",
      width: "0px",
  },
]

// Customizable Area End
