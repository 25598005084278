// Customizable Area Start
import React from "react";
import ContactUsWebController, { Props,configJSON } from './ContactUsController.web'
import { Box, Typography } from "@material-ui/core";
import Navbar from "../../../components/src/Navbar";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { ipoImage, logoImage } from "../../customform/src/assets";
import { createStyles , withStyles} from "@material-ui/styles"
const theme = createTheme({
    typography: {
        fontFamily: 'Poppins',
    }
});

import {personIcon} from './assets'
// Customizable Area End

export default class ContactUs extends ContactUsWebController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props
        return (
            <ThemeProvider theme={theme}>
                <Box className={classes.pageContainer}>
                    <Box className={classes.logoContainerContactUs}>
                        <img src={logoImage} alt="Logo" className={classes.logoContactUs} />
                        <img src={ipoImage} alt="Ipo-Logo" className={classes.logoContactUs} />
                    </Box>
                    <Box className={classes.outerContainer}>
                        <Box className={classes.innerContainer}>
                            <Navbar selectedTab={4} alertIcon={true} navigation={this.props.navigation} />

                            <Box className={classes.main}>
                                <Box className={classes.headSection}>
                                    <Typography variant="h4" className={classes.headTitle}>
                                        {configJSON.pageTitle}
                                    </Typography>
                                </Box>

                                {!this.state.isLoading &&
                                    <Box className={classes.cardSection}>
                                        <Box className={classes.cardHead}>
                                            <Box className={classes.profileIcon}>
                                                <img src={personIcon} width="40" height="40"/>
                                            </Box>
                                            <Box>
                                                <Typography className={classes.personName}>{this.state.contactDetails.name}</Typography>
                                                <Typography style={{ fontSize: "14px", fontWeight: 400 }}>
                                                    {this.state.contactDetails.designation}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box sx={{ fontSize: "16px", fontWeight: 400, lineHeight: "22px" }}>
                                            {this.state.contactDetails.address}<br />
                                            {`Phone: ${this.state.contactDetails.phoneNo}`}<br />
                                            {`Fax: ${this.state.contactDetails.faxNo}`}<br />
                                            {`Email: ${this.state.contactDetails.email}`}
                                        </Box>
                                    </Box>
                                }

                            </Box>
                            <Typography className={classes.cardBottom}>
                                {configJSON.helpLineNumber}
                            </Typography>
                        </Box>


                    </Box>
                    <footer className={classes.footer}>
                        <p className={classes.footerText}>
                            Guidelines for using NTRP (Bharat Kosh) Payment Gateway <a className={classes.guideHypertext} href="#">Guide</a>
                        </p>
                    </footer>
                </Box>
            </ThemeProvider>
        );
        // Customizable Area End
    }
}

// Customizable Area Start

const webStyles = createStyles({
    main: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflow: "hidden"
    },
    headSection: {
        color: "#8A4504",
        backgroundColor: "#FFEEDE",
        padding: "14px",
        display: "flex",
        justifyContent: "center",
        alignItem: "center",
        width: "100%",
        marginTop: "50px"
    },
    headTitle: {
        fontSize: "24px",
        fontWeight: 500,
        fontFamily: 'Poppins-Medium'
    },
    cardSection: {
        color: "#8A4504",
        backgroundColor: "#FFEEDE",
        maxWidth: "370px",
        padding: "30px 40px",
        marginTop: "30px",
        borderRadius: "12px",
        border: "solid 1px"
    },
    cardHead: {
        display: "flex",
        marginBottom: "10px"
    },
    profileIcon: {
        background: "#64748B",
        padding: "10px",
        borderRadius: "100%",
        marginRight: "10px",
    },
    personName: {
        fontSize: "14px",
        fontWeight: 600
    },
    cardBottom: {
        fontSize: "14px",
        fontWeight: 400,
        color: "#8A4504",
        textAlign: "center",
        fontFamily: 'Poppins',
        padding: "30px",
    },
    logoContainerContactUs: {
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "#ffd4ac",
        maxWidth: "100%",
        height: "120px",
        padding: "20px",
    },
    logoContactUs: {
        objectFit: "contain",
        height: "100px",
        padding: "4px",
        '@media (max-width: 900px)': {
          height: "80px",
          padding: "10px",
        },
        '@media (max-width: 600px)': {
          height: "60px",
          padding: "5px",
        },
        '@media (max-width: 300px)': {
          height: "40px",
          padding: "4px",
        }
    },
    footer: {
        backgroundColor: "#ffffff",
        padding: "10px",
        textAlign: "center",
        height: "70px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    guideHypertext: {
        color: "#FF0000",
        textDecoration: "none",
    },
    innerContainer:{
        backgroundColor: "white",
        borderRadius: "20px",
        boxShadow: "5px 10px 8px #888888",
    },
    outerContainer: {
        backgroundColor: "#ffd4ac",
        padding: "20px 180px",
        fontFamily: "Poppins",
        '@media (max-width: 900px)': {
              padding: "20px 50px",
        },
        '@media (max-width: 600px)': {
              padding: "20px 30px",
        }
    },
    pageContainer: {
        overflowX: "hidden",
    },
    footerText: {
        margin: 0,
        fontSize: "20px !important",
        color: "#000000",
        fontWeight: 400,
        lineHeight: "45px !important",
        fontFamily: "Poppins-Medium",
    }

});
const ContactUsWithStyles = withStyles(webStyles)(ContactUs);
export { ContactUsWithStyles };
// Customizable Area End
