export const refresh = require("../assets/group_group.png");
export const captchaback = require("../assets/image_image_cleanup.png");
export const logo = require("../assets/group_logo.png");
export const logo1 = require("../assets/image_image.png");
export const Headerlogo = require("../assets/logoImage.png");
export const imageSuccess = require("../assets/image_success.png");
export const imageUpload = require("../assets/Group 251.svg");
export const warningIcon = require("../assets/Group 281.svg");
export const registration = require("../assets/registration.png");
export const linkExpired = require("../assets/Group 317.png");

