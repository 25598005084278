import React from "react";
// Customizable Area Start
import { Headerlogo,warningIcon,registration  } from "../../landingpage/src/assets";
import { ipoImage } from "../../customform/src/assets";
import { Selected,unSelected } from "../src/assets";
import LinkList from "../../../components/src/LinkList";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { OutlinedInput, Typography, Container,InputAdornment ,IconButton,
  Dialog, DialogContent, DialogContentText
} from "@material-ui/core";
import { withStyles,createStyles } from "@material-ui/core/styles";
// Customizable Area End
import ForgotPasswordController, { Props } from "./ForgotPasswordController";

export class SetPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.checkResetLinkExpiry()
    // Customizable Area End
  }
  render() {
    // Customizable Area Start
    const {upperCase,lowerCase,numberCase,specialChar}=this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <div className={this.props.classes.appContainerFG}>
        <div className={this.props.classes.headerFGSetPwd}>
          <img src={Headerlogo} alt="Logo" className={this.props.classes.logoFGSetPwd} />
          <img src={ipoImage} alt="Ipo-Logo" className={this.props.classes.logoFGSetPwd} />
        </div>
        <div className={this.props.classes.cardRLSet}>
          <div className={this.props.classes.topLineSet}>
            <button className={this.props.classes.customButton}>
              Reset password
            </button>
          </div>
          <Container className={this.props.classes.setPwdContainerFG}>
            <div 
              className={this.props.classes.divCenter}>
              <Typography variant="subtitle1"
                className={this.props.classes.formLabelUserDetailsUS}
              >
                {"Enter New password"}<span className={this.props.classes.startColorS}>*</span>
              </Typography>
              <div className={this.props.classes.containerField}>
                <OutlinedInput
                  type={this.state.showPwd ? 
                      "text":"password"}
                  id="newPassword"
                  className={this.props.classes.formInputUserDetailsSP}
                  onChange={e => {
                    this.setState({
                      newPassword: e.target.value,
                      newPasswordError:""
                    });
                  }}
                  endAdornment={
                    <InputAdornment 
                    position="end">
                      <IconButton
                        id="pwdVisibility"
                        aria-label="toggle visibility"
                        edge="end"
                        onClick={() => {
                          this.setState({ showPwd: !this.state.showPwd });
                        }}
                      >
                        {!this.state.showPwd ? (
                          <VisibilityOff />
                        ) : (<Visibility />)}
                      </IconButton>
                    </InputAdornment>
                  }
                />
               {this.state.newPasswordError && (
                  <div className={this.props.classes.textUserDetailsCheck}>
                    <div className={this.props.classes.textUserDetailsError}>
                    <img
                      className={this.props.classes.alertIcon}
                      src={warningIcon}
                      alt="alertIcon"
                    />
                    <span className={this.props.classes.errorMessageTextUserDetails}>
                      {this.state.newPasswordError}
                    </span>
                    </div>
                    <div className={this.props.classes.textUserDetailsError}>
                    <img
                      className={this.props.classes.alertIcon}
                      src={warningIcon}
                      alt="alertIcon"
                    />
                    <span className={this.props.classes.errorMessageTextUserDetails}>
                      {"Should contain:"}
                    </span>
                    </div>
                    <div className={this.props.classes.textUserDetailsValidate}>
                    <img
                      className={this.props.classes.alertIcon}
                      src={!lowerCase ? Selected :unSelected}
                      alt="alertIcon"
                    />
                    <span className={ !lowerCase ?this.props.classes.errorValidateUserDetails : this.props.classes.errorUserDetails}>
                      {"Lower case letters (a-z)"}
                    </span>
                    </div>
                    <div className={this.props.classes.textUserDetailsValidate}>
                    <img
                      className={this.props.classes.alertIcon}
                      src={!upperCase ? Selected :unSelected}
                      alt="alertIcon"
                    />
                    <span className={ !upperCase  ?this.props.classes.errorValidateUserDetails : this.props.classes.errorUserDetails}>
                      {"Upper case letters (A-Z)"}
                    </span>
                    </div>
                    <div className={this.props.classes.textUserDetailsValidate}>
                    <img
                      className={this.props.classes.alertIcon}
                      src={!numberCase ? Selected :unSelected}
                      alt="alertIcon"
                    />
                    <span className={ !numberCase ?this.props.classes.errorValidateUserDetails : this.props.classes.errorUserDetails}>
                      {"Numbers (i.e. 0-9)"}
                    </span>
                    </div>
                    <div className={this.props.classes.textUserDetailsValidate}>
                    <img
                      className={this.props.classes.alertIcon}
                      src={!specialChar ? Selected : unSelected}
                      alt="alertIcon"
                    />
                    <span className={ !specialChar ? this.props.classes.errorValidateUserDetails : this.props.classes.errorUserDetails}>
                      {"At least one Special char (i.e. @#$%...)"}
                    </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div 
            className={this.props.classes.divCenterTest}
            >
              <Typography variant="subtitle1"
                className={this.props.classes.formLabelUserDetailsUS}
              >
                {"Re-enter new password"}<span className={this.props.classes.startColorS}>*</span>
              </Typography>
              <div className={this.props.classes.containerField}>
                <OutlinedInput
                  className={this.props.classes.formInputUserDetailsSP}
                  type={
                    this.state.showAltPwd ? "text" :"password"}
                    onChange={e => {
                      this.setState({
                        reTypePassword: e.target.value,
                        reTypePwdError:""
                      });
                    }}
                  id="reTypePassword"
                  endAdornment={
                    <InputAdornment 
                     position="end">
                      <IconButton
                        id="pwdVisibility"
                        aria-label="toggle password"
                        onClick={() => {
                          this.setState({ showAltPwd: 
                            !this.state.showAltPwd });
                        }}
                        edge="end"
                      >
                        {!this.state.showAltPwd ? (<VisibilityOff />
                         ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {this.state.reTypePwdError && (
                  <div className={this.props.classes.textUserDetails}>
                    <img
                      className={this.props.classes.alertIcon}
                      src={warningIcon}
                      alt="alertIcon"
                    />
                    <span className={this.props.classes.errorMessageTextUserDetails}>
                      {this.state.reTypePwdError}
                    </span>
                    </div>
                )}
              </div>
            </div>
          </Container>
          <div 
          className={this.props.classes.buttonsContainerFG}>
            <button className={this.props.classes.actionButton}
              id="homepage"
              onClick={() => {
                this.setNewpassword()
              }}
            >Confirm</button>
          </div>
        </div>
        <LinkList />
        {
          <Dialog 
          open={this.state.pwdChangeDone}
          fullWidth
          maxWidth="md"
          className={this.props.classes.dialogModal}
          aria-labelledby="form-dialog-title"
           >
            <DialogContent className={this.props.classes.dialogContent} >
              <DialogContentText className={this.props.classes.dialogContentText}>
                <Typography
                  variant="subtitle1"
                  className={this.props.classes.dialogLabelUserDetails}
                >
                  {"Password changed"}
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={this.props.classes.dialogLabelUserDetails}
                >
                  {"Your password has been changed successfully"}
                </Typography>
              </DialogContentText>
              <DialogContentText className={this.props.classes.dialogContentTextImage}>
                <img src={registration} width={100} height={100} alt="registration"/>
              </DialogContentText>
              <DialogContentText  className={this.props.classes.dialogContentTextButton} >
                <button
                  className={this.props.classes.dialogActionButton}
                  id="navigateHome"
                  onClick={()=>{this.handleNavigation("LandingPageweb")}}
                >
                  Back to login
                </button>
              </DialogContentText>
            </DialogContent>
              
          </Dialog>
        }
      <footer className={this.props.classes.customFooter}>
          <p className={this.props.classes.customFooterText}>
            Guidelines for using NTRP (Bharat Kosh) Payment Gateway{" "}
            <span className={this.props.classes.startColorS}>Guide</span>
          </p>
        </footer>
      </div>
     // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyles = () =>
createStyles({
  appContainerFG: {
    backgroundColor: '#ffd4ac',
    height: 'auto', 
    overflowX: 'hidden',
  },
  headerFGSetPwd: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#ffd4ac",
    maxWidth: "100%",
    height: "100px",
    marginBottom: "50px"
  },
  logoFGSetPwd: {
    objectFit: "contain",
    height: "92px",
    padding: "20px",
    '@media (max-width: 900px)': {
      height: "80px",
      padding: "10px",
    },
    '@media (max-width: 600px)': {
      height: "60px",
      padding: "5px",
    },
    '@media (max-width: 300px)': {
      height: "40px",
      padding: "4px",
    }
  },
  customFooter: {
    backgroundColor: '#ffffff',
    padding: '10px',
    height: '70px', // Note: Using is discouraged and should be avoided if possible.
    textAlign: 'center' ,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  customFooterText: {
    fontWeight: 400 ,
    fontSize: '20px', // Note: Using is discouraged and should be avoided if possible.
    margin: '0',
    fontFamily: 'Poppins-Medium, sans-serif', // Note: Using is discouraged and should be avoided if possible.
    lineHeight: '45px', // Note: Using is discouraged and should be avoided if possible.
    color: '#000000',
  },
  startColorS: {
    color: 'red',
  },
  actionButton: {
    fontSize: "18px",
    padding: "10px 20px",
    borderRadius: "5px",
    fontWeight: "bold" ,
    cursor: "pointer",
    minWidth: "300px" /* Minimum width for the button */,
    margin: "0 10px" /* Add some margin between the buttons */,
    display: "flex",
    alignItems: "center",
    height: "54px" /* Height for the button */,
    textTransform: "none" ,
    justifyContent: "center",
    backgroundColor: "#894402", // Background color
    fontFamily: 'Poppins, sans-serif',
    border: "none" ,
    color: "#ffffff", // Text color
  },
  cardRLSet: {
    height: 'auto',
    minHeight: '400px',
    width: '75%',
    flexDirection: 'column' ,
    justifyContent: 'center',
    display: 'flex',
    position: 'relative' ,
    backgroundColor: '#ffffff',
    alignItems: 'center',
    padding: '40px 20px 20px', 
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    margin: '0 auto',
    marginTop: '60px',
    marginBottom: '40px',
    borderRadius: '10px',
  },
  topLineSet: {
    backgroundColor: '#ccc',
    height: '1px',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute' ,
    top: '0',
    display: 'flex',
  },
  setPwdContainerFG:{
    marginTop: '50px',
  },
  customButton: {
    padding: '5px 10px',
    border: 'none',
    fontWeight: 400,
    fontSize: '30px', // Note: Using is discouraged and should be avoided if possible.
    height: '70px', // Note: Using is discouraged and should be avoided if possible.
    width: '600px', // Note: Using is discouraged and should be avoided if possible.
    color: '#ffffff',
    backgroundColor: '#894402',
    fontFamily: "Poppins-Medium, sans-serif", // Note: Using is discouraged and should be avoided if possible.
    borderRadius: '5px',
    '@media (max-width: 1200px)': {
      fontSize: '21px',
      width: '340px',
    },
    '@media (max-width: 1100px)': {
      fontSize: '18px',
      width: '210px',
      padding: '0 0',
    },
  },
  divCenter: {
    display: 'flex',
    marginBottom: '1rem',
    marginTop: '10px',
    marginLeft: '11%',
    '@media (max-width: 1200px)': {
      display: 'block', 
      flexDirection: 'column',
      alignItems: 'center', // Center horizontally in column layout
      justifyContent: 'center', // Center vertically in column layout
      marginLeft: '11%',
      marginRight: '11%', // Reset margin for smaller screens
    },
  },
  divCenterTest: {
    display: 'flex',
    marginLeft: '11%',
    marginBottom: '5%',
    '@media (max-width: 1200px)': {
      display: 'block', 
      flexDirection: 'column',
      alignItems: 'center', // Center horizontally in column layout
      justifyContent: 'center', // Center vertically in column layout
      marginLeft: '11%',
      marginRight: '11%', // Reset margin for smaller screens
    },
  },
  formLabelUserDetailsUS: {
    color: '#000000',
    fontWeight: 400,
    fontFamily: 'Poppins, sans-serif',
    marginTop: '10px',
    fontSize: '18px',
    flex: '0 0 250px',
    '@media (max-width: 1200px)': {
      flex: '0 0 5px',
      alignSelf: "flex-start",
      alignItems:"flex-start"
    }
  },
  containerField: {
    flex: 1,
    flexDirection: 'column' ,
    '@media (max-width: 1200px)': {
      alignSelf: "flex-start",
      alignItems:"flex-start"
    }
  },
  formInputUserDetailsSP: {
    width: '80%',
    padding: '10px',
    fontSize: '16px',
    height: '53px',
    marginBottom: '8px',
    borderRadius: '5px',
    color: '#000000',
    fontFamily: 'Poppins, sans-serif',
    '@media (max-width: 1200px)': {
      width: '100% !important' // Use !important to ensure it overrides any conflicting styles
    }
  },
  formInputUserDetailsFG: {
    fontSize: '16px',
    width: '75%',
    padding: '10px',
    height: '53px',
    borderRadius: '5px',
    marginBottom: '8px',
    border: '1.31px solid #979797',
    color: '#000000',
    fontFamily: 'Poppins, sans-serif',
  },
  textUserDetails: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left' ,
  },
  textUserDetailsCheck: {
    display: 'flex',
    // alignItems: 'center',
    textAlign: 'left' ,
    flexDirection: 'column' ,
  },
  textUserDetailsValidate: {
    display: 'flex',
    marginLeft: '35px',
    margin:"5px"
  },
  textUserDetailsError: {
    display: 'flex',
    margin:"5px"
  },
  errorValidateUserDetails: {
    fontStyle: 'normal',
    position: 'relative' ,
    fontFamily: 'Poppins-Medium, sans-serif',
    fontWeight: 500 ,
    color: '#009B31',
    fontSize: '14px',
    lineHeight: '18px',
    marginTop: '1px',
    left: '0'
  },
  errorUserDetails: {
    fontStyle: 'normal',
    position: 'relative' ,
    fontFamily: 'Poppins-Medium, sans-serif',
    fontWeight: 500 ,
    color: '#979797',
    fontSize: '14px',
    lineHeight: '18px',
    marginTop: '1px',
    left: '0'
  },
  errorMessageTextUserDetails: {
    fontStyle: 'normal',
    position: 'relative' ,
    fontFamily: 'Poppins-Medium, sans-serif',
    fontWeight: 500 ,
    color: '#FF0000',
    fontSize: '14px',
    lineHeight: '18px',
    marginTop: '1px',
    left: '0'
  },
  alertIcon: {
    height: '20px',
    marginRight: '4px',
    width: '20px',
  },
  buttonsContainerFG: {
    marginBottom: '30px', // Note: Using is discouraged and should be avoided if possible.
    display: 'flex',
    justifyContent: 'center',
    marginTop: '30px', // Note: Using is discouraged and should be avoided if possible.
  },
  dialogModal: {
    "& .MuiPaper-root": {
      borderRadius: "15px",
      padding: "20px",
      maxWidth: "690px",
    },
    '@media (max-width: 900px)': {
      "& .MuiPaper-root": {
        maxHeight: "300px", 
      },
    },
    '@media (max-width: 600px)': {
      "& .MuiPaper-root": {
        maxHeight: "200px", 
      },
    },
    '@media (max-width: 300px)': {
      "& .MuiPaper-root": {
        maxHeight: "100px", 
      },
    }
  },
  dialogLabelUserDetails: {
    fontSize: '18px',
    color: '#894402',
    fontWeight: 400 ,
    alignSelf: 'flex-start',
    width: "100%",
    fontFamily: 'Poppins, sans-serif',
    '@media (max-width: 900px)': {
      fontSize: '14px',
    },
    '@media (max-width: 600px)': {
      fontSize: '12px',
    },
    '@media (max-width: 300px)': {
      fontSize: '10px',
    }
  },
  dialogContent: {
    textAlign: 'center'
  },
  dialogContentText: {
    marginTop: '18px',
    marginBottom: '30px',
    '@media (max-width: 900px)': {
      marginTop: '14px',
      marginBottom: '20px',
    },
    '@media (max-width: 600px)': {
      marginTop: '10px',
      marginBottom: '10px',
    },
    '@media (max-width: 300px)': {
      marginTop: '5px',
      marginBottom: '5px',
    }
  },
  dialogContentTextImage: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '40px',
    display: 'flex',
    '@media (max-width: 900px)': {
      marginTop: '25px',
    },
    '@media (max-width: 600px)': {
      marginTop: '15px',
    },
    '@media (max-width: 300px)': {
      marginTop: '5px',
    }
  },
  dialogContentTextButton: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  dialogActionButton: {
    marginBottom: '20px',
    fontSize: '18px',
    fontWeight: 'bold' ,
    borderRadius: '8px',
    marginTop: '30px',
    minWidth: '300px',
    cursor: 'pointer',
    textTransform: 'none' ,
    height: '54px',
    backgroundColor: '#894402',
    border: '1px solid #894402',
    fontFamily: 'Poppins, sans-serif',
    color: '#ffffff',
    '@media (max-width: 900px)': {
      marginTop: '20px',
      minWidth: '200px',
      fontSize: '14px',
      marginBottom: '12px',
    },
    '@media (max-width: 600px)': {
      marginTop: '10px',
      minWidth: '100px',
      fontSize: '12px',
      marginBottom: '9px',
    },
    '@media (max-width: 300px)': {
      marginTop: '5px',
      fontSize: '8px',
      minWidth: '90px',
      marginBottom: '5px',
    }
  },
})
export default withStyles(webStyles)(SetPassword)
// Customizable Area End