import React from "react";
// Customizable Area Start
import { Headerlogo,warningIcon } from "../../landingpage/src/assets";
import { ipoImage } from "../../customform/src/assets";
import LinkList from "../../../components/src/LinkList";
import { OutlinedInput, Typography, Container } from "@material-ui/core";
import { withStyles,createStyles } from "@material-ui/core/styles";
// Customizable Area End
import ForgotPasswordController, { Props } from "./ForgotPasswordController";
export class ResetPassWord extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <div className={this.props.classes.appContainerFG}>
        <div className={this.props.classes.headerFGResetpwd}>
          <img src={Headerlogo} alt="Logo" className={this.props.classes.logoFGResetpwd} />
          <img src={ipoImage} alt="Ipo-Logo" className={this.props.classes.logoFGResetpwd} />
        </div>
        <div className={this.props.classes.cardFGReset}>
          <div className={this.props.classes.cardHeaderFGR}>
            <span className={this.props.classes.headerTextFGR}>Forgot password</span>
          </div>
          <div className={this.props.classes.buttonsContainerFGR}>
            <button className={this.props.classes.actionButton} id="backButton">
              User ID
            </button>
            <button className={this.props.classes.actionButtonBack} id="backButton">
              Digital signature
            </button>
          </div>
          <Container className={this.props.classes.resetContainer}>
            <div className={this.props.classes.divCenter}>
              <Typography
                variant="subtitle1"
                className={this.props.classes.formLabelUserDetailsU}
              >
                {"Registered Email_Id"}
                <span className={this.props.classes.startColorR}>*</span>
              </Typography>
              <div className={this.props.classes.containerField}>
                <OutlinedInput
                  type="text"
                  placeholder="Enter registered email id"
                  className={this.props.classes.formInputUserDetailsFG}
                  id="RegisteredEmailId"
                  onChange={e => {
                    this.setState({
                      RegisteredEmailId: e.target.value,
                      RegisteredEmailIdError: ""
                    });
                  }}
                />
                {this.state.RegisteredEmailIdError && (
                  <div className={this.props.classes.textUserDetails}>
                    <img
                      src={warningIcon}
                      alt="alertIcon"
                      className={this.props.classes.alertIcon}
                    />
                    <span className={this.props.classes.errorMessageTextUserDetails}>
                      {this.state.RegisteredEmailIdError}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className={this.props.classes.divCenter}>
              <Typography
                variant="subtitle1"
                className={this.props.classes.formLabelUserDetailsU}
              >
                {"User ID"}
                <span className={this.props.classes.startColorR}>*</span>
              </Typography>
              <div className={this.props.classes.containerField}>
                <OutlinedInput
                  type="text"
                  placeholder="Enter user ID"
                  className={this.props.classes.formInputUserDetailsFG}
                  id="RegisteredUserId"
                  onChange={e => {
                    this.setState({
                      RegisteredUserId: e.target.value,
                      RegisteredUserIdError: ""
                    });
                  }}
                />
                {this.state.RegisteredUserIdError && (
                  <div className={this.props.classes.textUserDetails}>
                    <img
                      src={warningIcon}
                      alt="alertIcon"
                      className={this.props.classes.alertIcon}

                    />
                    <span className={this.props.classes.errorMessageTextUserDetails}>
                      {this.state.RegisteredUserIdError}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </Container>
          <div className={this.props.classes.buttonsContainerError}>
            {this.state.userNotFoundError && (
              <div className={this.props.classes.textUserDetails}>
                <img
                  src={warningIcon}
                  alt="alertIcon"
                  className={this.props.classes.alertIcon}
                />
                <span className={this.props.classes.errorMessageTextUserDetails}>
                  {this.state.userNotFoundError}
                </span>
              </div>
            )}
          </div>
          <div className={this.props.classes.buttonsContainerFGR}>
            <button
              className={ this.state.resetButtonDisable
                  ? this.props.classes.actionButtonDisable
                  : this.props.classes.actionButton
              }
              disabled={this.state.resetButtonDisable}
              id="ResetLink"
              onClick={() => {this.valiateField()}}
            >Reset</button>
          </div>
          
        </div>
        <LinkList />
        <footer className={this.props.classes.customFooterR}>
          <p className={this.props.classes.customFooterTextR}>
             Guidelines for using NTRP (Bharat Kosh) Payment Gateway{" "}
            <span className={this.props.classes.startColorR}>Guide</span>
          </p>
        </footer>
      </div>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyles = () =>
createStyles({
  appContainerFG: {
    height: 'auto', 
    backgroundColor: '#ffd4ac',
    overflowX: 'hidden'
  },
  headerFGResetpwd: {
    backgroundColor: "#ffd4ac",
    display: "flex",
    justifyContent: "space-between",
    maxWidth: "100%",
    height: "100px",
    marginBottom: "40px",
    padding: "10px",
  },
  logoFGResetpwd: {
    padding: "20px",
    objectFit: "contain",
    height: "92px",
    '@media (max-width: 900px)': {
      padding: "10px",
      height: "80px",
    },
    '@media (max-width: 600px)': {
      height: "60px",
      padding: "5px",
    },
    '@media (max-width: 300px)': {
      height: "40px",
      padding: "4px",
    }
  },
  customFooterR: {
    padding: '10px',
    backgroundColor: '#ffffff',
    height: '70px', // Note: Using is discouraged and should be avoided if possible.
    display: 'flex',
    textAlign: 'center' ,
    justifyContent: 'center',
    alignItems: 'center',
  },
  customFooterTextR: {
    fontSize: '20px', // Note: Using is discouraged and should be avoided if possible.
    margin: '0',
    fontWeight: 400 ,
    lineHeight: '45px', // Note: Using is discouraged and should be avoided if possible.
    fontFamily: 'Poppins-Medium, sans-serif', // Note: Using is discouraged and should be avoided if possible.
    color: '#000000',
  },
  startColorR: {
    color: 'red',
  },
  actionButton: {
    fontSize: "18px",
    padding: "10px 20px",
    borderRadius: "5px",
    margin: "0 10px" /* Add some margin between the buttons */,
    fontWeight: "bold",
    minWidth: "300px" /* Minimum width for the button */,
    height: "54px" /* Height for the button */,
    cursor: "pointer",
    justifyContent: "center",
    display: "flex",
    fontFamily: 'Poppins, sans-serif',
    textTransform: "none" ,
    backgroundColor: "#894402", // Background color
    color: "#ffffff", // Text color
    alignItems: "center",
    border: "none" ,
    '@media (max-width: 1200px)': {
      width: "90px"
    },
  },
  cardFGReset: {
    height: 'auto', // Note: Using is discouraged and should be avoided if possible.
    minHeight: '500px',
    display: 'flex',
    flexDirection: 'column' ,
    width: '75%',
    backgroundColor: '#ffffff',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    margin: '0 auto',
    position: 'relative' ,
    marginTop: '40px', // Note: Using is discouraged and should be avoided if possible.
    padding: '20px',
    marginBottom: '40px',
  },
  cardHeaderFGR: {
    alignItems: 'center',
    marginTop: '33px',
    display: 'flex',
    justifyContent: 'center',
  },
  headerTextFGR: {
    fontWeight: 500 ,
    fontFamily: 'Poppins, sans-serif',
    marginBottom: '20px',
    fontSize: '25px',
  },
  buttonsContainerFGR: {
    marginTop: '10px', // Note: Using is discouraged and should be avoided if possible.
    display: 'flex',
    marginBottom: '30px', // Note: Using is discouraged and should be avoided if possible.
    justifyContent: 'center',
    alignItems:"center",
    '@media (max-width: 1200px)': {
      flexDirection: "column",
      gap:"10px"
    },
  },
  actionButtonBack: {
    fontWeight: "bold" ,
    padding: "10px 20px",
    borderRadius: "5px",
    fontSize: "18px",
    cursor: "pointer",
    margin: "0 10px" /* Add some margin between the buttons */,
    height: "54px" /* Height for the button */,
    minWidth: "300px" /* Minimum width for the button */,
    justifyContent: "center",
    display: "flex",
    textTransform: "none" ,
    alignItems: "center",
    backgroundColor: "#ffffff", // Background color
    fontFamily: '"Poppins", sans-serif',
    border: "1px solid #894402", // Remove border
    marginRight: "10px",
    color: "#894402",
    '@media (max-width: 1200px)': {
      width: "90px"
    },
  },
  resetContainer: {
    marginTop: '30px'
  },
  divCenter: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: '1rem',
    marginLeft: '11%'
  },
  formLabelUserDetailsU: {
    fontWeight: 400 ,
    color: '#000000',
    fontSize: '18px',
    alignSelf: 'center',
    flex: '0 0 250px',
    fontFamily: 'Poppins, sans-serif',
  },
  containerField: {
    flex: 1
  },
  formInputUserDetailsFG: {
    height: '53px',
    width: '75%',
    fontSize: '16px',
    padding: '10px',
    borderRadius: '5px',
    marginBottom: '8px',
    color: '#000000',
    fontFamily: '"Poppins", sans-serif',
  },
  textUserDetails: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left' ,
  },
  errorMessageTextUserDetails: {
    fontFamily: 'Poppins-Medium, sans-serif',
    fontStyle: 'normal',
    position: 'relative' ,
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: 500,
    marginTop: '5px',
    left: '0',
    color: '#FF0000',
  },
  alertIcon: {
    marginRight: '4px',
    width: '20px',
    height: '20px',
  },
  actionButtonDisable: {
    fontSize: "18px",
    padding: "10px 20px",
    borderRadius: "5px",
    fontWeight: "bold" ,
    cursor: "pointer",
    margin: "0 10px" /* Add some margin between the buttons */,
    height: "54px" /* Height for the button */,
    minWidth: "300px" /* Minimum width for the button */,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "none" ,
    backgroundColor: "#b38961", // Background color
    fontFamily: "Poppins, sans-serif",
    color: "#ffffff" // Text color
  },
  buttonsContainerError: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '50px',
    marginBottom: '10px',
  },
  
})
export default withStyles(webStyles)(ResetPassWord)
// Customizable Area End
