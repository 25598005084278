import React, { Component } from "react";
import {
    withStyles,
    createStyles,
    WithStyles,
    Theme,
} from "@material-ui/core/styles";
import {
    Grid,
    Typography, Dialog
} from "@material-ui/core";
import { successLogo, warningLogo } from "../../blocks/settings2/src/assets";

const styles = (theme: Theme) =>
    createStyles({
        messageText: {
            fontFamily: "Poppins, sans-serif",
            fontSize: 32,
            color: "#000000",
            fontWeight: 500,
        },
        logos: {
            maxWidth: "132px",
            width: "100%",
            height: "132px",
            maxHeight: "100%"
        }
    });

interface VerificationDailogProps extends WithStyles<typeof styles> {
    closeDailog: Function,
    open: boolean,
    sourceType: string,
    message: string,
    verificationStatus: string

}

class VerificationDailog extends Component<VerificationDailogProps> {
    render() {

        const { classes } = this.props;
        const renderImage = this.props.verificationStatus === "success" ? successLogo : warningLogo;

        return (
            <>
                <Dialog
                    maxWidth="lg"
                    open={this.props.open}
                    PaperProps={{
                        style: {
                            maxWidth: "648px",
                            maxHeight: "276px",
                            height: "100%",
                            borderRadius: "8px",
                            width: "100%"
                        },
                    }}
                    onClose={() => this.props.closeDailog(this.props.sourceType)}

                >
                    <Grid item xs={12} container>
                        <Grid container alignItems="center">
                            <Grid item xs={12} container justifyContent="center">
                                <img src={renderImage} width={100} height={100} />
                            </Grid>
                            <Grid item xs={12} container justifyContent="center">
                                <Typography component={'div'} className={classes.messageText}>{this.props.message}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Dialog>
            </>

        );
    }
}

export default withStyles(styles)(VerificationDailog);
