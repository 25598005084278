import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Typography,
  Radio,
  FormLabel,
  FormControl,
  FormControlLabel,
  RadioGroup,
  TextField,
  InputLabel,
  Select,
  MenuItem
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { createStyles, withStyles } from "@material-ui/styles";
import { ipoImage, logoImage } from "../../customform/src/assets";
import Navbar from "../../../components/src/Navbar";
import moment from "moment";
import Pagination from '@material-ui/lab/Pagination';
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    },
    secondary:{
      main: "#8A4504",
    }
  },
  typography: {
    fontFamily: "Poppins, sans-serif",
  },
});
// Customizable Area End

import AdvancedSearchController, { Props, configJSON } from "./AdvancedSearchController";

export default class AdvancedSearch extends AdvancedSearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { searchText, showSearchResult, selectedRadioOption, loading } = this.state
    const { classes } = this.props
    
    const paginationSizeResponsive = () => {
      if (window.innerWidth < 600) {
        return "small"
      } else {
        return "medium"
      }
    }
    
    return (
      <ThemeProvider theme={theme}>
        <Box className={classes.pageContainer}>
          <Box className={classes.imageContainer}>
            <img className={classes.image} src={logoImage} alt="Logo" />
            <img className={classes.image} src={ipoImage} alt="ipo-logo" />
          </Box>

          <Box className={classes.outerContainer}>
            <Box className={classes.innerContainer}>
              <Navbar selectedTab={0} alertIcon={true} navigation={this.props.navigation} />

              {!showSearchResult ?
                <Box className={classes.main}>
                  <Box className={classes.headSection}>
                    <Typography variant="h4" className={classes.headTitle}>
                      Search for existing GI
                    </Typography>
                  </Box>

                  <FormControl className={classes.labelContainer}>
                    <FormLabel id="demo-row-radio-buttons-group-label" className={classes.formLabel}>Search with:</FormLabel>
                    <RadioGroup
                      data-test-id="radio-group"
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      onChange={(e) => this.handleRadioSelect(e.target.value)}
                      value={this.state.selectedRadioOption}
                    >
                      <FormControlLabel value="gi_name" control={<Radio className={classes.greenRadio} />} label="GI Name" />
                      <FormControlLabel value="gi_number" control={<Radio className={classes.greenRadio} />} label="GI Number" />
                    </RadioGroup>
                  </FormControl>

                  <Box className={classes.searchContainer}>
                    <TextField
                      data-test-id="search-input-field"
                      value={this.state.searchText}
                      onChange={(e) => { this.handleUserInput(e.target.value) }}
                      variant="outlined"
                      className={classes.input}
                      disabled={!selectedRadioOption}
                    />
                    <Button className={classes.button} onClick={() => this.handleSearch(searchText)} disabled={!searchText.trim()} data-test-id="search-button">Search</Button>
                  </Box>
                  <Button className={classes.buttonOutline} style={{marginTop:"80px",marginBottom:"20px"}} onClick={this.handleBackButton} data-test-id="back-button">Back</Button>
                </Box>
                : 
                <>
                  <Box className={classes.headerContainer}>
                    <div>{`Results for GI ${this.state.selectedRadioOption === 'gi_name' ? "name" : "number"} for “${searchText}”`}</div>
                    <div className={classes.dropdownContainer}>
                      <span>Result Showing</span>
                      {/* <span className={classes.paginationDropdown}> */}
                          <Select
                            className={classes.paginationDropdown}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={this.state.pagination.per_page}
                            variant="outlined"
                            disableUnderline
                            onChange={this.handleItemPerPage}
                          >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={30}>30</MenuItem>
                          </Select>
                      {/* </span> */}
                      <span>out of TOTAL {this.state.pagination.count}</span>
                    </div>
                  </Box>

                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow className={classes.tableHeading}>
                          {configJSON.COLUMNS.map((element: any, index: number) => {
                            return <TableCell style={{width: element.width, paddingLeft: index===0 ? "24px": "16px"}} key={index}>{element.name}</TableCell>
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.searchResultData.length ?
                          (this.state.searchResultData.map((element, index: number) => {
                            return <TableRow className={classes.tableRows} key={index}>
                              <TableCell style={{ paddingLeft: "24px" }}>{element.attributes.gi_number}</TableCell>
                              <TableCell>{moment(element.attributes.application_date).format("MMM DD, YYYY")}</TableCell>
                              <TableCell className={classes.giName}>
                                {element.attributes.gi_name}
                                </TableCell>
                              <TableCell>{element.attributes.aplicant_name}</TableCell>
                              <TableCell>{moment(element.attributes.publication_date).format("MMM DD, YYYY")}</TableCell>
                              <TableCell><Button className={classes.viewButton} onClick={() => this.handleView(element.attributes.gi_number)}>View</Button></TableCell>
                            </TableRow>
                          })) :
                          <TableRow>
                            <TableCell colSpan={configJSON.COLUMNS.length} align="center" style={{border:"none"}}>
                              <p className={classes.noRecord}>No Record found</p>
                            </TableCell>
                          </TableRow>
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box className={classes.bottomSection}>
                    <Button
                      className={classes.buttonOutline}
                      onClick={this.handleCloseSearch}
                      data-test-id="backToAdvSearch">
                      Back
                    </Button>
                    {
                      (this.state.pagination.showPagination && this.state.pagination.totalCount!==1) &&
                      <Pagination
                        className={classes.pagination}
                        count={this.state.pagination.totalCount}
                        color="secondary"
                        size={paginationSizeResponsive()}
                        onChange={this.handlePageChange} />
                    }
                  </Box>
                </>}
            </Box>
          </Box>
          <footer className={classes.footer}>
            <Typography className={classes.footerText}>
              Guidelines for using NTRP (Bharat Kosh) Payment Gateway  <a href="#" className={classes.guideHypertext}>Guide</a>
            </Typography>
          </footer>
        </Box>
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = createStyles({
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "hidden",
  },
  headSection: {
    backgroundColor: "#FFEEDE",
    padding: "14px",
    display: "flex",
    justifyContent: "center",
    alignItem: "center",
    width: "100%",
    margin: "50px 0px"
  },
  headTitle: {
    fontSize: "24px",
    fontWeight: 300,
    fontFamily: 'Poppins'
  },
  pageContainer: {
    overflowX: "hidden",
    backgroundColor: "#ffd4ac",
  },
  formLabel: {
    color: "black",
    "&.Mui-focused": {
      color: "black",
    },
  },
  image: {
    objectFit: "contain",
    height: "100px",
    padding: "4px",
    '@media (max-width: 900px)': {
      height: "80px",
      padding: "10px",
    },
    '@media (max-width: 600px)': {
      height: "60px",
      padding: "5px",
    },
    '@media (max-width: 300px)': {
      height: "40px",
      padding: "4px",
    }
  },
  outerContainer: {
    backgroundColor: "#ffd4ac",
    padding: "20px 180px",
    fontFamily: "Poppins",
    '@media (max-width: 900px)': {
      padding: "20px 50px",
    },
    '@media (max-width: 600px)': {
      padding: "20px 30px",
    }
  },
  innerContainer: {
    backgroundColor: "white",
    borderRadius: "20px",
    boxShadow: "5px 10px 8px #888888",
  },
  buttonOutline: {
    backgroundColor: "white",
    color: "#894402",
    fontWeight: 600,
    borderRadius: "6px",
    border: "1px solid #894402",
    boxShadow: "0px 2px 4px 1px rgba(0,0,0,0.25)",
    textTransform: "none",
    fontSize: "24px",
    width: "250px",
    // margin: "100px auto 50px",

    '@media (max-width: 1600px)': {
      fontSize: "18px",
    },
    "&:hover": {
      backgroundColor: "white",
    },
    '&:disabled': {
      opacity: 0.5
    },
    '@media (max-width: 600px)': {
      width: "150px",
    },
  },
  button: {
    backgroundColor: "#8A4504",
    color: "white",
    fontWeight: 600,
    borderRadius: "6px",
    boxShadow: "0px 2px 4px 1px rgba(0,0,0,0.25)",
    textTransform: "none",
    fontSize: "24px",

    width: "248px",
    height: "100%",
    '@media (max-width: 1600px)': {
      fontSize: "18px",
    },
    "&:hover": {
      backgroundColor: "8A4504",
    },
    "&.Mui-disabled": {
      opacity: 0.5,
      color: "white"
    }
  },
  searchContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "45px",
    gap: "24px",
    width: "80%",
    '@media (max-width: 600px)': {
      flexDirection: "column",
      marginTop: "60px",
    },
  },
  labelContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
    gap: "24px",
    height: "45px",
    marginLeft: "-290px",
    '@media (max-width: 1200px)': {
      width: "80%",
      justifyContent: "flex-start",
      marginLeft: "unset"
    },
    '@media (max-width: 600px)': {
      flexDirection: "column",
      gap: "6px",
    },
  },
  input: {
    border: "1px solid #979797",
    borderRadius: "4px",
    width: "380px",
    "& .MuiOutlinedInput-input": {
      padding: "10px",
    },
    "& .MuiInputBase-input": {
      fontSize: "18px",

    },
    '& .MuiSvgIcon-root': {
      color: 'black',
    },
    '@media (max-width: 600px)': {
      width: "250px",
    },
  },
  footer: {
    backgroundColor: "#ffffff",
    padding: "10px",
    textAlign: "center",
    height: "70px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  guideHypertext: {
    color: "#FF0000",
    textDecoration: "none",
  },
  footerText: {
    margin: 0,
    fontSize: "20px",
    color: "#000000",
    fontWeight: 400,
    lineHeight: "45px",
    fontFamily: "Poppins-Medium",
    '@media (max-width: 600px)': {
      fontSize: "16px",

    }
  },
  imageContainer: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#ffd4ac",
    maxWidth: "100%",
    height: "120px",
    padding: "20px",
  },
  greenRadio: {
    '&.Mui-checked': {
      color: '#5EAE03',
    },
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "45px 24px 50px",

    gap: "16px",
    '@media (max-width: 600px)': {
      margin: "24px",
      flexDirection: "column",
    },
  },
  tableHeading: {
    background: "#FFE2C7",
    height: "96px",
    '@media (min-width: 1400px)': {
      height: "65px",
    },
    '& th': {
      fontWeight: 700,
      fontSize: "16px",
      color: "black",

    }
  },
  viewButton: {
    borderRadius: "12px",
    background: "#FFE2C7",
    textTransform: "none",
    color: "black",

  },
  tableRows: {
    padding: "20px",
    '&:hover': {
      background: "#D9D9D9",
      '& td': {
        fontWeight: 600,
      },
      '& $viewButton': {
        background: "#8A4504",
        color: "white",
      },
    },
    '& td': {
      fontSize: "16px",
      color: "black",

    }
  },
  noRecord: {
    color: "#F00",
    fontWeight: 700,

    fontSize: "16px",
  },
  paginationDropdown: {
    borderRadius: "12px",
    height: "35px",
    width: "80px",

  },
  dropdownContainer: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  bottomSection:{
    display:"flex",
    flexWrap: "wrap",
    alignItems:"center",
    paddingBlock:"60px",
    justifyContent:"center",
    gap:"20px"
  },
  pagination: {
    "& .MuiPagination-ul": {
      "& li:first-child": {
        "& button": {
          background: '#fff',
          color: '#8A4504',
          border:"solid 1px",
          borderRadius:"6px"
        },
      },
      "& li:last-child": {
        "& button": {
          background: '#fff',
          color: '#8A4504',
          border:"solid 1px",
          borderRadius:"6px"
        },
      },
      "& li": {
        "& button": {
          background: '#fff',
          color: '#8A4504'
        },
        "& .MuiPaginationItem-page.Mui-selected": {
          fontWeight:"700"
        },
      },
    },
  },
  giName:{
    "&:hover": {
      textDecoration:'underline',
    }
  }
});
const AdvancedSearchSection = withStyles(webStyles)(AdvancedSearch);
export { AdvancedSearchSection };
// Customizable Area End
