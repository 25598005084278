import React from 'react';
import { withStyles, Theme, createStyles, Button, TextField, Tabs, Tab } from '@material-ui/core';

export const fontFamilyMedium = "Poppins-Medium, sans-serif !important";
export const fontFamily = "Poppins, sans-serif !important"

interface StyledTabProps {
    label: string;
}
export const StyledPasswordButton = withStyles((theme: Theme) =>
    createStyles({
        root: {
            fontSize: 19,
            width: "200px",
            height: 40,
            borderRadius: 6,
            fontWeight: 500,
            textTransform: "capitalize",
            [theme.breakpoints.up('xl')]: {
                width: "293px",
                height: 54,

            }

        }
    }))(Button)

export const StyledTextFieldPassword = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "& .MuiInputBase-root": {
                width: "100%",
                height: "48px",
                fontFamily: "Poppins-Medium, sans-serif !important",
                maxWidth: "422px",
                [theme.breakpoints.down('sm')]: {
                    width: "100%",
                }
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#979797",
                borderWidth: "2px"
            },
            "& .MuiOutlinedInput-root:hover": {
                borderColor: "#979797",
            },
            "& .MuiSelect-select:focus": {
                backgroundColor: "white"
            },
            "& .MuiSvgIcon-root": {
                fontSize: "20px"
            }
        }
    }))(TextField);
export const StyledTabs =withStyles((theme: Theme) =>
createStyles({
    indicator:{
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    textTransform: 'none',
    height: "4px",
    '& > span': {
        maxWidth: "100%",
        width: '100%',
        backgroundColor: '#8A4504',
        borderRadius: '20px'
    },
},
root: {
    "& .MuiTabs-flexContainer": {
        height: "60px",
        justifyContent: "space-around",
        background: "#FFE2C7D9",
        [theme.breakpoints.down(1100)]:{
            height: "auto",
            justifyContent: "center",
            flexDirection:"column",
            alignItems:'center'
        }

    },

}
    
}))((props: any) => <Tabs {...props} disableripple="true" TabIndicatorProps={{ children: <span /> }} />);

export const StyledTab = withStyles((theme: Theme) =>
    createStyles({
        root: {
            textTransform: 'none',
            color: '#000000',
            fontWeight: 300,
            fontSize: 16,
            fontFamily: fontFamily,
            '&$selected': {
                color: '#000000',
                fontSize: 16,
                fontWeight: 500,
                fontFamily: fontFamily
            }
        },
    }),
)((props: StyledTabProps) => <Tab disableRipple {...props} />);