import React from "react";
// Customizable Area Start
import { warningIcon} from "../../landingpage/src/assets";
import { ipoImage ,logoImage} from "../../customform/src/assets";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
    OutlinedInput,
    TextField,
    Container,
    Typography,
    createStyles
} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from "@material-ui/styles";
// Customizable Area End
import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";
export class OrganisationDetails extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    // Customizable Area Start
    const countries = [
      { code: "AF", name: "Afghanistan" },
      { code: "AL", name: "Albania" },
      { code: "DZ", name: "Algeria" },
      { code: "AD", name: "Andorra" },
      { code: "AO", name: "Angola" },
      { code: "AG", name: "Antigua and Barbuda" },
      { code: "AR", name: "Argentina" },
      { code: "AM", name: "Armenia" },
      { code: "AU", name: "Australia" },
      { code: "AT", name: "Austria" },
      { code: "AZ", name: "Azerbaijan" },
      { code: "BS", name: "Bahamas" },
      { code: "BH", name: "Bahrain" },
      { code: "BD", name: "Bangladesh" },
      { code: "BB", name: "Barbados" },
      { code: "BY", name: "Belarus" },
      { code: "BE", name: "Belgium" },
      { code: "BZ", name: "Belize" },
      { code: "BJ", name: "Benin" },
      { code: "BT", name: "Bhutan" },
      { code: "BO", name: "Bolivia" },
      { code: "BA", name: "Bosnia and Herzegovina" },
      { code: "BW", name: "Botswana" },
      { code: "BR", name: "Brazil" },
      { code: "BN", name: "Brunei" },
      { code: "BG", name: "Bulgaria" },
      { code: "BF", name: "Burkina Faso" },
      { code: "BI", name: "Burundi" },
      { code: "CV", name: "Cabo Verde" },
      { code: "KH", name: "Cambodia" },
      { code: "CM", name: "Cameroon" },
      { code: "CA", name: "Canada" },
      { code: "CF", name: "Central African Republic" },
      { code: "TD", name: "Chad" },
      { code: "CL", name: "Chile" },
      { code: "CN", name: "China" },
      { code: "CO", name: "Colombia" },
      { code: "KM", name: "Comoros" },
      { code: "CG", name: "Congo" },
      { code: "CD", name: "Democratic Republic of the Congo" },
      { code: "CR", name: "Costa Rica" },
      { code: "CI", name: "Côte d'Ivoire" },
      { code: "HR", name: "Croatia" },
      { code: "CU", name: "Cuba" },
      { code: "CY", name: "Cyprus" },
      { code: "CZ", name: "Czech Republic" },
      { code: "DK", name: "Denmark" },
      { code: "DJ", name: "Djibouti" },
      { code: "DM", name: "Dominica" },
      { code: "DO", name: "Dominican Republic" },
      { code: "EC", name: "Ecuador" },
      { code: "EG", name: "Egypt" },
      { code: "SV", name: "El Salvador" },
      { code: "GQ", name: "Equatorial Guinea" },
      { code: "ER", name: "Eritrea" },
      { code: "EE", name: "Estonia" },
      { code: "ET", name: "Ethiopia" },
      { code: "FJ", name: "Fiji" },
      { code: "FI", name: "Finland" },
      { code: "FR", name: "France" },
      { code: "GA", name: "Gabon" },
      { code: "GM", name: "Gambia" },
      { code: "GE", name: "Georgia" },
      { code: "DE", name: "Germany" },
      { code: "GH", name: "Ghana" },
      { code: "GR", name: "Greece" },
      { code: "GD", name: "Grenada" },
      { code: "GT", name: "Guatemala" },
      { code: "GN", name: "Guinea" },
      { code: "GW", name: "Guinea-Bissau" },
      { code: "GY", name: "Guyana" },
      { code: "HT", name: "Haiti" },
      { code: "HN", name: "Honduras" },
      { code: "HU", name: "Hungary" },
      { code: "IS", name: "Iceland" },
      { code: "IN", name: "India" },
      { code: "ID", name: "Indonesia" },
      { code: "IR", name: "Iran" },
      { code: "IQ", name: "Iraq" },
      { code: "IE", name: "Ireland" },
      { code: "IL", name: "Israel" },
      { code: "IT", name: "Italy" },
      { code: "JM", name: "Jamaica" },
      { code: "JP", name: "Japan" },
      { code: "JO", name: "Jordan" },
      { code: "KZ", name: "Kazakhstan" },
      { code: "KE", name: "Kenya" },
      { code: "KI", name: "Kiribati" },
      { code: "KP", name: "North Korea" },
      { code: "KR", name: "South Korea" },
      { code: "KW", name: "Kuwait" },
      { code: "KG", name: "Kyrgyzstan" },
      { code: "LA", name: "Laos" },
      { code: "LV", name: "Latvia" },
      { code: "LB", name: "Lebanon" },
      { code: "LS", name: "Lesotho" },
      { code: "LR", name: "Liberia" },
      { code: "LY", name: "Libya" },
      { code: "LI", name: "Liechtenstein" },
      { code: "LT", name: "Lithuania" },
      { code: "LU", name: "Luxembourg" },
      { code: "MK", name: "North Macedonia" },
      { code: "MG", name: "Madagascar" },
      { code: "MW", name: "Malawi" },
      { code: "MY", name: "Malaysia" },
      { code: "MV", name: "Maldives" },
      { code: "ML", name: "Mali" },
      { code: "MT", name: "Malta" },
      { code: "MH", name: "Marshall Islands" },
      { code: "MR", name: "Mauritania" },
      { code: "MU", name: "Mauritius" },
      { code: "MX", name: "Mexico" },
      { code: "FM", name: "Micronesia" },
      { code: "MD", name: "Moldova" },
      { code: "MC", name: "Monaco" },
      { code: "MN", name: "Mongolia" },
      { code: "ME", name: "Montenegro" },
      { code: "MA", name: "Morocco" },
      { code: "MZ", name: "Mozambique" },
      { code: "MM", name: "Myanmar" },
      { code: "NA", name: "Namibia" },
      { code: "NR", name: "Nauru" },
      { code: "NP", name: "Nepal" },
      { code: "NL", name: "Netherlands" },
      { code: "NZ", name: "New Zealand" },
      { code: "NI", name: "Nicaragua" },
      { code: "NE", name: "Niger" },
      { code: "NG", name: "Nigeria" },
      { code: "NO", name: "Norway" },
      { code: "OM", name: "Oman" },
      { code: "PK", name: "Pakistan" },
      { code: "PW", name: "Palau" },
      { code: "PA", name: "Panama" },
      { code: "PG", name: "Papua New Guinea" },
      { code: "PY", name: "Paraguay" },
      { code: "PE", name: "Peru" },
      { code: "PH", name: "Philippines" },
      { code: "PL", name: "Poland" },
      { code: "PT", name: "Portugal" },
      { code: "QA", name: "Qatar" },
      { code: "RO", name: "Romania" },
      { code: "RU", name: "Russia" },
      { code: "RW", name: "Rwanda" },
      { code: "KN", name: "Saint Kitts and Nevis" },
      { code: "LC", name: "Saint Lucia" },
      { code: "VC", name: "Saint Vincent and the Grenadines" },
      { code: "WS", name: "Samoa" },
      { code: "SM", name: "San Marino" },
      { code: "ST", name: "Sao Tome and Principe" },
      { code: "SA", name: "Saudi Arabia" },
      { code: "SN", name: "Senegal" },
      { code: "RS", name: "Serbia" },
      { code: "SC", name: "Seychelles" },
      { code: "SL", name: "Sierra Leone" },
      { code: "SG", name: "Singapore" },
      { code: "SK", name: "Slovakia" },
      { code: "SI", name: "Slovenia" },
      { code: "SB", name: "Solomon Islands" },
      { code: "SO", name: "Somalia" },
      { code: "ZA", name: "South Africa" },
      { code: "SS", name: "South Sudan" },
      { code: "ES", name: "Spain" },
      { code: "LK", name: "Sri Lanka" },
      { code: "SD", name: "Sudan" },
      { code: "SR", name: "Suriname" },
      { code: "SZ", name: "Eswatini" },
      { code: "SE", name: "Sweden" },
      { code: "CH", name: "Switzerland" },
      { code: "SY", name: "Syria" },
      { code: "TW", name: "Taiwan" },
      { code: "TJ", name: "Tajikistan" },
      { code: "TZ", name: "Tanzania" },
      { code: "TH", name: "Thailand" },
      { code: "TL", name: "Timor-Leste" },
      { code: "TG", name: "Togo" },
      { code: "TO", name: "Tonga" },
      { code: "TT", name: "Trinidad and Tobago" },
      { code: "TN", name: "Tunisia" },
      { code: "TR", name: "Turkey" },
      { code: "TM", name: "Turkmenistan" },
      { code: "TV", name: "Tuvalu" },
      { code: "UG", name: "Uganda" },
      { code: "UA", name: "Ukraine" },
      { code: "AE", name: "United Arab Emirates" },
      { code: "GB", name: "United Kingdom" },
      { code: "US", name: "United States" },
      { code: "UY", name: "Uruguay" },
      { code: "UZ", name: "Uzbekistan" },
      { code: "VU", name: "Vanuatu" },
      { code: "VA", name: "Vatican City" },
      { code: "VE", name: "Venezuela" },
      { code: "VN", name: "Vietnam" },
      { code: "YE", name: "Yemen" },
      { code: "ZM", name: "Zambia" },
      { code: "ZW", name: "Zimbabwe" }
    ];
    // Customizable Area End

    return (
        // Customizable Area Start
        <div className={this.props.classes.container}>
            <div className={this.props.classes.headerOrg}>
                <img src={logoImage} alt="Logo" className={this.props.classes.logoOrg} />
                <img src={ipoImage} alt="Ipo-Logo" className={this.props.classes.logoOrg} />
            </div>
            <div className={this.props.classes.card}>
            <div className={this.props.classes.topLine}>
                <button className={this.props.classes.button}> New User Registration Form </button>
            </div>
            <div className={this.props.classes.content}>
                <label className={this.props.classes.label}>Organisation Details</label>
                <label className={this.props.classes.subLabel}>(Association/Organisation)</label>
            </div>
            <Container className={this.props.classes.cardContainer}>
                <div className={this.props.classes.customElement}>
                    <Typography variant="subtitle1" className={this.props.classes.formLabelUserDetailsUT}>
                        {"Organisation Name"}
                        <span className={this.props.classes.colorStar}>*</span>:
                    </Typography>
                    <div className={this.props.classes.flexDiv}>
                        <OutlinedInput
                            type="text"
                            className={this.props.classes.formInputUserDetailsA}
                            id="OrganisationName"
                            onChange={e => {
                                this.setState({
                                    orgName: e.target.value,
                                    orgNameError: ""
                                });
                            }}
                        />
                        {this.state.orgNameError && (
                            <div className={this.props.classes.textUserDetails}>
                                <img src={warningIcon} alt="alertIcon" className={this.props.classes.waringIcon} />
                                <span className={this.props.classes.errorMessageTextUserDetails}>
                                    {this.state.orgNameError}
                                </span>
                            </div>
                        )}
                    </div>
                </div>

                <div className={this.props.classes.customElement}>
                    <Typography variant="subtitle1" className={this.props.classes.formLabelUserDetailsUT}>
                        {"Organisation Address"}
                        <span className={this.props.classes.colorStar}>*</span>:
                    </Typography>
                    <div className={this.props.classes.flexDiv}>
                        <OutlinedInput
                            type="text"
                            className={this.props.classes.formInputUserDetailsA}
                            id="OrganisationAddress"
                            onChange={e => {
                                this.setState({
                                    orgAddress: e.target.value,
                                    orgAddressError: ""
                                });
                            }}
                        />
                        {this.state.orgAddressError && (
                            <div className={this.props.classes.textUserDetails}>
                                <img src={warningIcon} alt="alertIcon" className={this.props.classes.waringIcon} />
                                <span className={this.props.classes.errorMessageTextUserDetails}>
                                    {this.state.orgAddressError}
                                </span>
                            </div>
                        )}
                    </div>
                </div>

                <div className={this.props.classes.pincodeDiv}>
                    <Typography variant="subtitle1" className={this.props.classes.formLabelUserDetailsU}>
                        {"Town/City/District"}
                        <span className={this.props.classes.colorStar}>*</span>:
                    </Typography>
                    <OutlinedInput
                        type="text"
                        className={this.props.classes.orgDetails}
                        id="Town"
                        onChange={e => {
                            this.setState({
                                orgTown: e.target.value,
                                orgTownError: ""
                            });
                        }}
                    />
                    <Typography
                        variant="subtitle1"
                        className={this.props.classes.formLabelUserDetailsUD}
                    >
                        {"PIN Code"}
                        <span className={this.props.classes.colorStar}>*</span>:
                    </Typography>
                    <OutlinedInput
                        type="text"
                        className={this.props.classes.formInputUserDetailsA}
                        id="PINCode"
                        onChange={e => {
                            this.setState({
                                orgPinCode: e.target.value,
                                orgPinCodeError: ""
                            });
                        }}
                    />
                </div>

                
                <div className={this.props.classes.customElement}>
                    <Typography variant="subtitle1" className={this.props.classes.formLabelUserDetails} />
                    <Typography variant="caption" className={this.props.classes.flexDiv}>
                        {this.state.orgTownError && (
                            <div className={this.props.classes.textUserDetails}>
                                <img src={warningIcon} alt="alertIcon" className={this.props.classes.waringIcon} />
                                <span className={this.props.classes.errorMessageTextUserDetails}>
                                    {this.state.orgTownError}
                                </span>
                            </div>
                        )}
                    </Typography>
                    <Typography
                        variant="caption"
                        className={this.props.classes.flexData}
                    >
                        {this.state.orgPinCodeError && (
                            <div className={this.props.classes.textUserDetails}>
                                <img src={warningIcon} alt="alertIcon" className={this.props.classes.waringIcon} />
                                <span className={this.props.classes.errorMessageTextUserDetails}>
                                    {this.state.orgPinCodeError}
                                </span>
                            </div>
                        )}
                    </Typography>
                </div>

                <div className={this.props.classes.labeltext}>
                    <Typography variant="subtitle1" className={this.props.classes.formLabelUserDetailsUT}>
                        {"Country "}
                        <span className={this.props.classes.colorStar}>*</span>:
                    </Typography>
                    <div className={this.props.classes.flexDiv}>
                        <Autocomplete
                            id="Country"
                            options={countries}
                            className={this.props.classes.formAutocomplete}
                            getOptionLabel={option => option.name}
                            popupIcon={<ExpandMoreIcon />}
                            onChange={(e, value) => {
                                this.setState({
                                    orgCountry: value?.code,
                                    orgCountryName:value?.name,
                                    orgCountryError: ""
                                });
                            }}
                            renderOption={(option) => (
                                <div className={this.props.classes.optionContainerORG}>
                                  {option.name}
                                </div>
                            )}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    placeholder="Select Country"
                                    variant="outlined"
                                    id="renderInput"
                                    inputProps={{
                                        ...params.inputProps
                                    }}
                                />
                            )}
                        />
                        {this.state.orgCountryError && (
                            <div className={this.props.classes.textUserDetails}>
                                <img src={warningIcon} alt="alertIcon" className={this.props.classes.waringIcon} />
                                <span className={this.props.classes.errorMessageTextUserDetails}>
                                    {this.state.orgCountryError}
                                </span>
                            </div>
                        )}
                    </div>
                </div>

                <div className={this.props.classes.labeltext}>
                    <Typography
                        variant="subtitle1"
                        className={this.props.classes.formLabelUserDetailsUT}
                    >
                        {"Telephone No. (STD/ISD)"}{" "}
                        <span className={this.props.classes.colorStar}>*</span>:
                    </Typography>
                    <div className={this.props.classes.flexDiv}>
                        <OutlinedInput
                            type="text"
                            className={this.props.classes.formAuto}
                            id="Telephone"
                            onChange={e => {
                                this.setState({
                                    orgTelephoneNo: e.target.value,
                                    orgTelephoneNoError: ""
                                });
                            }}
                        />
                        {this.state.orgTelephoneNoError && (
                            <div className={this.props.classes.textUserDetails}>
                                <img src={warningIcon} alt="alertIcon" className={this.props.classes.waringIcon} />
                                <span className={this.props.classes.errorMessageTextUserDetails}>
                                    {this.state.orgTelephoneNoError}
                                </span>
                            </div>
                        )}
                    </div>
                </div>

                <div className={this.props.classes.labeltext}>
                    <div className={this.props.classes.formLabelUserDetails}>
                        <Typography
                            variant="subtitle1"
                            className={this.props.classes.formLabelUserDetailsU}
                        >
                            {"Alternate Telephone No.(STD/ISD)"}
                        </Typography>
                    </div>
                    <div className={this.props.classes.flexDiv}>
                        <OutlinedInput
                            type="text"
                            className={this.props.classes.formAuto}
                            id="Alternate"
                            onChange={e => {
                                this.setState({
                                    orgAlternateTelephoneNo: e.target.value,
                                    orgAlternateTelephoneNoError: ""
                                });
                            }}
                        />
                        {this.state.orgAlternateTelephoneNoError && (
                            <div className={this.props.classes.textUserDetails}>
                                <img src={warningIcon} alt="alertIcon" className={this.props.classes.waringIcon} />
                                <span className={this.props.classes.errorMessageTextUserDetails}>
                                    {this.state.orgAlternateTelephoneNoError}
                                </span>
                            </div>
                        )}
                    </div>
                </div>

                <div className={this.props.classes.pincodeDiv}>
                <Typography variant="subtitle1" className={this.props.classes.formLabelUserDetailsU}>
                    {"Email ID"}
                    <span className={this.props.classes.colorStar}>*</span>:
                </Typography>
                <OutlinedInput
                    type="text"
                    className={this.props.classes.orgDetails}
                    id="emailID"
                    onChange={e => {
                    this.setState({
                        orgEmailID: e.target.value,
                        orgEmailIDError: ""
                    });
                    }}
                />
                <Typography
                    variant="subtitle1"
                    className={this.props.classes.formLabelUserDetailsUD}
                >
                    {"Fax Number:"}
                </Typography>
                <OutlinedInput
                    type="text"
                    className={this.props.classes.fromOrg}
                    id="FaxNumber"
                    onChange={e => {
                    this.setState({
                        orgFaxNumber: e.target.value,
                        orgFaxNumberError: ""
                    });
                    }}
                />
                </div>
                <div className={this.props.classes.customElement}>
                <Typography variant="subtitle1" className={this.props.classes.formLabelUserDetails} />
                <Typography variant="caption" className={this.props.classes.flexDiv}>
                    {this.state.orgEmailIDError && (
                    <div className={this.props.classes.textUserDetails}>
                        <img src={warningIcon} alt="alertIcon" className={this.props.classes.waringIcon}/>
                        <span className={this.props.classes.errorMessageTextUserDetails}>
                        {this.state.orgEmailIDError}
                        </span>
                    </div>
                    )}
                </Typography>
                <Typography variant="caption" className={this.props.classes.flexDiv} />
                </div>
            </Container>

            <div className={this.props.classes.buttonsContainerDS}>
                <button
                className={this.props.classes.actionButtonBack}
                onClick={() => {
                    this.props.navigation.navigate("SelectTypeOfApplicant");
                }}
                >
                Back
                </button>
                <button
                className={this.state.orgNextButtonDisable ? this.props.classes.actionButtonDisable:this.props.classes.actionButton}
                id="submitForm"
                disabled={this.state.orgNextButtonDisable}
                onClick={() => {
                    this.onSubmitForm();
                }}
                >
                Next
                </button>
            </div>
            <div className={this.props.classes.buttonsContainerDS}>
                {this.state.apiError && (
                <div className={this.props.classes.textUserDetails}>
                    <img src={warningIcon} alt="alertIcon" className={this.props.classes.waringIcon}/>
                    <span className={this.props.classes.errorMessageTextUserDetails}>
                    {this.state.apiError}
                    </span>
                </div>
                )}
            </div>
            </div>
            <footer className={this.props.classes.footer}>
            <p className={this.props.classes.footerText}>
    Guidelines for using NTRP (Bharat Kosh) Payment Gateway <span className={this.props.classes.guidecolor}>Guide</span>
    </p>
            </footer>
        </div>
        // Customizable Area End

    );
  }
}
// Customizable Area Start
const style=createStyles({
    container: {
        backgroundColor: "#ffd4ac",
        height: "auto",
        overflowX: "hidden"
    },
    headerOrg: {
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "#ffd4ac",
        maxWidth: "100%",
        height: "100px",
        marginBottom: "50px"
      },
    logoOrg: {
        objectFit: "contain",
        height: "92px",
        padding: "20px",
        '@media (max-width: 900px)': {
          height: "80px",
          padding: "10px",
        },
        '@media (max-width: 600px)': {
          height: "60px",
          padding: "5px",
        },
        '@media (max-width: 300px)': {
          height: "40px",
          padding: "4px",
        }
      },
    card: {
        width: "75%",
        height: "auto",
        minHeight: "600px",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        backgroundColor: "#ffffff",
        borderRadius: "10px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        margin: "0 auto",
        padding: "20px",
        marginBottom: "40px",
        paddingTop: "20px",
      },
      topLine: {
        width: "100%",
        height: "1px",
        backgroundColor: "#ccc",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        top: "0",
      },
      button: {
        padding: "5px 10px",
        fontSize: "30px",
        fontWeight: 400,
        border: "none",
        width: "600px",
        height: "70px",
        backgroundColor: "#894402",
        color: "#ffffff",
        borderRadius: "5px",
        fontFamily: "Poppins-Medium, sans-serif",
        '@media (max-width: 1200px)': {
            width:"60%",
            fontSize: "25px",
            height: "auto",
          }
      },
      content: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "50px"
    },
    subLabel: {
        fontWeight: 400,
        fontSize: "24px",
        fontFamily: "Poppins, sans-serif",
        color: "#000000",
      },
      guidecolor: {
        color: "#FF0000"
      },
      footerText: {
        margin: "0" /* Remove default margins for the paragraph */,
        fontSize: "20px",
        color: "#000000",
        fontWeight: 400,
        lineHeight: "45px",
        fontFamily: "Poppins-Medium, sans-serif"
      },
      footer: {
        backgroundColor: "#ffffff",
        padding: "10px",
        height: "70px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      },
    label: {
        fontWeight: 500,
        fontSize: "34px",
        fontFamily: "Poppins, sans-serif",
        color: "#000000",
        marginBottom: "15px"
    },
    cardContainer: {
        marginTop: "4rem",
        marginBottom: "50px"
      },
    customElement: {
        marginBottom: "1rem",
        display: "flex",
        '@media (max-width: 1200px)': {
            flexDirection: "column",
        }
      },
      formLabelUserDetailsUT: {
        fontSize: "18px",
        fontWeight: 400,
        color: "#000000",
        fontFamily: "Poppins, sans-serif",
        alignSelf: "center",
        flex: "0 0 250px" ,
        '@media (max-width: 1200px)': {
            flex: "0 0 10px" ,
            display:"flex",
            alignSelf: "flex-start",
          }
      },
      formLabelUserDetailsU: {
        fontSize: "18px",
        fontWeight: 400,
        color: "#000000",
        fontFamily: "Poppins, sans-serif",
        alignSelf: "center",
        flex: "0 0 250px" ,
      },
      formLabelUserDetailsUD: {
        fontSize: "18px",
        fontWeight: 400,
        color: "#000000",
        fontFamily: "Poppins, sans-serif",
        alignSelf: "center",
        flex: "0 0 120px", 
        marginLeft: "20px",
        '@media (max-width: 1200px)': {
          marginLeft: "1px",
          flex: "0 0 250px", 
        }
      },
      formLabelUserDetailsfax: {
        fontSize: "18px",
        fontWeight: 400,
        color: "#000000",
        fontFamily: "Poppins, sans-serif",
        alignSelf: "center",
        flex: "0 0 100px",
        marginLeft: "20px",
        marginRight: "15px",
        '@media (max-width: 1200px)': {
          marginLeft: "1px",
        }
      },
      formLabelUserDetails: {
        flex: "0 0 250px" ,
        '@media (max-width: 1200px)': {
            flex: "0 0 20px" ,
        }
      },
      colorStar: {
        color: "red"
      },
      flexDiv: {
          flex: "1",
          '@media (max-width: 1200px)': {
            display: "flex",
            flex:"unset",
            alignItems: "center",
            flexWrap: "wrap",
        }
      },
      optionContainerORG: {
        fontSize: '16px',
        color: '#000000',
        fontFamily: 'Poppins, sans-serif',
      },
      pincodeDiv: {
        display: "flex",
        alignItems: "center",
        '@media (max-width: 1200px)': {
          flexWrap: "wrap",
        }
      },
      formInputUserDetailsA: {
        width: "100%",
        height: "53px",
        padding: "10px",
        fontSize: "16px",
        borderRadius: "5px",
        marginBottom: "8px",
        fontFamily: "Poppins, sans-serif",
        color: "#000000"
      },
      formEmailDetails: {
        width: "100%",
        height: "53px",
        padding: "10px",
        fontSize: "16px",
        border: "1.31px solid #979797",
        borderRadius: "5px",
        marginBottom: "8px",
        fontFamily: "Poppins, sans-serif",
        color: "#000000",
        '@media (max-width: 1200px)': {
          minWidth: "900px",
        }
      },
      UserDetailsNote: {
        fontFamily: "Poppins, sans-serif",
        fontWeight: 400,
        fontSize: "13px",
        color: "#737373"
      },
      errorMessageTextUserDetails: {
        position: "relative",
        fontFamily: "Poppins-Medium, sans-serif",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "12px",
        lineHeight: "18px",
        color: "#FF0000",
        marginTop: "5px",
        left: "0"
      },
      textUserDetails: {
        textAlign: "left" ,
        display: "flex",
        alignItems: "center"
      },
      labeltext:{
        display: "flex",
        marginBottom: "1rem",
         '@media (max-width: 1200px)': {
            flexDirection: "column"
          }
      } ,
      formInputUserDetailsH: {
        width: "100%",
        height: "53px",
        padding: "10px",
        '@media (max-width: 1200px)': {
          height: "10px",
        }
      },
      actionButton: {
        padding: "10px 20px",
        fontSize: "18px",
        fontWeight: "bold",
        borderRadius: "5px",
        margin: "0 10px" /* Add some margin between the buttons */,
        cursor: "pointer",
        minWidth: "250px" /* Minimum width for the button */,
        height: "54px" /* Height for the button */,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textTransform: "none",
        fontFamily: 'Poppins, sans-serif',
        backgroundColor: "#894402", // Background color
        color: "#ffffff", // Text color
        border: "#894402", // Remove border
        '@media (max-width: 1200px)': {
            width: "25%"
        }
      },
      waringIcon: {
        width: "20px",
        height: "20px",
        marginRight: "4px"
      },
      buttonsContainerDS: {
        display: "flex",
        marginTop: "auto",
        marginBottom: "40px",
        justifyContent: "center",
        '@media (max-width: 1200px)': {
          flexDirection: "column",
          alignItems: "center",
          marginBottom: "10px",
        }
      },
      actionButtonBack: {
        padding: "10px 20px",
        fontSize: "18px",
        fontWeight: "bold",
        borderRadius: "5px",
        margin: "0 10px" /* Add some margin between the buttons */,
        cursor: "pointer",
        minWidth: "250px" /* Minimum width for the button */,
        height: "54px" /* Height for the button */,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textTransform: "none",
        fontFamily: 'Poppins, sans-serif',
        backgroundColor: "#ffffff", // Background color
        color: "#894402", // Text color
        border: "1px solid #894402", // Remove border
        marginRight: "10px",
        '@media (max-width: 1200px)': {
          marginBottom:"20px",
          width: "25%"
        }
      },
      actionButtonDisable: {
        padding: "10px 20px",
        fontSize: "18px",
        fontWeight: "bold",
        borderRadius: "5px",
        margin: "0 10px" /* Add some margin between the buttons */,
        cursor: "pointer",
        minWidth: "250px" /* Minimum width for the button */,
        height: "54px" /* Height for the button */,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textTransform: "none",
        fontFamily: "Poppins, sans-serif",
        border: "1px solid #b38961",
        backgroundColor: "#b38961", // Background color
        color: "#ffffff", // Text color
        '@media (max-width: 1200px)': {
          marginBottom:"20px",
          width: "25%"
        }
      },
      flexData:{ flex: "1", marginLeft: "290px" },
      formAutocomplete:{
        height: '54px',
        fontSize: '16px',
        borderRadius: '5px',
        marginRight: '30px',
        fontFamily: 'Poppins, sans-serif',
        color: '#000000',
        width: '450px',
        marginBottom: '8px',
        '@media (max-width: 1200px)': {
            width: '100%',
            marginRight: 'unset',
        },
        '& .MuiAutocomplete-input': {
          fontSize: '16px',
          color: '#000000',
          fontFamily: "Poppins, sans-serif"
        },
        '& .MuiAutocomplete-root .MuiAutocomplete-option': {
          fontSize: '16px',
          color: '#000000',
          fontFamily: "Poppins, sans-serif"
        }
      },
      formAuto:{
        height: '54px',
        fontSize: '16px',
        borderRadius: '5px',
        marginRight: '30px',
        fontFamily: 'Poppins, sans-serif',
        color: '#000000',
        /* width: 'calc(53% - 10px)', */
        minWidth: '450px',
        marginBottom: '8px',
        '@media (max-width: 1200px)': {
            minWidth: "100%",
          }
      },
      fromOrg:{
        width: '60%',
        height: '53px',
        padding: '10px',
        fontSize: '16px',
        borderRadius: '5px',
        marginBottom: '8px',
        fontFamily: 'Poppins, sans-serif',
        '@media (max-width: 1200px)': {
            width: '100%',
        }
      },
      orgDetails:{
        height: '53px',
        minWidth: '450px',
        padding: '10px',
        fontSize: '16px',
        borderRadius: '5px',
        marginBottom: '8px',
        fontFamily: 'Poppins, sans-serif',
        color: '#000000',
        '@media (max-width: 1200px)': {
            width: '100%',
            minWidth: "unset",
        }
      }
})
export default withStyles(style)(OrganisationDetails)
// Customizable Area End

