import React from "react";
// Customizable Area Start

import Slider from "react-slick";
import { Headerlogo } from "../src/assets";
import { ipoImage } from "../../customform/src/assets";
import LinkList from "../../../components/src/LinkList";
import AccountLogin from "../../email-account-login/src/AccountLogin.web";
import { withStyles,createStyles } from "@material-ui/core/styles";
// Customizable Area End
import LandingPageController, { Props } from "./LandingPageController";

export class LandingPageweb extends LandingPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true, // Enable auto-scrolling
            autoplaySpeed: 3000, // Set the duration between each slide (in milliseconds)
            arrows: false
          };
        // Customizable Area End
        return (
            // Customizable Area Start
      <div className={this.props.classes.appContainerLP}>
        <div className={this.props.classes.headerLandingPage}>
          <img src={Headerlogo} alt="Logo" className={this.props.classes.logoLandingPage} />
          <img src={ipoImage} alt="Ipo-Logo" className={this.props.classes.logoLandingPage} />
        </div>
        <div className={this.props.classes.cardContainer}>
          <div className={this.props.classes.label}>
            <span className={this.props.classes.mainLabel}>
              Online Filing of Geographical Indications
            </span>
          </div>
          <div className={this.props.classes.sliderContainermain}>
            <AccountLogin navigation={this.props.navigation} id=""/>
            <div className={this.props.classes.separator} />
            <div className={this.props.classes.rightPart}>
              <Slider {...settings}>
                {this.state.guideData.map((image:any, index:any) => (
                  <div key={index} className={this.props.classes.sliderContainer}>
                    <img
                      src={image.attributes.banner_image_url}
                      alt={image.attributes.id}
                      className={this.props.classes.slideImage}
                    />
                    <div className={this.props.classes.imageDescription}>
                      <span className={this.props.classes.guideLabelText}>{image.attributes.title}</span>
                    </div>
                    <div className={this.props.classes.radioButtons}>
                      {this.state.guideData.map((_:any, i:any) => (
                        <div
                          key={i}
                          className={i === index ? this.props.classes.radioButtonSelected :  this.props.classes.radioButton}
                        />
                      ))}
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
        <LinkList/>
        <footer className={this.props.classes.customFooter}>
          <p className={this.props.classes.customFooterText}>
            Guidelines for using NTRP (Bharat Kosh) Payment Gateway <span className={this.props.classes.startColor}>Guide</span>
          </p>
        </footer>
      </div>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyles = () =>
createStyles({
  appContainerLP: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#ffd4ac',
    height: 'auto',
    overflowX: 'hidden',
    minHeight: '100vh', 
  },
  headerLandingPage: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#ffd4ac",
    maxWidth: "100%",
    height: "100px",
    marginBottom: "60px"
  },
  logoLandingPage: {
    objectFit: "contain",
    height: "92px",
    padding: "20px",
    '@media (max-width: 900px)': {
      height: "80px",
      padding: "10px",
    },
    '@media (max-width: 600px)': {
      height: "60px",
      padding: "5px",
    },
    '@media (max-width: 300px)': {
      height: "40px",
      padding: "4px",
    }
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    width: '75%',
    margin: '0 auto',
    padding: '20px',
    marginBottom: '40px',
  },
  label: {
    fontSize: '20px',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Poppins-Medium, sans-serif',
  },
  mainLabel: {
    fontFamily: 'Poppins-Medium, sans-serif',
    fontSize: '33px',
    color: '#000000',
    fontWeight: 500,
    lineHeight: '52px',
    textAlign: 'center'
  },
  sliderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    marginTop: '25px'
  },
  sliderContainermain: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    marginLeft: '50px',
    marginTop: '25px'
  },
  separator: {
    width: '2px',
    backgroundColor: '#e7e4e4',
    margin: '10px 20px', // Adjust the margin as needed
    alignSelf: 'stretch'
  },
  rightPart: {
    width: '50%',
    marginTop: '0px'
  },
  slideImage: {
    height: '330px',
    display: 'block', /* Remove any unwanted whitespace below the image */
    margin: '0 auto', /* Center the image horizontally within its container */
    width: '65%', /* Ensure the image doesn't exceed the container's width */
    marginTop: '5px'
  },
  imageDescription: {
    height: '3em',
    overflow: 'hidden',
    marginTop: '25px',
    marginBottom: '8px',
  },
  guideLabelText: {
    fontFamily: 'Poppins-Medium, sans-serif', 
    color: '#000000',
    fontWeight: 600,
    fontSize: '18px' ,
    lineHeight: '1.1'
  },
  customFooter: {
    backgroundColor: '#ffffff',
    padding: '10px',
    textAlign: 'center',
    height: '70px', // Note: Using is discouraged and should be avoided if possible.
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  customFooterText: {
    margin: '0',
    fontSize: '20px', // Note: Using is discouraged and should be avoided if possible.
    color: '#000000',
    fontWeight: 400,
    lineHeight: '45px', // Note: Using is discouraged and should be avoided if possible.
    fontFamily: 'Poppins-Medium, sans-serif', // Note: Using is discouraged and should be avoided if possible.
  },
  startColor: {
    color: '#FF0000',
  },
  radioButtons: {
    marginTop: '20px'
  },
  radioButton: {
    width: '18px',
    height: '18px',
    borderRadius: '50%',
    backgroundColor: '#FFFFFF',
    margin: '5px',
    border: '1px solid #7a1c1c',
    display: 'inline-block',
  },
  radioButtonSelected:{
    width: '18px',
    height: '18px',
    borderRadius: '50%',
    margin: '5px',
    border: '1px solid #7a1c1c',
    display: 'inline-block',
    backgroundColor: '#7a1c1c'
  }
})
export default withStyles(webStyles)(LandingPageweb)
// Customizable Area End
