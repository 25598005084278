import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { errorLogo } from '../../blocks/settings2/src/assets';


interface Props {
    errorName?: string
}

const ErrorMessage = (props: Props) => {

    return (
        <>
            {props.errorName && <Grid item xs={12}>
                <Grid container direction='row' alignItems='center' spacing={1} >
                    <Grid item xs={1} >
                        <img src={errorLogo} width={25} height={25} style={webStyle.errorIcon} />
                    </Grid>
                    <Grid item xs={11}>
                        <Typography component={'div'} style={webStyle.errorText}>{props.errorName}</Typography>
                    </Grid>

                </Grid>

            </Grid>}
        </>
    );
};

const webStyle = {
    errorText: {
        color: "#FF0000",
        fontSize: "14px",
        fontFamily: "Poppins-Medium, sans-serif !important",
        fontWeight: 400
    },
    errorIcon: {
        width: "21px",
        height: "19px"
    },
}



export default ErrorMessage;
