import React, { Component } from "react";
import {
    withStyles,
    createStyles,
    WithStyles,
    Theme,
} from "@material-ui/core/styles";
import {
    AppBar,
    Toolbar,
    Tabs,
    Tab,
    Button,
    Divider,
    Typography,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    InputAdornment,
} from "@material-ui/core";
const ProfileIcon = require("./view_logout.png")
const ProfileIconView = require("./view_profile.png")
const LogoutIcon = require("./viewlogout.png")
const NotificationIcon = require("./view_shape.png")

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import LanguageIcon from '@material-ui/icons/Translate';
import { mapPropsStreamWithConfig } from "recompose";

const styles = (theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        appBar: {
            backgroundColor: "#894402",
            paddingTop: "10px",
            borderRadius: "10px 10px 0px 0px",
            alignItems: "between",
        },
        toolBar: {
            display: "flex",
            justifyContent: "space-between",
            flexWrap:"wrap",
            minHeight: "0px",
            alignItems: "end"
        },
        tabs: {
            fontSize: "24px",
            '& .MuiTab-root': {
                opacity: 1, 
                color:"white",
                '&.Mui-selected': {
                  opacity: 1,
                },
              },
        },
        tab: {
            fontSize: "20px",
            fontWeight: 500,
            minWidth: "0px",
            fontFamily: "Poppins, sans-serif",
            padding: "0px",
            minHeight: "0px",
            textTransform: "none",
            lineHeight: "32px"
        },
        selectedTab: {
            fontSize: "20px",
            fontWeight: 700,
            minWidth: "0px",
            fontFamily: "Poppins, sans-serif",
            padding: "0px",
            minHeight: "0px",
            textTransform: "none",
            lineHeight: "32px"
        },
        divider: {
            background: "white",
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
        },
        button: {
            color: "white",
            fontSize: "24px",
            fontWeight: 500,
            borderColor: "white",
        },
        icon: {
            width: "20px",
            height: "20px"
        },
        alertIcon: {
            width: "25px",
            height: "28px",
            marginBottom:"13px"
        },
        profileIcon:{
            width: "37px",
            height: "37px",
            marginBottom:"1px"
        },
        buttonText: {
            textTransform: "capitalize",
            fontSize: "12px",
            fontFamily: "Poppins, sans-serif",
        },
        buttonSelected:{
            fontWeight: 900,
            textTransform: "capitalize",
            fontSize: "12px",
            fontFamily: "Poppins-Medium, sans-serif",
        },
        indicator: {
            height: "4px",
            backgroundColor: "white",
            bottom: "0",
            borderRadius: "100px",
        },
        iconButton: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: "white",
        },
        circle: {
            border: "1px solid white",
            borderRadius: "100px",
            width: "30px",
            height: "30px",
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        },
        circleAlert: {
            borderRadius: "100px",
            width: "40px",
            height: "40px",
            margin: "auto",
            marginBottom:"9px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        },
        buttonContainer: {
            display: "flex",
            marginTop:"4px"
        },
        select: {
            border: "1px solid white",
            borderRadius: "999px",
            padding: "10px",
            marginTop: "5px",
            color: "white",
            fontSize: "18px",
            fontFamily: "Poppins, sans-serif",
            fontWeight: 600,
            width: "95px",
            height: "40px",
            "& .MuiSelect-icon": {
                top: "calc(50% - 12px)",
                color: "white"
            },
            "& .MuiSelect-select":{
                padding:"0px"
            }
        },
        // Responsive Styles
        responsiveTabs: {
            fontSize: "16px", // Adjust font size for smaller screens
        },
        responsiveButton: {
            fontSize: "16px", // Adjust font size for smaller screens
        },
        responsiveButtonActive: {
            fontSize: "16px", // Adjust font size for smaller screens
            borderRadius: 0,
            color: "#FFFFFF",
            fontWeight: 700,
            borderBottom: "solid #FFFFFF"
        },
        flexContainer:{
            flexWrap:"wrap"
        }
    });

interface MyNavbarProps extends WithStyles<typeof styles> {
    selectedTab: number;
    alertIcon?: boolean;
    profileView?:boolean;
    navigation: {
        navigate: (path:string) => void 
    }
}

class MyNavbar extends Component<MyNavbarProps> {
    render() {
        const { classes, selectedTab,alertIcon,navigation,profileView } = this.props;

        return (
            <div className={classes.root}>
                <AppBar position="static" className={classes.appBar}>
                    <Toolbar className={classes.toolBar}>
                        <Box>
                            <Tabs
                                value={selectedTab}
                                classes={{ root: classes.tabs, indicator: classes.indicator, flexContainer: classes.flexContainer }}
                            >
                                <Tab
                                    className={
                                        selectedTab === 0
                                            ? classes.selectedTab
                                            : classes.tab
                                    }
                                    label="Home"
                                    onClick={() => navigation.navigate("Dashboard")}
                                />
                                <Divider
                                    orientation="vertical"
                                    flexItem
                                    className={classes.divider}
                                />
                                <Tab
                                    className={
                                        selectedTab === 2
                                            ? classes.selectedTab
                                            : classes.tab
                                    }
                                    label="About Us"
                                    onClick={() => navigation.navigate("AboutUS")}
                                />
                                <Divider
                                    orientation="vertical"
                                    flexItem
                                    className={classes.divider}
                                />
                                <Tab
                                    className={
                                        selectedTab === 4
                                            ? classes.selectedTab
                                            : classes.tab
                                    }
                                    label="Contact Us"
                                    onClick={() => navigation.navigate("ContactUs")}
                                />
                                <Divider
                                    orientation="vertical"
                                    flexItem
                                    className={classes.divider}
                                />
                                <Tab
                                    className={
                                        selectedTab === 6
                                            ? classes.selectedTab
                                            : classes.tab
                                    }
                                    label="FAQs"
                                    onClick={() => navigation.navigate("HelpCentreQA")}
                                />
                            </Tabs>
                        </Box>
                        <Box className={classes.buttonContainer}>
                            {
                                alertIcon &&
                                <Button className={classes.responsiveButton}>
                                    <div className={classes.button}>
                                        <img src={NotificationIcon} className={classes.alertIcon} />
                                        <Typography className={classes.buttonText}>

                                        </Typography>
                                    </div>
                                </Button>
                            }
                            <FormControl>
                                <Select
                                    value="en"
                                    renderValue={() => ("En")}
                                    className={classes.select}
                                    disableUnderline
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <LanguageIcon />
                                        </InputAdornment>
                                    }

                                    IconComponent={KeyboardArrowDownIcon}
                                >
                                    <MenuItem value="en">EN</MenuItem>
                                    {/* Add more language options as needed */}
                                </Select>
                            </FormControl>
                            <Button className={
                                        selectedTab === 8
                                            ? classes.responsiveButtonActive
                                            : classes.responsiveButton
                                    }
                                    onClick={() => navigation.navigate("UserProfileBasic")}>
                                <div className={classes.button}>
                                    {/* <div className={classes.circleAlert}> */}
                                        <img src={profileView ? ProfileIconView :ProfileIcon} className={classes.profileIcon}/>
                                    {/* </div> */}
                                    <Typography className={ profileView ? classes.buttonSelected : classes.buttonText}>
                                        Profile
                                    </Typography>
                                </div>
                            </Button>
                            <Button className={classes.responsiveButton} onClick={() => navigation.navigate("LandingPageweb")}>
                                <div className={classes.button}>
                                    {/* <div className={classes.circle}> */}
                                    <img src={LogoutIcon} className={classes.profileIcon}/>
                                    {/* </div> */}
                                    <Typography className={classes.buttonText}>
                                        Logout
                                    </Typography>
                                </div>
                            </Button>
                        </Box>
                    </Toolbar>
                </AppBar>
                <Divider />
            </div>
        );
    }
}

export default withStyles(styles)(MyNavbar);
