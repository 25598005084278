import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "framework/src/Utilities";
import { generateString } from "../../landingpage/src/LandingPageController";
import { WithStyles } from "@material-ui/core";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  settingsTabValue: number
  passwordCaptch: string
  changePasswordForm: {
    currentPassword: string,
    newPassword: string,
    reEnterNewPassword: string,
    captch: string
  },
  isErrorFormData: any
  changePasswordData: any
  dailogOpen: boolean
  messageText: string
  changePasswordStatus: string,
  oldPassword: boolean,
  newPassword: boolean,
  confirmNewPassword: boolean,
  currentPasswordError: string,
  signatureButtonStatus: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiCallIdChangePassword: string = ""
  token: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      settingsTabValue: 0,
      passwordCaptch: "",
      changePasswordForm: {
        currentPassword: "",
        newPassword: "",
        reEnterNewPassword: "",
        captch: ""
      },
      isErrorFormData: {},
      changePasswordData: {},
      dailogOpen: false,
      messageText: "",
      changePasswordStatus: "",
      oldPassword: false,
      newPassword: false,
      confirmNewPassword: false,
      currentPasswordError: "",
      signatureButtonStatus: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  async componentDidMount() {
    this.setState({ passwordCaptch: (() => generateString())() });
    const token = await getStorageData('authToken')
    if (!token) {
      this.props.navigation.history.push("/")
    }
    this.token = token
    this.manageTabs()
    // this.managecloseDailog()
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId === this.apiCallIdChangePassword) {
        if (responseJson && !responseJson.errors && !responseJson.message) {
          this.setState({
            changePasswordData: responseJson.data,
            changePasswordStatus: "success"
            , dailogOpen: true,
            messageText: configJSON.popupText.successChangePassword
          })
        } else if (responseJson.message === "Your password doesn't match") {
          this.setState({ currentPasswordError: "Incorrect current password" })

        }
      }

    }

    // Customizable Area End
  }

  // Customizable Area Start

  manageTabs = () => {
    const pathName = this.props.navigation?.history?.location?.pathname;
    if (pathName == "/RenewDigitalSignature") {
      this.setState({ settingsTabValue: 1 })
    } else if (pathName == "/RequestDigitalSignature") {
      this.setState({ settingsTabValue: 2 })
    } else {
      this.setState({ settingsTabValue: 0 })

    }

  }
  handleReset = () => {
    this.setState({
      changePasswordForm: {
        ...this.state.changePasswordForm,
        currentPassword: "",
        newPassword: "",
        reEnterNewPassword: "",
        captch: ""
      },
      isErrorFormData: {},
      currentPasswordError: ""

    })
  }


  settingsChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (newValue === 1) {
      this.props.navigation.history.push('/RenewDigitalSignature')
    } else if (newValue === 2) {
      this.props.navigation.history.push('/RequestDigitalSignature')
    } else {
      this.props.navigation.history.push('/ChangePassword')
    }
  }

  handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ changePasswordForm: { ...this.state.changePasswordForm, [event.target.name]: event.target.value } }, () => {
    });

  }
  changePasswordBtn = () => {
    this.setState({ isErrorFormData: this.validatePasswordForm(this.state.changePasswordForm, this.state.passwordCaptch) }, () => {
      if (Object.keys(this.state.isErrorFormData).length === 0) {
        this.changePassword()
      }
    }
    )
  }
  validatePasswordForm = (passwordForm: any, captch: string) => {
    let errors: any = {}
    this.validatePassword(passwordForm.currentPassword, errors, "currentPassword")
    this.validateNewPassword(passwordForm.newPassword, errors, "newPassword")
    this.validateConfirmPassword(passwordForm.newPassword, passwordForm.reEnterNewPassword, errors, "reEnterNewPassword")
    this.validateCaptch(captch, passwordForm.captch, errors)

    return errors
  }
  validatePassword = (values: string, error: any, key: string) => {
    if (!values) {
      error[key] = "Current password cannot be empty"
    } else if (!configJSON.regexPatters.passwordPatters.test(values)) {
      error[key] = "Please enter the valid password"
    }

  }
  validateNewPassword = (values: string, error: any, key: string) => {
    if (!values) {
      error[key] = "New password cannot be empty"
    } else if (!/[A-Z]/.test(values)) {
      error[key] = "Password must have at least one capital letter.";
    } else if (!/[a-z]/.test(values)) {
      error[key] = "Password must have at least one lowercase letter.";
    } else if (!/\d/.test(values)) {
      error[key] = "Password must have at least one number.";
    } else if (!/[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]/.test(values)) {
      error[key] = "Password must have at least one special character.";
    } else if (!(values.length >= 8 && values.length <= 15)) {
      error[key] = "Password must be between 8 and 15 characters in length.";
    } else if (!configJSON.regexPatters.passwordPatters.test(values)) {
      error[key] = "Please enter the valid password"
    }

  }
  validateConfirmPassword = (newPassword: string, reEnterNewPassWord: string, error: any, key: string) => {
    if (!reEnterNewPassWord) {
      error[key] = "Confirm new password cannot be empty"
    } else if (newPassword != reEnterNewPassWord) {
      error[key] = "Password doesn't match"
    }
  }

  validateCaptch = (generateCapth: string, formCaptch: string, error: any) => {
    if (!formCaptch) {
      error.captch = configJSON.captchErrorText.emptyText
    } else if (generateCapth != formCaptch) {
      error.captch = configJSON.captchErrorText.incorrextCaptch
    }
  }
  changePassword = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType
    };
    const changePasswordBody = {
      "token": this.token,
      "old_password": this.state.changePasswordForm.currentPassword,
      "new_password": this.state.changePasswordForm.newPassword,
      "confirm_password": this.state.changePasswordForm.reEnterNewPassword
    }
    const body = {
      data: changePasswordBody
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCallIdChangePassword = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changePasswordEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleShowOldPassword = () => {
    this.setState({ oldPassword: !this.state.oldPassword });
  }

  handleShowNewPassword = () => {
    this.setState({ newPassword: !this.state.newPassword });
  }

  handleShowConfirmPassword = () => {
    this.setState({ confirmNewPassword: !this.state.confirmNewPassword });
  }
  handleBackButton = () => {
    this.props.navigation.history.push("/Dashboard")
  }
  closeDailog = (redirectFrom: string) => {
    this.setState({
      dailogOpen: false
    }, () => {
      this.handleReset()
      this.handleRedirection(redirectFrom)
    })
  }
  handleRedirection = (redirectFrom: string) => {
    if (redirectFrom === "changePassword") {
      this.props.navigation.history.push("/changePassword")
    }
  }

  // Customizable Area End
}
