// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");
// Customizable Area End



export interface Props {
  // Customizable Area Start
  navigation: any;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  contactDetails: {
    name: string,
    designation: string,
    address: string,
    phoneNo: string,
    faxNo: string,
    email: string,
  };
  isLoading:boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start

  // Customizable Area End
}

export default class ContactUsWebController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  contactUsId = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      contactDetails: {
        name: "",
        designation: "",
        address: "",
        phoneNo: "",
        faxNo: "",
        email: ""
      },
      isLoading:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.contactUsId) {
        const data=responseJson.data.attributes
        if(data){
          const name=`${data.title} ${data.first_name} ${data.last_name}`
          const address=`${data.address}, ${data.city} - ${data.pincode}`
          this.setState({
            contactDetails:{
              name,
              address,
              phoneNo: data.phone_no,
              faxNo: data.fax_no,
              email: data.email,
              designation:data.designation
            }
          })
        }
        this.setState({isLoading:false})
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.fecthContactUsData()
  }

  fecthContactUsData = () => {
    this.setState({isLoading:true})
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.contactUsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_landingpage2/contact_us'
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  // Customizable Area End
}
