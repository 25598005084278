import React from "react";
// Customizable Area Start
import { ipoImage ,logoImage} from "../../customform/src/assets";
import { withStyles, createStyles } from "@material-ui/styles";
// Customizable Area End
import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";

export class NewUserRegistrationForm extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <div className={this.props.classes.containerNew}>
        <div className={this.props.classes.headerNew}>
          <img src={logoImage} alt="Logo" className={this.props.classes.logoNew} />
          <img src={ipoImage} alt="Ipo-Logo" className={this.props.classes.logoNew} />
        </div>
        <div className={this.props.classes.cardDSNew}>
          <div className={this.props.classes.cardHeaderNew}>
            <span className={this.props.classes.headerTextNew}>New User Registration Form </span>
          </div>
          <div className={this.props.classes.registrationCardNew}>
            <div className={this.props.classes.headingContainerNew}>
              <span className={this.props.classes.subheaderTextNew}>
                Steps to Register for eFiling
              </span>
              <hr className={this.props.classes.registrationCardHrNew}/>
            </div>
            <span className={this.props.classes.registrationCardRulesNew}>
              1.Procure a class III digital signature from any of the Indian Certifying Authorities and install the same on your computer.
            </span>
            <span className={this.props.classes.registrationCardRulesNew}>
              2.Download and install the <a href="" className={this.props.classes.textColorNew}>Signing Component</a> as per
              the instructions given in the{" "}
              <a href="" className={this.props.classes.textColorNew}>Digital Signature Manual</a>.
            </span>
            <span className={this.props.classes.registrationCardRulesNew}>
              3.Write to the helpdesk at tmr-helpdesk@nic.in for any further
              assistance.
            </span>
          </div>

          <div className={this.props.classes.buttonsContainerDSNew}>
            <button
              className={this.props.classes.actionButtonBackNew}
              id="backButton"
              onClick={() => {
                this.handleNavigate("LandingPageweb");
              }}
            >
              Back
            </button>
            <button
              className={this.props.classes.actionButtonNew}
              id="nextButton"
              onClick={() => {
                this.handleNavigate("SelectTypeOfApplicant");
              }}
            >
              Proceed
            </button>
          </div>
        </div>
        <div className={this.props.classes.buttonsContainerDSNew}>
          <button id="Home" className={this.props.classes.actionButtonNew} onClick={()=>this.handleNavigate("Dashboard")}>Home</button>
          <button id="AboutUs" className={this.props.classes.actionButtonNew} onClick={()=>this.handleNavigate("AboutUS")}>About Us</button>
          <button id="ContactUs" className={this.props.classes.actionButtonNew} onClick={()=>this.handleNavigate("ContactUs")}>Contact Us</button>
        </div>
        <footer className={this.props.classes.footerNew}>
          <p className={this.props.classes.footerTextNew}>
            Guidelines for using NTRP (Bharat Kosh) Payment Gateway <span className={this.props.classes.gideLableNew}>Guide</span>
          </p>
        </footer>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const style=createStyles({
  containerNew: {
    backgroundColor: "#ffd4ac",
    height: "auto",
    overflowX: "hidden"
  },
  headerNew: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#ffd4ac",
    maxWidth: "100%",
    height: "100px",
    marginBottom: "50px"
  },
  logoNew: {
    objectFit: "contain",
    height: "92px",
    padding: "20px",
    '@media (max-width: 900px)': {
      height: "80px",
      padding: "10px",
    },
    '@media (max-width: 600px)': {
      height: "60px",
      padding: "5px",
    },
    '@media (max-width: 300px)': {
      height: "40px",
      padding: "4px",
    }
  },
  cardDSNew: {
    width: "75%",
    height: "auto",
    minHeight: "500px",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    backgroundColor: "#ffffff",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    margin: "0 auto",
    padding: "20px",
    marginBottom: "40px",
    paddingTop: "20px",
  },
  cardHeaderNew: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "40px",
  },
  headerTextNew: {
    fontFamily: 'Poppins, sans-serif',
    fontSize: "33px",
    fontWeight: 400,
  },
  subheaderTextNew: {
    fontFamily: 'Poppins, sans-serif',
    fontSize: "23px",
    fontWeight: 600,
    display: "flex",
    justifyContent: "flex-start",
  },
  registrationCardNew: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    margin: "auto",
    width: "80%",
    marginTop: "40px",
  },
  registrationCardRulesNew: {
    fontSize: "22px",
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 400,
    marginBottom: "2px",
  },
  buttonsContainerDSNew: {
    display: "flex",
    marginTop: "auto",
    marginBottom: "40px",
    justifyContent: "center",
    '@media (max-width: 1200px)': {
      flexDirection: "column",
      marginTop: "40px",
      gap:"10px",
      alignItems: "center",
    }
  },
  actionButtonNew: {
    padding: "10px 20px",
    fontSize: "18px",
    fontWeight: "bold",
    borderRadius: "5px",
    margin: "0 10px" /* Add some margin between the buttons */,
    cursor: "pointer",
    minWidth: "300px" /* Minimum width for the button */,
    height: "54px" /* Height for the button */,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "none",
    fontFamily: '"Poppins", sans-serif',
    backgroundColor: "#894402", // Background color
    color: "#ffffff", // Text color
    border: "none", // Remove border
    '@media (max-width: 1200px)': {
      width: "200px",
    }
  },
  actionButtonBackNew: {
    padding: "10px 20px",
    fontSize: "18px",
    fontWeight: "bold",
    borderRadius: "5px",
    margin: "0 10px" /* Add some margin between the buttons */,
    cursor: "pointer",
    minWidth: "300px" /* Minimum width for the button */,
    height: "54px" /* Height for the button */,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "none",
    fontFamily: '"Poppins", sans-serif',
    backgroundColor: "#ffffff", // Background color
    color: "#894402", // Text color
    border: "1px solid #894402", // Remove border
    marginRight: "10px",
    '@media (max-width: 1200px)': {
      marginBottom:"20px",
      width: "200px"
    }
  },
  textColorNew:{
    color: "#0047FF"
  },
  gideLableNew:{
    color: "#FF0000"
  },
  footerTextNew: {
    margin: "0" /* Remove default margins for the paragraph */,
    fontSize: "20px",
    color: "#000000",
    fontWeight: 400,
    lineHeight: "45px",
    fontFamily: "Poppins-Medium, sans-serif"
  },
  footerNew: {
    backgroundColor: "#ffffff",
    padding: "10px",
    height: "70px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  headingContainerNew: {
    display: "flex",
    marginBottom: "8px",
    flexDirection: "column",
  },
  registrationCardHrNew: {
    margin: "2px 0",
    flex: "1",
    border: "none",
    width: "317px",
    borderTop: "2px solid #000000",
  },
})
export default withStyles(style)(NewUserRegistrationForm)
// Customizable Area End
