Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "settings2";
exports.labelBodyText = "settings2 Body";
exports.updateMethodType="PUT"

exports.btnExampleTitle = "CLICK ME";


exports.regexPatters={
  charactersPatters:/^[a-zA-Z]+(?:\s[a-zA-Z]+)*$/,
  passwordPatters:/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[!@#$%^&*]).{8,15}$/ ,
  emailPattern:/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
}
exports.chnagePasswordButtons={
  backBtnText:"Back",
  resetBtnText:"Reset",
  changeBtnText:"Change",
  DigitalBtnText:"Digital Signature"

}
exports.changePasswordEndPoint="bx_block_forgot_password/change_password"
// ErrorTexts 

exports.captchErrorText={
  emptyText:"Captcha is required",
  incorrextCaptch:"Incorrect captcha"
}

exports.popupText={
  successChangePassword:"Successfully Changed Password",
  errorChangePassword:"Unable To Change The Password",
  successRenewSignature:"Successfully Renewed Digital Signature",
  errorRenewSignature:"Unable to Renew Digital Signature",
  successRequestSignature:"Successfully Requested Digital Signature",
  errorRequestSignature:"Unable To Change The Password",

}
// Customizable Area End