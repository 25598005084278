import React from "react";
// Customizable Area Start
import {
  refresh,
  captchaback,
  warningIcon,
  registration,
  linkExpired
} from "../../landingpage/src/assets";
import { ipoImage ,logoImage} from "../../customform/src/assets";
import {
  IconButton,
  OutlinedInput,
  InputAdornment,
  Container,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import {withStyles, createStyles} from "@material-ui/styles";
import Captcha from "../../captcha/src/Captcha.web";
// Customizable Area End
import EmailAccountRegistrationController, { Props } from "../src/EmailAccountRegistrationController";

export class AccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <div className={this.props.classes.containerRegs}>
        <div className={this.props.classes.headerRegs}>
          <img src={logoImage} alt="Logo" className={this.props.classes.logoRegs} />
          <img src={ipoImage} alt="Ipo-Logo" className={this.props.classes.logoRegs} />
        </div>
        <div className={this.props.classes.cardDSRegs}>
          <div className={this.props.classes.topLineRegs}>
            <button className={this.props.classes.buttonRegs}> New User Registration Form </button>
          </div>
          <div className={this.props.classes.contentRegs}>
            <label className={this.props.classes.labelRegs}>User Details</label>
            <label className={this.props.classes.subLabelRegs}>(Association/Organisation)</label>
          </div>
          <Container className={this.props.classes.cardContainerRegs}>
            <div className={this.props.classes.customElementRegs}>
              <Typography
                variant="subtitle1"
                className={this.props.classes.formLabelUserDetailsUD}
              >
                {"User ID "}
                <span className={this.props.classes.colorStarRegs}>*</span>:
              </Typography>
              <div className={this.props.classes.flexDivRegs}>
                <OutlinedInput
                  type="text"
                  className={this.props.classes.formInputUserDetailsARegs}
                  id="UserID"
                  onChange={e => {
                    this.setState({ userId: e.target.value, userIdError: "" });
                  }}
                />
                {!this.state.userIdError && (
                  <span className={this.props.classes.UserDetailsNoteRegs}>
                    (User ID must be at least 5 characters, no more than 16
                    characters, and allows only Alphanumeric without space.)
                  </span>
                )}
                {this.state.userIdError && (
                  <div className={this.props.classes.textUserDetailsRegs}>
                    <img
                      src={warningIcon}
                      alt="alertIcon"
                      className={this.props.classes.waringIconRegs}
                    />
                    <span className={this.props.classes.errorMessageTextUserDetailsRegs}>
                      {/* User ID must be at least 5 characters, no more than 16
                      characters, and allows only Alphanumeric without space. */}
                      {this.state.userIdErrorMsg}
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className={this.props.classes.customElementRegs}>
              <Typography
                variant="subtitle1"
                className={this.props.classes.formLabelUserDetailsUD}
              >
                {"Password "}
                <span className={this.props.classes.colorStarRegs}>*</span>:
              </Typography>
              <div className={this.props.classes.flexDivRegs}>
                <OutlinedInput
                  type={this.state.showPwd ? "text" : "password"}
                  className={this.props.classes.formInputUserDetailsARegs}
                  id="userPassword"
                  onChange={e => {
                    this.setState({
                      userPwd: e.target.value,
                      userPwdError: ""
                    });
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          this.setState({ showPwd: !this.state.showPwd });
                        }}
                        edge="end"
                      >
                        {!this.state.showPwd ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {!this.state.userPwdError && (
                  <span className={this.props.classes.UserDetailsNoteRegs}>
                      {this.getFieldInputValidation()} <a href="#" onClick={this.handleReadMoreClick} className={this.props.classes.readMore}>
                      {this.getActionForRead()}
                      </a>
                  </span>
                )}
                {this.state.userPwdError && (
                  <div className={this.props.classes.textUserDetailsRegs}>
                    <img
                      src={warningIcon}
                      alt="alertIcon"
                      className={this.props.classes.waringIconRegs}
                    />
                    <span className={this.props.classes.errorMessageTextUserDetailsRegs}>
                      {this.getFieldInputValidation()} <a href="#" onClick={this.handleReadMoreClick} className={this.props.classes.readMore}>
                      {this.getActionForRead()}</a>
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className={this.props.classes.customElementRegs}>
              <Typography
                variant="subtitle1"
                className={this.props.classes.formLabelUserDetailsUTRegs}
              >
                {"Re-type Password "}
                <span className={this.props.classes.colorStarRegs}>*</span>:
              </Typography>
              <div className={this.props.classes.flexDivRegs}>
                <OutlinedInput
                  type={this.state.showAltPwd ? "text" : "password"}
                  className={this.props.classes.formInputUserDetailsARegs}
                  id="RetypePassword"
                  onChange={e => {
                    this.setState({
                      reTypePwd: e.target.value,
                      reTypePwdError: ""
                    });
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        id="pwdVisibility"
                        onClick={() => {
                          this.setState({ showAltPwd: !this.state.showAltPwd });
                        }}
                        edge="end"
                      >
                        {!this.state.showAltPwd ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {this.state.reTypePwdError && (
                  <div className={this.props.classes.textUserDetailsRegs}>
                    <img
                      src={warningIcon}
                      alt="alertIcon"
                      className={this.props.classes.waringIconRegs}
                    />
                    <span className={this.props.classes.errorMessageTextUserDetailsRegs}>
                      {this.state.reTypePwdError}
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className={this.props.classes.pincodeDivRegs}>
              <Typography
                variant="subtitle1"
                className={this.props.classes.formLabelUserDetailsURegs}
              >
                {"Telephone No. (STD/ISD)"}
                <span className={this.props.classes.colorStarRegs}>*</span>:
              </Typography>
              <OutlinedInput
                type="text"
                className={this.props.classes.formInputUserDetailsARegs}
                id="Telephone"
                onChange={e => {
                  this.setState({
                    telePhoneNo: e.target.value,
                    telePhoneNoError: ""
                  });
                }}
              />
              <Typography
                variant="subtitle1"
                className={this.props.classes.formLabelUserDetailsUDRegs}
              >
                {"Alternate Telephone No:"}
              </Typography>
              <OutlinedInput
                type="text"
                className={this.props.classes.formInputUserDetailsARegs}
                id="AlternateTelephone"
                onChange={e => {
                  this.setState({
                    altTelePhoneNo: e.target.value,
                    altTelePhoneNoError: ""
                  });
                }}
              />
            </div>
            <div className={this.props.classes.customElementRegs}>
              <Typography
                variant="subtitle1"
                className={this.props.classes.formLabelUserDetailsRegs}
              />
              <Typography variant="caption" className={this.props.classes.flexDivRegs}>
                {this.state.telePhoneNoError && (
                  <div className={this.props.classes.textUserDetailsRegs}>
                    <img
                      src={warningIcon}
                      alt="alertIcon"
                      className={this.props.classes.waringIconRegs}
                    />
                    <span className={this.props.classes.errorMessageTextUserDetailsRegs}>
                      {this.state.telePhoneNoError}
                    </span>
                  </div>
                )}
              </Typography>
              <Typography variant="caption" className={this.props.classes.errorContainRegs}>
                {this.state.altTelePhoneNoError && (
                  <div className={this.props.classes.textUserDetailsRegs}>
                    <img
                      src={warningIcon}
                      alt="alertIcon"
                      className={this.props.classes.waringIconRegs}
                    />
                    <span className={this.props.classes.errorMessageTextUserDetailsRegs}>
                      {this.state.altTelePhoneNoError}
                    </span>
                  </div>
                )}
              </Typography>
            </div>
            <div className={this.props.classes.pincodeDivRegs}>
              <Typography
                variant="subtitle1"
                className={this.props.classes.formLabelUserDetailsURegs}
              >
                {"Email ID "}
                <span className={this.props.classes.colorStarRegs}>*</span>:
              </Typography>
              <OutlinedInput
                type="text"
                className={this.props.classes.formInputUserDetailsARegs}
                id="EmailID"
                value={this.state.emailId}
                readOnly
              />
              <Typography
                variant="subtitle1"
                className={this.props.classes.formLabelUserDetailsUDRegs}
              >
                {""}
              </Typography>
              <label className={this.props.classes.formInputUserDetailsHRegs}> </label>
            </div>
            <div className={this.props.classes.pincodeDivRegs}>
              <Typography
                variant="subtitle1"
                className={this.props.classes.formLabelUserDetailsURegs}
              >
                {"Captcha "}
                <span className={this.props.classes.colorStarRegs}>*</span>:
              </Typography>
              <Captcha navigation={this.props.navigation} id={this.props.id} setCaptcha={this.setCaptcha} classes={this.props.classes}/>
              <Typography
                variant="subtitle1"
                className={this.props.classes.formLabelUserDetailsUDRegs}
              >
                {"Enter Captcha "}
                <span className={this.props.classes.colorStarRegs}>*</span>:
              </Typography>
              <OutlinedInput
                type="text"
                className={this.props.classes.formInputUserDetailsARegs}
                id="Captcha"
                onChange={e => {
                  this.setState({
                    userCaptcha: e.target.value,
                    userCaptchaError: ""
                  });
                }}
              />
            </div>
            <div className={this.props.classes.customElementRegs}>
              <Typography variant="subtitle1" className={this.props.classes.managedesignRegs} />
              <Typography variant="caption" className={this.props.classes.flexDivRegs} />
              <Typography variant="caption" className={this.props.classes.errorPostionRegs}>
                {this.state.userCaptchaError && (
                  <div className={this.props.classes.textUserDetailsRegs}>
                    <img
                      src={warningIcon}
                      alt="alertIcon"
                      className={this.props.classes.waringIconRegs}
                    />
                    <span className={this.props.classes.errorMessageTextUserDetailsRegs}>
                      {this.state.userCaptchaError}
                    </span>
                  </div>
                )}
              </Typography>
            </div>
          </Container>
          <div className={this.props.classes.buttonsContainerDSRegs}>
            <button
              className={this.props.classes.actionButtonBackRegs}
              id="buttonback"
              onClick={() => {
                this.props.navigation.navigate("DigitalSignature");
              }}
            >
              Back
            </button>
            <button
              className={this.state.disableUserButton ? this.props.classes.actionButtonDisableRegs : this.props.classes.actionButtonRegs}
              id="submitUserDetails"
              disabled={this.state.disableUserButton}
              onClick={() => {
                this.submitUserDetails();
              }}
            >
              Proceed
            </button>
          </div>
        </div>
        <footer className={this.props.classes.footerRegs}>
          <p className={this.props.classes.footerTextRegs}>
            Guidelines for using NTRP (Bharat Kosh) Payment Gateway{" "}
            <span className={this.props.classes.guidecolorRegs}>Guide</span>
          </p>
        </footer>
        {
          <Dialog
            open={this.state.registrationCompleted}
            className={this.props.classes.dialogModal}
            fullWidth
          >
            <DialogContent className={this.props.classes.dialogTextRegs}>
              <DialogContentText className={this.props.classes.dialogLableRegs}>
                <Typography
                  variant="subtitle1"
                  className={this.props.classes.dialogLabelUserDetailsRegs}
                >
                  {"Congratulations"}
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={this.props.classes.dialogLabelUserDetailsRegs}
                >
                  {"User registration has been successfully completed."}
                </Typography>
              </DialogContentText>
              <DialogContentText className={this.props.classes.dialoginfoIconRegs}>
                <img
                  src={registration}
                  width={100}
                  height={100}
                  alt="registration"
                />
              </DialogContentText>
              <DialogContentText className={this.props.classes.dialogButtonRegs}>
                <button
                  className={this.props.classes.actionButtonRegs}
                  id="backTohome"
                  onClick={() => {
                    this.handleNavigate("Dashboard");
                  }}
                >
                  Go to dashboard
                </button>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        }
        {
          <Dialog
            open={this.state.registrationFail}
            fullWidth
            className={this.props.classes.dialogModal}
          >
            <DialogContent className={this.props.classes.dialogTextRegs}>
              <DialogContentText className={this.props.classes.dialogLableRegs}>
                <Typography
                  variant="subtitle1"
                  className={this.props.classes.dialogLabelUserDetailsRegs}
                >
                  {"Sign up failed"}
                </Typography>
                {
                  this.state.emailIdError &&
                  <Typography
                    variant="subtitle1"
                    className={this.props.classes.dialogUserDetailsError}
                  >
                    {this.state.emailIdErrorMsg}
                  </Typography>
                }
                {
                  this.state.userIdError &&
                  <Typography
                    variant="subtitle1"
                    className={this.props.classes.dialogUserDetailsError}
                  >
                    {this.state.userIdErrorMsg}
                  </Typography>
                }
                <Typography
                  variant="subtitle1"
                  className={this.props.classes.dialogLabelUserDetailsRegs}
                >
                  {"Please reload the page and try to submit the form again"}
                </Typography>
              </DialogContentText>
              <DialogContentText className={this.props.classes.dialoginfoIconRegs}>
                <img
                  src={linkExpired}
                  width={100}
                  height={100}
                  alt="registration"
                />
              </DialogContentText>
              <DialogContentText className={this.props.classes.dialogButtonRegs}>
                <button
                  className={this.props.classes.actionButtonRegs}
                  id="dashboard"
                  onClick={() => {
                    this.navigationUser()
                  }}
                >
                  Try again
                </button>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        }
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = createStyles({
  containerRegs: {
    backgroundColor: "#ffd4ac",
    height: "auto",
    overflowX: "hidden"
  },
  headerRegs: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#ffd4ac",
    maxWidth: "100%",
    height: "100px",
    marginBottom: "50px"
  },
  logoRegs: {
    objectFit: "contain",
    height: "92px",
    padding: "20px",
    '@media (max-width: 900px)': {
      height: "80px",
      padding: "10px",
    },
    '@media (max-width: 600px)': {
      height: "60px",
      padding: "5px",
    },
    '@media (max-width: 300px)': {
      height: "40px",
      padding: "4px",
    }
  },
 
  guidecolorRegs: {
    color: "#FF0000"
  },
  footerTextRegs: {
    margin: "0" /* Remove default margins for the paragraph */,
    fontSize: "20px",
    color: "#000000",
    fontWeight: 400,
    lineHeight: "45px",
    fontFamily: "Poppins-Medium, sans-serif"
  },
  footerRegs: {
    backgroundColor: "#ffffff",
    padding: "10px",
    height: "70px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  cardDSRegs: {
    width: "75%",
    height: "auto",
    minHeight: "500px",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    backgroundColor: "#ffffff",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    margin: "0 auto",
    padding: "20px",
    marginBottom: "40px",
    paddingTop: "20px"
  },
  topLineRegs: {
    width: "100%",
    height: "1px",
    backgroundColor: "#ccc",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: "0"
  },
  buttonRegs: {
    padding: "5px 10px",
    fontSize: "30px",
    fontWeight: 400,
    border: "none",
    width: "600px",
    height: "70px",
    backgroundColor: "#894402",
    color: "#ffffff",
    borderRadius: "5px",
    fontFamily: "Poppins-Medium, sans-serif",
    '@media (max-width: 1200px)': {
      width:"60%",
      fontSize: "25px",
      height: "auto",
    }
  },
  contentRegs: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "50px"
  },
  labelRegs: {
    fontWeight: 470,
    fontSize: "33px",
    fontFamily: "Poppins, sans-serif",
    color: "#000000",
    marginBottom: "15px"
  },
  subLabelRegs: {
    fontWeight: 400,
    fontSize: "24px",
    fontFamily: "Poppins, sans-serif",
    color: "#000000"
  },
  cardContainerRegs: {
    marginTop: "4rem",
    marginBottom: "50px"
  },
  customElementRegs: {
    marginBottom: "1rem",
    display: "flex",
    '@media (max-width: 1200px)': {
        flexDirection: "column",
    }
  },
  formLabelUserDetailsURegs: {
    fontSize: "18px",
    fontWeight: 400,
    color: "#000000",
    fontFamily: "Poppins, sans-serif",
    alignSelf: "center",
    flex: "0 0 250px"
  },
  formLabelUserDetailsUD: {
    fontSize: "18px",
    fontWeight: 400,
    color: "#000000",
    fontFamily: "Poppins, sans-serif",
    alignSelf: "flex-start",
    marginTop:"8px",
    flex: "0 0 250px" ,
    '@media (max-width: 1200px)': {
      flex: "0 0 10px" ,
      display:"flex",
    }
  },
  formLabelUserDetailsUTRegs: {
    fontSize: "18px",
    fontWeight: 400,
    color: "#000000",
    fontFamily: "Poppins, sans-serif",
    alignSelf: "center",
    flex: "0 0 250px" ,
    '@media (max-width: 1200px)': {
      flex: "0 0 10px" ,
      display:"flex",
      alignSelf: "flex-start",
  }
  },
  formLabelUserDetailsUDRegs: {
    fontSize: "18px",
    fontWeight: 400,
    color: "#000000",
    fontFamily: "Poppins, sans-serif",
    alignSelf: "center",
    flex: "0 0 250px", 
    marginLeft: "20px",
    '@media (max-width: 1200px)': {
      marginLeft: "0",
  }
  },
  formLabelUserDetailsRegs: {
    flex: "0 0 250px",
    '@media (max-width: 1200px)': {
      flex: "0 0 10px", 
    } 
  },
  readMore:{
    color: "#FF0000",
    fontWeight:660
  },
  colorStarRegs: {
    color: "red"
  },
  flexDivRegs: {
    flex: "1",
     '@media (max-width: 1200px)': {
      display: "flex",
      flex:"unset",
      alignItems: "center",
      flexWrap: "wrap",
  }
  },
  formInputUserDetailsARegs: {
    width: "100%",
    height: "53px",
    padding: "10px",
    fontSize: "16px",
    borderRadius: "5px",
    marginBottom: "8px",
    fontFamily: "Poppins, sans-serif",
    color: "#000000"
  },
  formEmailDetailsRegs: {
    width: "100%",
    height: "53px",
    padding: "10px",
    fontSize: "16px",
    borderRadius: "5px",
    marginBottom: "8px",
    fontFamily: "Poppins, sans-serif",
    color: "#000000",
    '@media (max-width: 1200px)': {
      minWidth: "900px",
    }
  },
  UserDetailsNoteRegs: {
    fontFamily: "Poppins, sans-serif",
    fontWeight: 400,
    fontSize: "13px",
    color: "#737373"
  },
  errorMessageTextUserDetailsRegs: {
    position: "relative",
    fontFamily: "Poppins-Medium, sans-serif",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "18px",
    color: "#FF0000",
    marginTop: "5px",
    left: "0"
  },
  textUserDetailsRegs: {
    textAlign: "left" ,
    display: "flex",
    alignItems: "center"
  },
  formInputUserDetailsHRegs: {
    width: "100%",
    height: "53px",
    padding: "10px",
    '@media (max-width: 1200px)': {
      height: "10px",
    }
  },
  iconContainer: {
    marginLeft: "10px",
    marginRight: "10px",
    marginTop:"18px"
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: "100%",
    marginRight: "15px",
    '@media (max-width: 1200px)': {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      marginBottom:"10px"
    }
  }, 
  imageContainer: {
    position: 'relative',
    textAlign: 'center',
    color: '#C74217',
    marginTop: '20px'
  },
  centered: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '30px',
    fontWeight: 800,
    fontFamily: 'Poppins-MediumItalic, sans-serif',
    color: '#C74217'
  },
  buttonsContainerDSRegs: {
    display: "flex",
    marginTop: "auto",
    marginBottom: "40px",
    justifyContent: "center",
    '@media (max-width: 1200px)': {
      flexDirection: "column",
      alignItems: "center",
      marginBottom: "10px",
    }
  },
  dialogUserDetailsRegs: {
    "& .MuiPaper-root": {
      boxShadow: "none",
      height: "auto",
      borderRadius: "20px",
      padding: "20px",
      maxWidth: "800px",
    }   

  },
  dialogLabelUserDetailsRegs: {
    fontSize: "18px",
    fontWeight: 400,
    color: "#894402",
    fontFamily: "Poppins, sans-serif",
    alignSelf: "flex-start"
  },
  dialogUserDetailsError: {
    fontSize: "18px",
    fontWeight: 400,
    color: "red",
    fontFamily: "Poppins, sans-serif",
    alignSelf: "flex-start"
  },
  actionButtonRegs: {
    padding: "10px 20px",
    fontSize: "18px",
    fontWeight: "bold",
    borderRadius: "5px",
    margin: "0 10px" /* Add some margin between the buttons */,
    cursor: "pointer",
    minWidth: "300px" /* Minimum width for the button */,
    height: "54px" /* Height for the button */,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "none",
    fontFamily: 'Poppins, sans-serif',
    backgroundColor: "#894402", // Background color
    color: "#ffffff", // Text color
    border: "1px solid #894402", // Remove border
    '@media (max-width: 1200px)': {
      Width: "200px",
    }
  },
  actionButtonBackRegs: {
    padding: "10px 20px",
    fontSize: "18px",
    fontWeight: "bold",
    borderRadius: "5px",
    margin: "0 10px" /* Add some margin between the buttons */,
    cursor: "pointer",
    minWidth: "300px" /* Minimum width for the button */,
    height: "54px" /* Height for the button */,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "none",
    fontFamily: 'Poppins, sans-serif',
    backgroundColor: "#ffffff", // Background color
    color: "#894402", // Text color
    border: "1px solid #894402", // Remove border
    marginRight: "10px",
    '@media (max-width: 1200px)': {
      marginBottom:"20px"
    }
  },
  actionButtonDisableRegs: {
    padding: "10px 20px",
    fontSize: "18px",
    fontWeight: "bold",
    borderRadius: "5px",
    margin: "0 10px" /* Add some margin between the buttons */,
    cursor: "pointer",
    minWidth: "300px" /* Minimum width for the button */,
    height: "54px" /* Height for the button */,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "none",
    fontFamily: "Poppins, sans-serif",
    backgroundColor: "#b38961", // Background color
    border: "1px solid #b38961",
    color: "#ffffff", // Text color
    '@media (max-width: 1200px)': {
      marginBottom:"20px"
    }
  },
  waringIconRegs: {
    width: "20px",
    height: "20px",
    marginRight: "4px"
    // Add any other styles specific to the "waringIcon" here
  },
  pincodeDivRegs: {
    display: "flex",
    alignItems: "center",
    '@media (max-width: 1200px)': {
      flexWrap: "wrap",
    }
  },
  labelTextRegs: {
    display: "flex",
    width: "100%",
    marginRight: "15px"
  },
  infoTextRegs: {
    color: "#FF0000",
    fontWeight: "bold"
  },
  errorContainRegs: {
    flex: "1",
    marginLeft: "255px"
  },
  errorPostionRegs: {
    flex: "1",
    marginLeft: "170px"
  },
  dialoginfoIconRegs: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  dialogButtonRegs: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "30px",
    marginBottom: "30px"
  },
  dialogLableRegs: {
    marginTop: "25px",
    marginBottom: "30px"
  },
  dialogContentRegs: {
    textAlign: "center"
  },
  managedesignRegs: {
    flex: "0 0 350px",
    '@media (max-width: 1200px)': {
      flex: "0 0 10px", 
    } 
  },
  dialogTextRegs: {
    textAlign: "center" 
  },
  dialogModal: {
    "& .MuiPaper-root": {
      borderRadius: "15px",
      padding: "20px",
      maxWidth: "690px",
    },
    '@media (max-width: 900px)': {
      "& .MuiPaper-root": {
        maxHeight: "300px", 
      },
    },
    '@media (max-width: 600px)': {
      "& .MuiPaper-root": {
        maxHeight: "200px", 
      },
    },
    '@media (max-width: 300px)': {
      "& .MuiPaper-root": {
        maxHeight: "100px", 
      },
    }
  },
});

export default withStyles(styles)(AccountRegistration)

// Customizable Area End
