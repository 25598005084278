import React from "react";

import {
  Box,
  Typography,
  // Customizable Area Start
  createStyles, Theme, Grid, Tab, withStyles, Select, MenuItem, TextField, InputAdornment, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button, Checkbox, Dialog, DialogContent,
  // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import SearchIcon from "@material-ui/icons/Search";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { ipoImage, logoImage } from "../../customform/src/assets";
import { CompleteIcon } from "../../dashboard/src/assets";
import { StyledTabs } from "../../../components/src/CustomizedComponents.web";
import Navbar from "../../../components/src/Navbar";
import "../../dashboard/src/Dashboard.css"
import FormHistoryController, {
  Props
} from "./FormHistoryController.web";
import moment from "moment";

// Customizable Area End



export default class FormHistory extends FormHistoryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  allProps = (index:any) => {
    return {
      "aria-controls": `simple-tabpanel-${index}`,
      id: `simple-tab-${index}`,
    };
  }

  TabPanel=(classes:any, index: any)=> {
    return (
      <div
        role="tabpanel"
        hidden={this.state.settingsTabValue !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
      >
        {this.state.settingsTabValue === index && (
          <Box p={3}>
            {this.GIFilterTable(classes, index)}
          </Box>
        )} 
      </div>
    );
  } 

  GIFilterTable=(classes:any, index:any)=>{
    switch (index) {
      case 0: return this.draftedForms(classes);
      case 1: return this.discardedForms(classes);
      case 2: return this.submittedForms(classes);
      case 3: return this.approvedForms(classes);
    }
  }

  noRecord=(classes:any)=>{
    return <Grid container justifyContent="center">
      <Typography className={classes.noRecordStyle}>
        No Record To Preview
      </Typography>
    </Grid>
  }

  draftedForms = (classes:any) => {
    if(this.state.draftedForms.length > 0){
    return (
      <Box
        className={classes.boxContainer}
      >
        {this.getFilterSearchView(classes)}
  
        <TableContainer
          className={classes.borderedTable}
          style={{ width: "100%" }}
        >
          <Table>
            {this.getTableHead(classes)}
  
            {this.showDraftedFormsData(classes)}
          </Table>
        </TableContainer>
  
        <Grid container justifyContent={"flex-end"}>
          <Button
            className={classes.editBtnStyle}
            disabled={!this.state.checkedForm.id}
            onClick={this.onEdit}
            >
              <Typography className={classes.editBtnTextStyle}>
              Edit
              </Typography>
          </Button>
          <Button
            className={classes.editBtnStyle}
            onClick={this.handleFormDiscard}
            data-test-id="discardTestID"
            disabled={!this.state.checkedForm.id}
          >
            <Typography className={classes.editBtnTextStyle}>
            Discard
            </Typography>
          </Button>
          <Button
            className={classes.previewBtnStyle}
            onClick={this.onPreview}
            data-test-id="previewTestID"
            disabled={!this.state.checkedForm.id}
          >
          <Typography className={classes.prevBtnTextStyle}>
          Preview
          </Typography>
          </Button>
        </Grid>

        {this.getNoteView(classes)}
      </Box>
    );
  }
   
  return this.noRecord(classes)
  
};

  discardedForms = (classes:any) => {
    if(this.state.discardedForms.length > 0){
    return (
      <Box
        className={classes.boxContainer}
      >
        {this.getFilterSearchView(classes)}
  
        <TableContainer
          className={classes.borderedTable}
          style={{ width: "100%" }}
        >
          <Table>
            
            {this.getTableHeadWithStatus(classes)}
  
            {this.showDiscardedFormsData(classes)}
          </Table>
        </TableContainer>
  
        <Grid container justifyContent={"flex-end"}>
          <Button
            onClick={this.onPreview}
            data-test-id="previewDiscardID"
            className={classes.previewBtnStyle}
            disabled={!this.state.checkedForm.id}
          >
          <Typography className={classes.prevBtnTextStyle} >
          Preview
          </Typography>
          </Button>
        </Grid>

        <Grid container justifyContent={"center"}>
<Typography className={classes.customTypography}>
      Note:  Upload all mandatory documents before signing. After signing the doucments digitally you can make payment under payment tab.
    </Typography>
</Grid>
      </Box>
    );
    }

    return this.noRecord(classes)

  };

  submittedForms = (classes:any) => {
    if(this.state.submittedForms.length > 0){
    return (
      <Box
        className={classes.boxContainer}
      >
        {this.getFilterSearchView(classes)}
  
        <TableContainer
          className={classes.borderedTable}
          style={{ width: "100%" }}
        >
          <Table>
          {this.getTableHead(classes)}
  
            {this.showSubmittedFormsData(classes)}
          </Table>
        </TableContainer>
  
        <Grid container justifyContent={"flex-end"}>
        <Button
            className={classes.editBtnStyle}
            >
              <Typography className={classes.editBtnTextStyle}>
              Make Payment
              </Typography>
          </Button>
          <Button
            className={classes.editBtnStyle}
          >
            <Typography className={classes.editBtnTextStyle}>
            Sign Document
            </Typography>
          </Button>
          <Button
            className={classes.previewBtnStyle}
            onClick={this.onPreview}
            data-test-id="previewSubmittedTestID"
            disabled={!this.state.checkedForm.id}
          >
            <Typography className={classes.prevBtnTextStyle} >
            Preview
            </Typography>
          </Button>
        </Grid>

        {this.getNoteView(classes)}
      </Box>
    );}

  return this.noRecord(classes)

  };

  approvedForms = (classes:any) => {
    if(this.state.approvedForms.length > 0){
    return (
      <Box
        className={classes.boxContainer}
      >
        {this.getFilterSearchView(classes)}
  
        <TableContainer
          className={classes.borderedTable}
          style={{ width: "100%" }}
        >
          <Table>
            {this.getTableHeadWithStatus(classes)}
  
            {this.showapprovedFormsData(classes)}
          </Table>
        </TableContainer>
  
        <Grid container justifyContent={"flex-end"}>
        <Button
            className={classes.editBtnStyle}
          >
            <Typography className={classes.editBtnTextStyle}>
            Transfer Patent
            </Typography>
          </Button>
          <Button
            className={classes.editBtnStyle}
          >
            <Typography className={classes.editBtnTextStyle}>
            Order Certified Copy
            </Typography>
          </Button>
          <Button
            className={classes.editBtnStyle}
          >
            <Typography className={classes.editBtnTextStyle}>
            Renewal
            </Typography>
          </Button>
          <Button
            className={classes.previewBtnStyle}
            onClick={this.onPreview}
            data-test-id="previewApprovedTestID"
            disabled={!this.state.checkedForm.id}
          >
          <Typography className={classes.prevBtnTextStyle}>
          Preview
          </Typography>
          </Button>
        </Grid>

{this.getNoteView(classes)}
      </Box>
    );}

    return this.noRecord(classes)
  };

  getTableHeadWithStatus=(classes:any)=>{
    return <TableHead>
    <TableRow>
      <TableCell className={classes.borderedTableHeaderCell}>
        Select
      </TableCell>
      <TableCell className={classes.borderedTableHeaderCell}>
        Application No.
      </TableCell>
      <TableCell style={{width:'140px'}} className={classes.borderedTableHeaderCell} >
        GI Name
      </TableCell>
      <TableCell className={classes.borderedTableHeaderCell}>
        Entry Type
      </TableCell>
      <TableCell style={{width:'160px'}} className={classes.borderedTableHeaderCell} >
        Filling Date
      </TableCell>
      <TableCell className={classes.borderedTableHeaderCell} style={{width:160}}>
        Form Amt.
      </TableCell>
      <TableCell className={classes.borderedTableHeaderCell}>
        Status
      </TableCell>
    </TableRow>
  </TableHead>
  }

  getTableHead=(classes:any)=>{
    return <TableHead>
    <TableRow>
      <TableCell className={classes.borderedTableHeaderCell}>
        Select
      </TableCell>
      <TableCell className={classes.borderedTableHeaderCell}>
        Application No.
      </TableCell>
      <TableCell className={classes.borderedTableHeaderCell} style={{width:'140px'}}>
        GI Name
      </TableCell>
      <TableCell className={classes.borderedTableHeaderCell}>
        Entry Type
      </TableCell>
      <TableCell className={classes.borderedTableHeaderCell} style={{width:160}}>
        Filling Date
      </TableCell>
      <TableCell style={{width:'160px'}} className={classes.borderedTableHeaderCell} >
        Form Amt.
      </TableCell>
    </TableRow>
  </TableHead>
  }

  getNoteView=(classes:any)=>{
    return <Grid container justifyContent={"center"}>
    <Typography className={classes.customTypography}>
      Note:  Upload all mandatory documents before signing. After signing the doucments digitally you can make payment under payment tab.
    </Typography>
    </Grid>
  }

  removeStartingWords=(inputString:string)=> {
    const indexOfOf = inputString.indexOf('of');
    return indexOfOf !== -1 ? inputString.slice(indexOfOf + 3).trim() : inputString;
  }  

  getTableCells=(value:any,classes:any)=>{
    return <>
    <TableCell className={classes.borderedTableCell}>
                {this.removeStartingWords(value.attributes.application_number)}
              </TableCell>
              <TableCell className={classes.borderedTableCell}>
                {value.attributes.gi_name}
              </TableCell>
              <TableCell className={classes.borderedTableCell}>
                {this.removeStartingWords(value.attributes.entry_type)}
              </TableCell>
              <TableCell className={classes.borderedTableCell}>
                {moment(value.attributes.filling_date).format('MM/DD/YYYY')}
              </TableCell>
              <TableCell className={classes.borderedTableCell}>
                Rs. {value.attributes.amount}
              </TableCell></>
  }

  getFilterSearchView=(classes:any)=>{
    return <Grid
    justifyContent={"space-between"}
      container
    >
      <Grid style={{display:'flex', flexDirection:'row', gap:10}}>
        <Typography style={{
            fontFamily: 'Poppins',
            fontSize: '18px',
            fontWeight: 400,
            lineHeight: '42px',
            letterSpacing: '0px',
            textAlign: 'left',
            color: '#000000',
        }}>Filter By</Typography>
      <Select
        data-test-id="filter"
        value={this.state.selectedOption}
        onChange={this.handleChange}
        IconComponent={(props) => (
          <KeyboardArrowDownIcon
            {...props}
            style={{marginRight:15}}
          />
        )}
        disableUnderline={true}
        className={classes.selectStyle}
      >
        <MenuItem className={classes.dropdownFont} value={"Show All"}>
          Show All
        </MenuItem>
        <MenuItem className={classes.dropdownFont} value={"By Date"}>
          By Date
        </MenuItem>
        <MenuItem className={classes.dropdownFont} value={"By Amount"}>
          By Amount
        </MenuItem>
      </Select>
      </Grid>


      <TextField
        size="medium"
        name="Search"
        placeholder="Search by class or form number"
        type="text"
        id="Search"
        data-test-id="Search"
        value={this.state.searchQuery}
        onChange={this.handleSearchChange}
        className={classes.textField}
        InputProps={{
          disableUnderline: true,
          classes: {
            input: "custom-placeholder-size", // Custom class for placeholder size
          },
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon className={classes.icon} />
            </InputAdornment>
          ),
        }}
      />
    </Grid>
  }

  showDraftedFormsData=(classes:any)=>{
    let filteredData = this.state.draftedForms;
    if(this.state.searchQuery.trim() != ""){
      filteredData = this.state.draftedForms.filter(
        (item:any) =>
          item.attributes.application_number.toLowerCase().includes(this.state.searchQuery.toLowerCase())
      );
    }

    return filteredData?.map((value:any, index:any) => {
      return (
        <TableBody key={'tablebdy'+index}>
          <TableRow>
            <TableCell className={classes.borderedTableCell}>
              <Checkbox
                checked={value.id == this.state.checkedForm.id}
                data-test-id='checkbox1'
                checkedIcon={<CheckBoxIcon className={classes.checkboxStyle}/>}
                onChange={(event)=>this.handleCheck(event, value)}
                inputProps={{ 'aria-label': 'primary checkbox' }}
                icon={<CheckBoxOutlineBlankIcon />}
                className={classes.checkIconStyle}
              />
            </TableCell>
            {this.getTableCells(value,classes)}
          </TableRow>
        </TableBody>
      );
    })
  }

  showDiscardedFormsData=(classes:any)=>{
    let filteredData = this.state.discardedForms;
    if(this.state.searchQuery.trim() != ""){
      filteredData = this.state.discardedForms.filter(
        (item:any) =>
          item.attributes.application_number.toLowerCase().includes(this.state.searchQuery.toLowerCase())
      );
    }

    return filteredData?.map((value:any) => {
        return (
          <TableBody>
            <TableRow>
              <TableCell className={classes.borderedTableCell}>
                 <Checkbox
                  checked={value.id == this.state.checkedForm.id}
                  className={classes.checkIconStyle}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  checkedIcon={<CheckBoxIcon className={classes.checkboxStyle}/>}
                  icon={<CheckBoxOutlineBlankIcon />}
                  onChange={(event)=>this.handleCheck(event, value)}
                  data-test-id='checkbox2'
                />
              </TableCell>
              {this.getTableCells(value,classes)}
              <TableCell className={classes.paymentStatusText}>
                {value.attributes.status == "discarded" ? "Rejected" : ""}</TableCell>
            </TableRow>
          </TableBody>
        );
      })
  }

  showSubmittedFormsData=(classes:any)=>{
    let filteredData = this.state.submittedForms;
    if(this.state.searchQuery.trim() != ""){
      filteredData = this.state.submittedForms.filter(
        (item:any) =>
          item.attributes.application_number.toLowerCase().includes(this.state.searchQuery.toLowerCase())
      );
    }

    return filteredData?.map((value:any) => {
        return (
          <TableBody>
            <TableRow>
              <TableCell className={classes.borderedTableCell}>
                 <Checkbox
                  onChange={(event)=>this.handleCheck(event, value)}
                  checked={value.id == this.state.checkedForm.id}
                  icon={<CheckBoxOutlineBlankIcon />}
                  data-test-id='checkbox3'
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  checkedIcon={<CheckBoxIcon className={classes.checkboxStyle}/>}
                  className={classes.checkIconStyle}
                />
              </TableCell>
              {this.getTableCells(value,classes)}
            </TableRow>
          </TableBody>
        );
      })    
  }

  showapprovedFormsData=(classes:any)=>{
    let filteredData = this.state.approvedForms;
    if(this.state.searchQuery.trim() != ""){
      filteredData = this.state.approvedForms.filter(
        (item:any) =>
          item.attributes.application_number.toLowerCase().includes(this.state.searchQuery.toLowerCase())
      );
    }

    return filteredData?.map((value:any) => {
        return (
          <TableBody>
            <TableRow>
              <TableCell className={classes.borderedTableCell}>
                 <Checkbox
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  onChange={(event)=>this.handleCheck(event, value)}
                  data-test-id='checkbox4'
                  className={classes.checkIconStyle}
                  checkedIcon={<CheckBoxIcon className={classes.checkboxStyle}/>}
                  checked={value.id == this.state.checkedForm.id}
                  icon={<CheckBoxOutlineBlankIcon />}
                />
              </TableCell>
              {this.getTableCells(value,classes)}
              <TableCell
                className={classes.paymentSuccessStatusText}
              >{value.attributes.status == "completed" ? "Accepted" : ""}
              </TableCell>
            </TableRow>
          </TableBody>
        );
      })
  }
  // Customizable Area End

  render() {
    const { classes } = this.props
    return (

      <>
        <Box className={classes.pageContainerDB }>
        <Dialog
        open={this.state.discardedSuccessfulModal}
        onClose={this.handleClose}
        className={classes.modal}
        aria-labelledby="form-dialog-title"
        data-test-id="ResponseModal"
      >
        
        <DialogContent className={this.props.classes.paper}>
    <Typography variant="h5" gutterBottom className={classes.successfullyDiscarded}>
    The form has been successfully discarded.
    </Typography>

      <img
        src={CompleteIcon}
        alt="Complete Icon"
        width={"60"}
      />
    
    <Button
            className={classes.closeBtnStyle}
            onClick={this.handleClose}
            data-test-id="CloseTestID"
            style={{width:"50%"}}
          >
          <Typography className={classes.prevBtnTextStyle}>
          Close
          </Typography>
          </Button>


          </DialogContent>
      </Dialog>
          <Box className={classes.logoContainerDB}>
            
          <img src={logoImage} alt="Logo" className={classes.logoDB} />
            <img src={ipoImage} alt="Ipo-Logo" className={classes.logoDB} />
          </Box>
          <Box className={classes.outerContainerDB}>
            <Box className={classes.innerContainerDB}>
              <Navbar selectedTab={0} navigation={this.props.navigation} alertIcon/>
              <Grid item xs={12}>
                <Grid item xs={12} container direction="row" justifyContent="center" alignItems="center" className={classes.titlePadding}>
                  <Typography component={'div'} className={classes.sectionHeading}>Form History</Typography>
                </Grid>
              </Grid>

              <Grid item xs={12} style={{ flexBasis: "0% !important" }}>
                <StyledTabs

                  aria-label="simple tabs example"
                  value={this.state.settingsTabValue}
                  onChange={this.settingsChange}
                  data-test-id="controlPanelTab"
                  variant="fullwidth"
                >
                  <Tab label="Drafted Forms"
                    className={
                      this.state.settingsTabValue === 0
                        ? classes.selectedTab 
                        : classes.nonSelectTab 
                    }

                    {...this.allProps(0)}
                  />
                  <Tab label="Discarded Forms"
                    className={
                      this.state.settingsTabValue === 1
                        ? classes.selectedTab 
                        : classes.nonSelectTab 
                    }

                    {...this.allProps(1)}
                  />
                  <Tab label="Submitted Forms"
                    className={
                      this.state.settingsTabValue === 2
                        ? classes.selectedTab 
                        : classes.nonSelectTab 
                    }

                    {...this.allProps(2)}
                  />
                  <Tab label="Completed Forms"
                    className={
                      this.state.settingsTabValue === 3
                        ? classes.selectedTab 
                        : classes.nonSelectTab 
                    }

                    {...this.allProps(3)}
                  />
                </StyledTabs>
              </Grid>
                {this.TabPanel(classes, 0)}
                {this.TabPanel(classes, 1)}
                {this.TabPanel(classes, 2)}
                {this.TabPanel(classes, 3)}
            </Box>
          </Box>
          <footer className={classes.footer}>
            <p className={classes.footerText}>
              Guidelines for using NTRP (Bharat Kosh) Payment Gateway <span style={{ color: "#FF0000" }}>Guide</span>
            </p>
          </footer>
        </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const styles = (theme: Theme) =>
  createStyles({
    // modal: {
    //   "& .MuiPaper-root": {
    //     borderRadius: "20px",
    //     padding: "20px",
    //     maxWidth: "800px",
    //   }
    // },
    paymentStatusText:{ color: "#FF0000", textTransform:"capitalize",
    fontFamily: 'Poppins',
    border: "1px solid #884403",
    padding: "8px",
    textAlign: "center",
    fontSize: "11px", },
    paymentSuccessStatusText:{ color: "#33CD33", textTransform:"capitalize",
    fontFamily: 'Poppins',
    border: "1px solid #884403",
    padding: "8px",
    textAlign: "center",
    fontSize: "11px", },
  borderedTable: {
    padding: "20px 0px",
  },
  borderedTableCell: {
    border: "1px solid #884403",
    padding: "8px",
    fontFamily: 'Poppins',
    textAlign: "center",
    fontSize: "13px",
  },
  borderedTableHeaderCell: {
    border: "1px solid #884403",
    padding: "8px",
    textAlign: "center",
    fontSize: "16px",
    backgroundColor: "#FFF3E7",
    fontWeight: 400,
    fontFamily: 'Poppins',
            color: '#000000',
  },
  newBox: {
    background: "yellow",
  },
  icon: {
    color: "black",
    fontSize: "20px",
  },
  dropdownIcon: {
    color: "black",
    fontSize: "20px",
  },
  textField: {
    justifyContent:'center',
    border: "1px solid #884403",
    borderRadius: "20px",
    paddingRight: "10px",
    paddingLeft: "10px",
    width: "28%",
    "& input::placeholder": {
      fontSize: "12px",
      color: "grey",
    },
  },
  selectStyle: {
    border: "1px solid #884403",
    width: "100px",
    borderRadius: "20px",
    fontSize: "10px",
    paddingLeft: "10px",
    marginLeft:"15px"
  },
  checkIconStyle: {
    // color: '#33CD32',
    // '&:checked': {
    //   color: "#33CD32",
    // },
    '&:checked + .MuiSvgIcon-root': {
      color: '#33CD32',
    },
  },
  closeBtnStyle:{
    // marginBottom: '30px',
    // backgroundColor: "#894504",
    // width: "15%",
    // color: "white",
    // textTransform: "capitalize",
    // '&:hover': {
    //   backgroundColor: '#894504',
    // },
    // border: "1px solid #894402"

    backgroundColor: "#8A4504",
    color: "white",
    fontWeight: 500,
    borderRadius: "6px",
    width: "100%",
    boxShadow: "0px 2px 4px 1px rgba(0,0,0,0.25)",
    textTransform: "none",
    fontSize: "24px",
    fontFamily: "Poppins, sans-serif",
    '@media (max-width: 1600px)': {
      fontSize: "18px",
    },
    "&:hover": {
      backgroundColor: "8A4504",
    },
    "&.Mui-disabled": {
      opacity: 0.5,
      color: "white"
    }
  },
  previewBtnStyle:{
    backgroundColor: "#894504",
    width: "20%",
    color: "white",
    textTransform: "capitalize",
    '&:hover': {
      backgroundColor: '#894504',
    },
    border: "1px solid #894402",
    [theme.breakpoints.down('sm')]: {
      width: 'auto', // Adjust width for small screens
      margin: 5, // Remove margin for small screens
    },
  },
  customTypography: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0px',
    textAlign: 'center',
    color: '#001AFF',
    marginTop:"15px"
  },
  editBtnStyle: {
    marginRight: "15px",
    padding:"0px, 10px",
    color: "#894504",
    width: "20%",
    backgroundColor: "white",
    textTransform: "capitalize",
    border: "1px solid #894402",
    [theme.breakpoints.down('sm')]: {
      width: 'auto', // Adjust width for small screens
      margin: 5, // Remove margin for small screens
    },
  },
  editBtnTextStyle: {
    // margin:"0px 10px",
    fontFamily: 'Poppins',
    fontSize: '15px',
    fontWeight: "bold",
    lineHeight: '20px',
    letterSpacing: '0px',
    textAlign: 'center',
    color: '#894402',
  },
  // editBtnStyle:{
  //   marginRight:"15px",
  //   color: "#894504",
  //   width: "15%",
  //   backgroundColor: "white",
  //   textTransform: "capitalize",
  //   border: "1px solid #894402"
  // },
  // editBtnTextStyle:{
  //   fontFamily: 'Poppins',
  //   fontSize: '15px',
  //   fontWeight: "bold",
  //   lineHeight: '20px',
  //   letterSpacing: '0px',
  //   textAlign: 'center',
  //   color: '#894402',
  // },
  prevBtnTextStyle:{
    color:"#fff",
    fontFamily: 'Poppins',
    fontSize: '15px',
    fontWeight: "bold",
    lineHeight: '20px',
    letterSpacing: '0px',
    textAlign: 'center',
    // margin:"0px 10px",
  },
  checkboxStyle:{color:'#33CD33'},
  noRecordStyle:{
    fontFamily: 'Poppins',
    fontSize: '19.5px',
    fontWeight: 300,
    lineHeight: '46px',
    letterSpacing: '0px',
    textAlign: 'center',
    color: '#E02020',
  },
  boxContainer: {
    padding: "0px 40px"
  },
  dropdownFont: {
    fontSize: "12px",
    fontFamily: 'Poppins',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0px',
    textAlign: 'left',
  },
    progress0: {
      '& > .MuiLinearProgress-bar1Determinate': {
        backgroundColor: 'purple !important',
      },
    },
    progress1: {
      '& > .MuiLinearProgress-bar1Determinate': {
        backgroundColor: 'yellow !important',
      },
    },
    progress2: {
      '& > .MuiLinearProgress-bar1Determinate': {
        backgroundColor: 'rgb(182, 82, 98) !important',
      },
    },
    progress3: {
      '& > .MuiLinearProgress-bar1Determinate': {
        backgroundColor: 'green !important',
      },
    },
    pageContainerDB: {
      overflowX: 'hidden',
    },

    paper: {
      background: "white",
      display:"flex",
      flexDirection:"column",
      alignItems:"center",
      gap:"20px",
      padding: "40px",
      paddingTop: "40px !important",
      width:474
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    successfullyDiscarded:{

      color: "#894402",
      textAlign: "center",
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "20px",
      marginBottom: "10px",
      maxWidth: "75%",
      margin: "0 auto",
      // marginTop: '30px',  fontFamily: 'Poppins',
      // fontSize: '20px',
      // fontWeight: 500,
      // lineHeight: '35px',
      // letterSpacing: '0px',
      // textAlign: 'center'
    },
    modalImg:{ width: '70px', height: '70px', objectFit: 'cover' },
    discardModalPaper:{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center',
      borderRadius: '12px',
      width: "25%"
    },
    logoContainerDB: {
      padding: '20px',
      height: '120px',
      justifyContent: 'space-between',
      maxWidth: '100%',
      display: 'flex',
      backgroundColor: '#ffd4ac',
    },
    logoDB: {
      height: "100px",
      padding: "4px",
      objectFit: "contain",
      '@media (max-width: 300px)': {
        height: "40px",
        padding: "4px",
      },
      '@media (max-width: 600px)': {
        height: "60px",
        padding: "5px",
      },
      '@media (max-width: 900px)': {
        height: "80px",
        padding: "10px",
      },
    },
    outerContainerDB: {
      padding: '20px 180px',
      fontFamily: "Poppins",
      backgroundColor: '#ffd4ac',
    },
    innerContainerDB: {
      marginBottom: '5% !important',
      borderRadius: '20px',
      boxShadow: '5px 10px 8px #888888',
      backgroundColor: 'white',
    },
    contentContainerDB: {
      marginTop: '7%',
      marginBottom: '7%',
      marginLeft: '9%',
      marginRight: '5%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    rowDB: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: '31px',
    },
    coloredBox: {
      backgroundColor: '#FFEFDF',
      width: '25%',
      height: '180px',
      display: 'flex',
      borderRadius: '16px',
      flexDirection: 'column',
      alignItems: 'center',
      marginRight: '40px !important',
      marginBottom: '50px !important',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        width: 'calc(50% - 30px)',
        marginRight: '15px',
        marginBottom: '15px',
      },
    },
    boxData: {
      textAlign: 'center',
      cursor: 'pointer',
    },
    boxDataImg: {
      width: '50px',
      height: '50px',
      [theme.breakpoints.down('md')]: {
        width: '30px',
        height: '30px',
      },
      [theme.breakpoints.down('sm')]: {
        width: '20px',
        height: '20px',
      },
      [theme.breakpoints.down('xs')]: {
        width: '15px',
        height: '15px',
      },
    },
    boxLabelText: {
      fontFamily: "Poppins",
      fontWeight: 500,
      fontSize: '22px',
      color: '#8A4504',
      [theme.breakpoints.down('md')]: {
        fontSize: '16px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '12px',
      },
    },
    topMargin: {
      marginTop: '50px',
    },
    appContainer: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#ffd4ac",
      height: "auto !important",
      overFlowX: "hidden"
      /* min-height: 100vh;  */
    },
    header: {
      backgroundColor: "#ffd4ac",
      padding: "10px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: "120px",
      marginBottom: " 30px !important",
      marginTop: "18px !important  ",
      maxWidth: "100%",
      margin: "0 auto"
    },
    logoContainer: {
      /* margin: 0 10px; */
      marginLeft: "20px !important",
      marginRight: "20px !important"
    },
    logo: {
      maxHeight: "100%",
      maxWidth: "100%"
    },
    cardContainer: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#ffffff",
      borderRadius: "10px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      width: "75%",
      margin: "0 auto",
      marginBottom: "40px",
      height: "100%",


    },
    footer: {
      backgroundColor: "#ffffff",
      padding: " 10px",
      textAlign: "center",
      height: "70px !important",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    footerText: {
      margin: 0,
      fontSize: "20px !important",
      color: "#000000",
      fontWeight: 400,
      lineWeight: "45px !important",
      fontFamily: "Poppins"
    },
    sectionHeading: {
      fontSize: "25px",
      fontWeight: 500,
      color: "#8A4504",
      fontFamily: "Poppins"
    },
    titlePadding: {
      padding: "1.5rem"
    },
    filterBy:{
      fontFamily: 'Poppins',
      fontSize: '18px',
      fontWeight: 400,
      lineHeight: '42px',
      letterSpacing: '0px',
      textAlign: 'left',
      color: '#000000'
    },
    selectedTab: {
      fontSize: "19.5px",
      fontWeight: 500,
      minWidth: "0px",
      fontFamily: "Poppins",
      padding: "0px",
      minHeight: "0px",
      textTransform: "none",
      lineHeight: "46.5px",
      color: "#000000"
    },
    nonSelectTab: {
      fontSize: "19.5px",
      fontWeight: 300,
      minWidth: "0px",
      fontFamily: "Poppins",
      padding: "0px",
      minHeight: "0px",
      textTransform: "none",
      lineHeight: "46.5px",
      color: "#000000",
      opacity: 1,

    }
  });
export const FormHistory1 = withStyles(styles)(FormHistory);

// Customizable Area End