import React from "react";

// Customizable Area Start
import {
  OutlinedInput,
  TextField,
  Button
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { warningIcon } from "../../landingpage/src/assets";
import Captcha from "../../captcha/src/Captcha.web";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";


export class AccountLogin extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
       // Customizable Area Start
      // Required for all blocks
      <div className={this.props.classes.leftPart}>
      <div className={this.props.classes.inputContainer}>
        <div className={this.props.classes.textLabel}>
          <span className={this.props.classes.subLabel}>Login With</span>
        </div>
      </div>
      <div className={this.props.classes.inputContainer}>
        <Autocomplete
          id="logintype"
          options={this.state.guideData}
          className={this.props.classes.formInputStyleAuto}
          popupIcon={<ExpandMoreIcon />}
          getOptionLabel={option => option.label} // Display the label property as the option label
          onChange={(e, value) => {
            this.setState({
              logintype: value ? value.value : null,
              logintypeError: ""
            });
          }}
          defaultValue={this.state.guideData[0]}
          renderInput={params => (
            <TextField
              {...params}
              error={this.state.logintypeError}
              variant="outlined"
              id="renderInput"
              inputProps={{
                ...params.inputProps,
                readOnly: true
              }}
            />
          )}
        />
      </div>
      {this.state.logintypeError && (
        <div className={this.props.classes.textLabel}>
          <img src={warningIcon} alt="alertIcon" className={this.props.classes.errorIcon}/>
          <span className={this.props.classes.errorMessageTextLp}>
            {this.state.logintypeError}
          </span>
        </div>
      )}
      <div className={this.props.classes.inputContainer}>
        <OutlinedInput
          type="text"
          className={this.props.classes.formInputStyle}
          placeholder="User ID"
          id="field1"
          error={this.state.usernameError}
          onChange={e => {
            this.setState({
              username: e.target.value,
              usernameError: ""
            });
          }}
        />
      </div>
        {this.state.usernameError && (
          <div className={this.props.classes.textLabel}>
            <img src={warningIcon} alt="alertIcon" className={this.props.classes.errorIcon}/>
            <span className={this.props.classes.errorMessageTextLp}>
              {this.state.usernameError}
            </span>
          </div>
        )}
      <div className={this.props.classes.inputContainer}>
        <OutlinedInput
          type="password"
          className={this.props.classes.formInputStyle}
          placeholder="Password"
          id="field2"
          onChange={e => {
            this.setState({
              credentials: e.target.value,
              credentialsError: ""
            });
          }}
          error={this.state.credentialsError}
        />
      </div>
        {this.state.credentialsError && (
          <div className={this.props.classes.textLabel}>
            <img src={warningIcon} alt="alertIcon" className={this.props.classes.errorIcon}/>
            <span className={this.props.classes.errorMessageTextLp}>
              {this.state.credentialsError}
            </span>
          </div>
        )}
      <div className={this.props.classes.inputContainer}>
        <OutlinedInput
          type="text"
          className={this.props.classes.formInputStyle}
          placeholder="Captcha"
          id="field3"
          error={this.state.captchaError}
          onChange={e => {
            this.setState({
              inputcaptcha: e.target.value,
              captchaError: ""
            });
          }}
        />
      </div>
        {this.state.captchaError && (
          <div className={this.props.classes.textLabel}>
            <img src={warningIcon} alt="alertIcon" className={this.props.classes.errorIcon}/>
            <span className={this.props.classes.errorMessageTextLp}>
              {this.state.captchaError}
            </span>
          </div>
        )}
      <Captcha navigation={this.props.navigation} id={this.props.id} setCaptcha={this.setCaptcha} classes={this.props.classes}/>
      <div className={this.props.classes.loginButtonContainer}>
        <Button
          data-test-id="btnSocialLogin"
          id="btnSocialLogin"
          onClick={() => {
            this.validate();
          }}
          disabled={this.state.buttonDisable}
          className={ this.state.buttonDisable ? this.props.classes.landingpageButtonDisable : this.props.classes.landingpageButton }
        >
          Login
        </Button>
        {this.state.loginFailError && (
          <div className={this.props.classes.textLabel}>
            <img src={warningIcon} alt="alertIcon" className={this.props.classes.errorIcon}/>
            <span className={this.props.classes.errorMessageTextLp}>
              {this.state.loginFailError}
            </span>
          </div>
        )}
      </div>
      <div className={this.props.classes.container}>
        <span id="new_User" className={this.props.classes.newUser}
        onClick={()=>{this.navigateUser("NewUserRegistrationForm")}}
        >New User? Sign Up |</span>
        <span className={this.props.classes.forgotPwd} 
        onClick={()=>{this.navigateUser("forgotPwd")}}
        >&nbsp;Forgot Password?</span>
      </div>
    </div>
       // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyles = () =>
createStyles({
  leftPart: {
    width: '50%'
  },
  inputContainer: {
    marginBottom: '10px', 
    display: 'flex'
  },
  textLabel: {
    marginBottom: '10px',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center'
  },
  subLabel: {
    position: 'relative',
    fontFamily: 'Poppins-Medium, sans-serif ',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '25px ',
    lineHeight: '45px',
    color: '#000000 ',
    left: '0 ',
  },
 
  formInputStyleAuto: {
    height: '50px',
    borderRadius: '5px',
    marginRight: '30px',
    marginBottom:"5px",
    fontFamily: '"Poppins-Medium", sans-serif',
    width: '95%', /* Set the width to 100% to make it adjust to the container's width */
    '& .MuiAutocomplete-input': {
      fontSize: '16px',
      color: '#000000',
      marginLeft:"9px",
      fontFamily: "Poppins, sans-serif"
    },
    '& .MuiAutocomplete-root .MuiAutocomplete-option': {
      fontSize: '16px',
      color: '#000000',
      fontFamily: "Poppins, sans-serif"
    }
  },
  errorIcon:{
    width:'20px',
    height:'20px',
    marginRight:"4px"
  },
  errorMessageTextLp: {
    position: 'relative',
    fontFamily: '"Poppins-Medium", sans-serif',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '18px',
    color: '#FF0000',
    left: '0'
  },
  formInputStyle: {
    width: '95%',
    height: '49px',
    padding: '10px',
    fontSize: '16px',
    // border: '1.31px solid #979797',
    borderRadius: '5px',
    marginRight: '30px',
    color: '#000000',
    fontFamily: 'Poppins, sans-serif',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '15px'
  },
  imageContainer: {
    position: 'relative',
    textAlign: 'center',
    color: '#C74217',
    marginTop: '20px'
  },
  centered: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '30px',
    fontWeight: 800,
    fontFamily: 'Poppins-MediumItalic, sans-serif',
    color: '#C74217',
    '@media (max-width: 900px)': {
      fontSize: '24px',
    },
    '@media (max-width: 600px)': {
      fontSize: '18px',
    },
    '@media (max-width: 300px)': {
      fontSize: '14px',
    }
  },
  iconContainer: {
    marginLeft: '10px', // Push the IconButton to the right
    marginTop: '20px'
  },
  loginButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: '15px',
  },
  landingpageButtonDisable: {
    height: '53px',
    width: '70%',
    textTransform: 'none',
    fontSize: '18px',
    color: '#FFFFFF',
    borderRadius: '6px',
    backgroundColor: '#b38961',
    '&:disabled': {
      color: '#FFFFFF !important', // Color when disabled
    },
  },
  landingpageButton: {
    height: '53px',
    width: '70%',
    textTransform: 'none',
    fontSize: '18px',
    color: '#FFFFFF',
    borderRadius: '6px',
    backgroundColor: '#8A4504',
    '&:hover': {
      color: '#FFFFFF',
      backgroundColor: '#8A4504',
    },
  },
  newUser: {
    color: '#0068FF',
    cursor: 'pointer',
    fontFamily: '"Poppins-Medium", sans-serif',
    
  },
  forgotPwd: {
    color: '#000000',
    cursor: 'pointer',
    fontFamily: '"Poppins-Medium", sans-serif',
  },

})
export default withStyles(webStyles) (AccountLogin)
// Customizable Area End
