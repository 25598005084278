import React from "react";

import {
    // Customizable Area Start
    withStyles,Theme, createStyles,
    // Customizable Area End
} from "@material-ui/core";
import Settings2Controller,{ Props } from "./Settings2Controller";
// Customizable Area Start
// Customizable Area End


export class RenewDigitalSignature extends Settings2Controller {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
               
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

export const RenewDigitalSignatureStyles = (theme: Theme) => 
createStyles({

    
});
export default withStyles(RenewDigitalSignatureStyles)(RenewDigitalSignature);

// Customizable Area End
