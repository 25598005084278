import React, { Component } from "react";
import {
    withStyles,
    createStyles,
    WithStyles,
    Theme,
} from "@material-ui/core/styles";
import {
    AppBar,
    Toolbar,
    Box,
    Tabs,
    Tab,
    Divider
} from "@material-ui/core";

const styles = (theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        appBar: {
            display:"flex",
            padding:"10px",
            boxShadow:"none",
            backgroundColor: "#ffd4ac",
            justifyContent:"center",
            alignItems: "center",
        },
        toolBar: {
            display: "flex",
            justifyContent: "space-between",
            flexWrap:"wrap",
            minHeight: "0px",
            alignItems: "end"
        },
        tabs: {
            fontSize: "17px",
            '& .MuiTab-root': {
                opacity: 1, 
                color:"#000000",
                '&.Mui-selected': {
                  opacity: 1,
                },
              },
        },
        tab: {
            fontSize: "17px",
            fontWeight: 400,
            minWidth: "0px",
            fontFamily: "Poppins, sans-serif",
            padding: "0px",
            minHeight: "0px",
            textTransform: "none",
            lineHeight: "25px",
            maxWidth: "100%"
        },
        divider: {
            background: "#000000",
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
        },
        linkText : {
            textDecoration: 'underline'
        },
        linkWrap : {
            textDecoration: 'underline',
            whiteSpace: "nowrap",
            maxWidth: "100%",
            display:"inline-block"
        },
        indicator: {
            height: "4px",
            backgroundColor: "white",
            bottom: "0",
            borderRadius: "100px",
        },
        flexContainer:{
            flexWrap:"wrap"
        }
    });

interface LinkListProps extends WithStyles<typeof styles> {}
   

class LinkList extends Component<LinkListProps> {
    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <AppBar position="static" className={classes.appBar}>
                    <Toolbar className={classes.toolBar}>
                        <Box>
                            <Tabs
                                classes={{ root: classes.tabs, indicator: classes.indicator, flexContainer: classes.flexContainer }}
                            >
                                <Tab
                                    className={classes.tab}
                                    label={<span className={classes.linkText}>{"DSC Component"}</span>}
                                    href={"#"}
                                />
                                <Divider
                                    orientation="vertical"
                                    flexItem
                                    className={classes.divider}
                                />
                                <Tab
                                    className={classes.tab}
                                    label={<span className={classes.linkText}>{"Digital Signature Manual"}</span>}
                                    href={"#"}
                                />
                                <Divider
                                    orientation="vertical"
                                    flexItem
                                    className={classes.divider}
                                />
                                <Tab
                                    className={classes.tab}
                                    label={<span className={classes.linkText}>{"e-filing User Manual"}</span>}
                                    href={"#"}
                                />
                                <Divider
                                    orientation="vertical"
                                    flexItem
                                    className={classes.divider}
                                />
                                <Tab
                                    className={classes.tab}
                                    label={<span className={classes.linkText}>{"FAQs"}</span>}
                                    href={"/HelpCentreQA"}
                                />
                                <Divider
                                    orientation="vertical"
                                    flexItem
                                    className={classes.divider}
                                />
                                <Tab
                                    className={classes.tab}
                                    label={<span className={classes.linkText}>{"Disclaimer"}</span>}
                                    href={"#"}
                                />
                                <Divider
                                    orientation="vertical"
                                    flexItem
                                    className={classes.divider}
                                />
                                <Tab
                                    className={classes.tab}
                                    label={<span className={classes.linkText}>{"Privacy Policy"}</span>}
                                    href={"/PrivacyPolicy"}
                                />

                            </Tabs>
                            
                        </Box>
                    </Toolbar>
                    <Toolbar className={classes.toolBar}>
                        <Box>
                        <Tabs
                                classes={{ root: classes.tabs, indicator: classes.indicator, flexContainer: classes.flexContainer }}
                            >
                                <Tab
                                    className={classes.tab}
                                    label={<span className={classes.linkText}>Terms & Conditions</span>}
                                    href={"/TermsConditions"}
                                />
                                <Divider
                                    orientation="vertical"
                                    flexItem
                                    className={classes.divider}
                                />
                                
                                <Tab
                                    className={classes.tab}
                                    label={<span className={classes.linkWrap}>{"Help Desk(ipo-helpdesk@nic.in)"}</span>}
                                    href={"#"}
                                />
                                <Divider
                                    orientation="vertical"
                                    flexItem
                                    className={classes.divider}
                                />
                                <Tab
                                    className={classes.tab}
                                    label={<span className={classes.linkText}>{"GI Process Guide"}</span>}
                                    href={"#"}
                                />

                            </Tabs>
                        </Box>
                    </Toolbar>
                </AppBar>
            </div>
        );
    }
}

export default withStyles(styles)(LinkList);
