import React from "react";
  // Customizable Area Start
import {
    Box,Typography, Grid
} from "@material-ui/core";
import { ipoImage, logoImage } from "../../customform/src/assets";
import Navbar from "../../../components/src/Navbar";
import { createStyles , withStyles} from "@material-ui/styles"
// Customizable Area End

import ContentManagementController, {
  Props
} from "./ContentManagementController";

// Customizable Area Start
// Customizable Area End


export class AboutUs extends ContentManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End

    return (
      // Customizable Area Start
      <Box className={this.props.classes.pageContainer}>
        <Box className={this.props.classes.logoContainerAboutus}>
          <img src={logoImage} alt="Logo" className={this.props.classes.logoAboutus} />
          <img src={ipoImage} alt="Ipo-Logo" className={this.props.classes.logoAboutus} />
        </Box>
        <Box className={this.props.classes.outerContainer}>
          <Box className={this.props.classes.innerContainer}>
            <Navbar selectedTab={2} alertIcon={true} navigation={this.props.navigation} />
            <Box className={this.props.classes.mainDiv}>
                <Grid container direction="row" justifyContent="center" alignItems="center" spacing={3}>
                  <div style={{ fontFamily: 'Poppins, sans-serif' }} className={this.props.classes.mainDiv}
                    dangerouslySetInnerHTML={{
                      __html: this.state.aboutUsInfo.replace(
                        /font-family:[^;]+;/g,
                        'font-family: Poppins, sans-serif'
                      )
                    }}
                  />
                </Grid>
            </Box>              
          </Box>
        </Box>
        <footer className={this.props.classes.footer}>
          <Typography className={this.props.classes.footerText}>
            Guidelines for using NTRP (Bharat Kosh) Payment Gateway{" "}
            <a href="#" className={this.props.classes.guideLink}>
              Guide
            </a>
          </Typography>
        </footer>
      </Box>
      // Customizable Area End

    );
  }
}
// Customizable Area Start

const webStyle = createStyles({
    pageContainer: {
      overflowX: 'hidden',
    },
    logoContainerAboutus: {
      display: "flex",
      backgroundColor: "#ffd4ac",
      justifyContent: "space-between",
      padding: "20px",
      maxWidth: "100%",
      height: "120px",
    },
    logoAboutus: {
      objectFit: "contain",
      height: "100px",
      padding: "4px",
      '@media (max-width: 900px)': {
        height: "80px",
        padding: "10px",
      },
      '@media (max-width: 600px)': {
        height: "60px",
        padding: "5px",
      },
      '@media (max-width: 300px)': {
        height: "40px",
        padding: "4px",
      }
    },
    mainDiv:{
       padding: "20px"
    },
  
    outerContainer: {
      backgroundColor: '#ffd4ac',
      padding: "20px 180px",
      fontFamily: 'Poppins, sans-serif',
      marginBottom:"30px",
      '@media (max-width: 1200px)': {
        padding: "30px 120px",
      },
      '@media (max-width: 900px)':{
        padding: "20px 40px",
      },
      '@media (max-width: 600px)':{
        padding: "20px 20px",
      },
      '@media (max-width: 300px)':{
        padding: "20px 10px",
      }
    },
  
    innerContainer: {
      backgroundColor: 'white',
      borderRadius: '20px',
      boxShadow: '5px 10px 8px #888888',
    },
    description:{
      fontWeight:400,
      fontSize:"20px",
      color:"#000000",
      fontFamily: 'Poppins, sans-serif',
    },
    heading:{
      fontWeight:600,
      fontSize:"30px",
      color:"#8A4504",
      fontFamily: 'Poppins, sans-serif',
    },
    guideLink: {
      color: "#ff0000",
      textDecoration: "none",
    },
    footer: {
      backgroundColor: "#ffffff",
      padding: "10px",
      textAlign: "center",
      height: "70px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    footerText: {
      margin: 0,
      fontSize: "20px",
      color: "#000000",
      fontWeight: 400,
      lineHeight: "45px",
      fontFamily: "Poppins-Medium, sans-serif",
    },
  });
  
export default withStyles(webStyle)(AboutUs)
// Customizable Area End
